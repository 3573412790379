import React from "react";
import { Text } from "react-native";
export default class LocationType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeName: {
        1: "Packstation",
        2: "Filiale",
        3: "Paketshop"
      }
    };
  }
  render() {
    return <Text>{!this.state.typeName[this.props.value] ? this.props.value : this.state.typeName[this.props.value]}</Text>;
  }
}
