import React from "react";
import { StyleSheet, View, TextInput, Alert, ActivityIndicator, Vibration, FlatList,RefreshControl } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking'
import * as Permissions from "expo-permissions";
import { BarCodeScanner } from "expo-barcode-scanner";
import { Button, Text } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";
import Row from "./components/ParcelNumberRow";

export default class AddParcelNumberRetourScreen extends React.Component {
  render() {
    return <MyContext.Consumer>{context => <ParcelDataHandler context={context} navigation={this.props.navigation} />}</MyContext.Consumer>;
  }
}

class ParcelDataHandler extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      hasCameraPermission: null,
      scanned: false,
      doscan: false,
      type: BarCodeScanner.Constants.Type.back,
      result: null,
      tmpParcelNumber:'',
      parcelNumbers:[
        {
          id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
          title: 'First Item',
        },
        {
          id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
          title: 'Second Item',
        },
        {
          id: '58694a0f-3da1-471f-bd96-145571e29d72',
          title: 'Third Item',
        },
      ]
    };
  }
  UNSAFE_componentWillMount() {}
  componentDidUpdate(prevProps, prevState) {}

  async _get_cameramermission() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    this.setState({ hasCameraPermission: status === "granted" });
  }

  _extractNumber = nr => {
    console.log(nr);
    if (nr.split("http://nd.dpd.de/?pknr=").length > 1) {
      return nr.split("http://nd.dpd.de/?pknr=")[1];
    } else if (nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=").length > 1) {
      return nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=")[1];
    } else if (nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=").length > 1) {
      return nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=")[1];
    } else {
      return "";
    }
  };
  handleBarCodeScanned = async ({ type, data }) => {
    //Vibration.vibrate(100);
    if (this.isURL(data)) {
     // this.props.context.setNewParcelValue("parcelNumber", this._extractNumber(data));

      this.setState({tmpParcelNumber:this._extractNumber(data)});

    } else if (data.indexOf(" ") == -1) {
      // this.setState({ number: data });
     // this.props.context.setNewParcelValue("parcelNumber", data);
     this.setState({tmpParcelNumber:data});
    }
    this.setState({ scanned: true });
    this.setState({ doscan: false });

    //alert(`Code erkannt! Typ: ${type} mit folgenden Daten: ${data}`);
  };
  isURL = str => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };

  render() {
    const { hasCameraPermission, scanned, doscan } = this.state;

    /* if (hasCameraPermission === null) {
      return (
        <View style={{ flex: 1, paddingTop: 20 }}>
          <ActivityIndicator />
        </View>
      );
    }
    if (hasCameraPermission === false) {
      return <Text>Kein Zugriff auf die Kamera</Text>;
    }*/

    if (doscan === true) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <BarCodeScanner onBarCodeScanned={this.handleBarCodeScanned} style={StyleSheet.absoluteFillObject} type={this.state.type} />

          <Button title={"Abbrechen"} style={styles.abortscan} onPress={() => this.setState({ doscan: false })} buttonStyle={{ borderRadius: 0 }} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <View style={{ padding: 5, justifyContent: "flex-start" }}>
          <HeaderImage image="scan" />
        </View>
        <View style={{ padding: 5, justifyContent: "center", flex: 1,flexDirection:'column' }}>

        <View style={{ marginTop:5,height: 32,padding: 0, borderColor: "#ccc", borderWidth: 1,flexDirection:'row'  }}>
            <View>
              <Button  onPress={this._startqr}  icon={<Icon name="barcode" size={22} color="#1e90ff" style={{ padding:0}} />} titleStyle={{fontSize:0}} buttonStyle={{ backgroundColor:'#ffffff',paddingTop:4, paddingLeft:10,paddingRight:10, borderWidth: 0,padding:0,borderRadius: 0,height:28,alignSelf:"flex-start"}} />
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:2,left:5}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:21,left:5}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:2,left:30}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:21,left:30}}></View>
              </View>
     
            <TextInput style={{paddingLeft:5,height:32,borderColor: "#ff0000", borderWidth: 0,alignSelf:"flex-start" ,flexGrow: 1}} placeholder={"Paketnummer"}  onChangeText={value => {value = value.split(',').join('');this.setState({ tmpParcelNumber: value })}} value={this.state.tmpParcelNumber} />
            <Button disabled={this.state.tmpParcelNumber != "" ? false : true}  onPress={this._addnumber} icon={<Icon name="plus" size={12} color="white" style={{ paddingTop:2}} />} buttonStyle={{ borderWidth:0,padding:0,borderRadius: 0,width:30 ,height:30,alignSelf:"flex-end"}} />
          </View>
         
        <FlatList ItemSeparatorComponent={this.ListViewItemSeparator} 
          data={this.props.context.state.parcelNumbers} 
          keyExtractor={item => item}
          style={{borderColor:'#ccc',borderWidth:1,borderTopWidth:0, height:200,marginBottom:30}} 
          renderItem={({ item, index, separators }) => (
              <View style={{flexDirection: 'row',backgroundColor: index % 2 == 0  ? "#f2f2f2" : "#FFFFFF"}}>

                <Text style={styles.ListEntry}>{item}</Text>
                <Button onPress={() => {this.removeNumber(index)}} color="#ffffff" icon={<Icon name="remove" size={10} color="#666666" style={{ }} />} buttonStyle={{ alignSelf:"flex-end",backgroundColor:'rgba(52, 52, 52, 0)',borderWidth: 0,padding:0,borderRadius: 0,width:50 ,height:35}} />
        
              
            </View>
            )}
          />

          

        </View>

        <View style={{ padding: 5, justifyContent: "flex-end" }}>
          <Button onPress={this._nextstep} title={"Weiter"} disabled={this.props.context.state.parcelNumbers.length > 0  ? false : true} buttonStyle={{ borderRadius: 0 }} />
        </View>
      </View>
    );
  }
  
  _addnumber = () => {
    var chk=false;
    this.props.context.state.parcelNumbers.forEach(element => {
      if(element==this.state.tmpParcelNumber){
        chk=true;
      }
    });
    if( chk==false){
      var newentry=this.state.tmpParcelNumber;
      var newobj=[...this.props.context.state.parcelNumbers,newentry];
      this.props.context.setNewParcelValue("parcelNumbers", newobj);
      this.setState({ tmpParcelNumber: '' });
    }else{
      Alert.alert("Ups", "Diese Paketnummer ist bereits dabei!", [
        {
          text: "OK"
        }
      ]);
    }
  };
  removeNumber = (indx) =>{
    var tmp=this.props.context.state.parcelNumbers;
    tmp.splice(indx, 1);
    this.props.context.setNewParcelValue("parcelNumbers", tmp);
  }
  _openqr = () => {
    alert("open qr scanner");
  };

  _startqr = async () => {
    if (this.state.hasCameraPermission === null) {
      //
      await this._get_cameramermission();
      if (this.state.hasCameraPermission === true) {
        this.setState({ doscan: true });
      } else {
        Alert.alert(
          "Hinweis",
          "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
          [
            {
              text: "OK",
              onPress: () => {
                this.setState({ loaderIsVisible: false });
                Linking.openURL("app-settings://notification/misterpostman");
              }
            }
          ],
          { cancelable: false }
        );
      }
    } else if (this.state.hasCameraPermission === false) {
      Alert.alert(
        "Hinweis",
        "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
        [
          {
            text: "OK",
            onPress: () => {
              this.setState({ loaderIsVisible: false });
              Linking.openURL("app-settings://notification/misterpostman");
            }
          }
        ],
        { cancelable: false }
      );
      return;
    } else {
      this.setState({ doscan: true });
    }
  };

  _nextstep = () => {
    this.props.navigation.navigate("AddParcelWeightRetour");
  };
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  },
  abortscan: {
    backgroundColor: "#fff",
    marginBottom: 20
  },
  ListEntry:{

    padding:10,
    alignSelf:"flex-start",
    flexGrow: 1
  }
});
