import React from "react";
import { Image, Platform, ScrollView, StyleSheet, TouchableWithoutFeedback, TouchableOpacity, View, TextInput, Alert, ActivityIndicator, Clipboard } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking'
import { Input, Button, Text, Overlay, ButtonGroup } from "react-native-elements";
import HeaderImage from "../components/HeaderImage";
import * as WebBrowser from "expo-web-browser";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class VerifyScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderIsVisible: false,
      verifyStatus: false,
      clipboardContent: null
    };
    this.updateIndex = this.updateIndex.bind(this);
  }
  updateIndex(selectedIndex) {
    this.setState({ selectedIndex });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.refresh == true) {
      this._getmydata();
    }
  }
  UNSAFE_componentWillMount() {
    this._getmydata();
  }
  _getmydata = () => {
    this.setState({ refreshing: true });

    fetch(apiUrl + "user/settings.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        show: true
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        if (typeof responseJson.error !== "undefined") {
          this.setState({ isLoading: false });
          Alert.alert(
            "Ups...",
            "Da ist etwas schief gelaufen....",
            [
              {
                text: "OK"
              }
            ],
            { cancelable: false }
          );
        } else {
          if (responseJson["verified"] == "1") {
            this.setState({ verifyStatus: true });
          } else {
            this.setState({ verifyStatus: false });
          }
          this.setState(
            {
              isLoading: false,
              refreshing: false,
              verified: responseJson["verified"]
            },
            function() {
              // In this block you can do something with new state.
            }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });


  };

  _verify = async () => {
    Linking.openURL('mailto:support@mister-postman.net?subject=Identität bestätigen&body=Hallo, ich möchte meine Identität bestätigen.');
      
  };
  _download= async () => {

    Linking.openURL('https://vertrag.mister-postman.net');
      
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <ScrollView>
          <View style={styles.container}>
            <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
              </View>
            </Overlay>

            <View style={{ padding: 0, justifyContent: "flex-start" }}>
              <HeaderImage image="profile" />
            </View>
            <View style={([styles.container], { padding: 10 })}>
              <Text style={{ fontWeight: "bold", fontSize:16,marginBottom: 10}}>Du möchtest dich als Fahrer oder Fahrerin bei Mister Postman verifizieren? Kein Problem!</Text>
              <Text>Sende uns einfach eine E-Mail mit einem Foto deines Personalausweises und dem unterschriebenen Vertrag an support@mister-postman.net 
              Wir kontaktieren dich umgehend und schalten deinen Account frei. </Text>
              <Text>Dein Mister Postman Team</Text>
            </View>

            <View style={{ flex: 1 }}>


              <Button
                  onPress={this._download}
                  title={"Vertrag downloaden"}
                  titleStyle={{ color: "#FFFFFF", fontSize: 16 }}
                  buttonStyle={{ borderRadius: 0, width: 250 }}
                  containerStyle={{
                    width: 250,
                    alignSelf: "center",
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                  
                />

              <Button
                onPress={this._verify}
                title={"support@mister-postman.net"}
                titleStyle={{ color: "#FFFFFF", fontSize: 16 }}
                buttonStyle={{ borderRadius: 0, width: 250 }}
                containerStyle={{
                  width: 250,
                  alignSelf: "center",
                  marginTop: 30,
                  marginBottom: 30,
                }}
                disabled={this.state.verifyStatus}
                accessibilityLabel="Meine Identität jetzt überprüfen!"
               
              />

              <Text style={{alignSelf: "center",color:'green',display:this.state.verifyStatus ? "flex" : "none"}}>Deine Identität wurde bereits bestätigt. <Icon name="check" size={20} color="green" style={this.state.verifyStatus ? { paddingLeft: 10 } : ""} /></Text>
            </View>
          </View>
        </ScrollView>
        <View style={{ padding: 5, justifyContent: "flex-end", marginBottom: 1 }}>
          <View style={{ justifyContent: "center", flexDirection: "row", marginBottom: 5 }}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.mister-postman.net/datenschutz");
              }}
            >
              <Text style={styles.legaltext}>Datenschutz</Text>
            </TouchableOpacity>
            <Text style={styles.separator}>|</Text>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.mister-postman.net/impressum");
              }}
            >
              <Text style={styles.legaltext}>Impressum</Text>
            </TouchableOpacity>
            <Text style={styles.separator}>|</Text>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.mister-postman.net/agb");
              }}
            >
              <Text style={styles.legaltext}>AGB</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  legaltext: {
    fontSize: 11,
    color: "grey"
  },
  separator: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: 11
  },
  container: {
    flex: 1,

    backgroundColor: "#fff",
    paddingTop: 20
  }
});
