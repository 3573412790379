import React from "react";
import { Alert } from "react-native";
import { Text } from "react-native-elements";
import getConfVars from "../../../config";
const { apiUrl } = getConfVars();

import { MyProvider, MyContext } from "../../../rootProvider";

export default class CalcCosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      isLoading: false,
      loaderIsVisible: false,
      numberValue: "",
      md5: "",
      distanceVal: "",
      distanceText: "",
      durationVal: "",
      durationText: "",
      userFirstname: "",
      userLastname: "",
      userStreet: "",
      userZip: "",
      userCity: ""
    };
  }
  UNSAFE_componentWillMount() {
    // this._retrieveData();
    this.__calccosts();
  }

  __calccosts = async context => {
    const { parcelWeight,parcelService,parcelCourierService,parcelNumber, parcelNumbers, parcelLocFormattedAddress, parcelDestFormattedAddress, parcelSess, parcelLocPlaceID, parcelEditID } = this.props.context.state;

    if(parcelService== "delivery" ){
      var checkURL="newparcel_calccosts";
    }else if(parcelService== "courier"){
      var checkURL="newparcel_calccosts_courier";
    }else{
      var checkURL="newparcel_calccosts_retour";
    }

    fetch(apiUrl + "parcel/owner/"+checkURL+".php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        parcelWeight:parcelWeight,
        parcelService: parcelService,
        parcelCourierService:parcelCourierService,
        parcelNumber: parcelNumber,
        parcelNumbers: parcelNumbers,
        parcelLocFormattedAddress: parcelLocFormattedAddress,
        parcelDestFormattedAddress: parcelDestFormattedAddress,
        parcelEditID: parcelEditID,
        parcelLocPlaceID: parcelLocPlaceID,
        parcelSess: parcelSess
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        console.log(responseJson);
        if (typeof responseJson.ok !== "undefined") {
          console.log(responseJson.ok);
          //this.setState({ md5: responseJson.md5, distanceVal: responseJson.distanceVal, distanceText: responseJson.distanceText, durationVal: responseJson.durationVal, durationText: responseJson.durationText });
          this.props.context.setNewParcelValue("parcelDistanceText", responseJson.distanceText);
          this.props.context.setNewParcelValue("parcelDurationText", responseJson.durationText);
          this.props.context.setNewParcelValue("parcelCosts", responseJson.costs);
          this.props.context.setNewParcelValue("parcelCurrency", responseJson.currency);
          this.props.context.setNewParcelValue("parcelSess", responseJson.sess);
          this.props.context.setNewParcelValue("parcelUserCredit", responseJson.credit);
          this.props.context.setNewParcelValue("parcelCostsPerKm", responseJson.costsperkm);
          this.props.context.setNewParcelValue("parcelCostsBase", responseJson.costsbase);
          this.props.context.setNewParcelValue("userCompany", responseJson.company);
          this.props.context.setNewParcelValue("userFirstname", responseJson.firstname);
          this.props.context.setNewParcelValue("userLastname", responseJson.lastname);
          this.props.context.setNewParcelValue("userStreet", responseJson.street);
          this.props.context.setNewParcelValue("userZip", responseJson.zip);
          this.props.context.setNewParcelValue("userCity", responseJson.city);
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          if (responseJson.code == "parcelExists") {
            errmess = "Paket mit der verwendeten Paketnummer ist bereits im System";
          } else if (responseJson.code == "parcelNotExist") {
            errmess = "Dieses Paket kann nicht bearbeitet werden.";
          }
          Alert.alert(
            "Ups...",
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.props.scope.props.navigation.navigate("MyParcels")
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt!",
            [
              {
                text: "OK",
                onPress: () => this.props.scope.props.navigation.navigate("AddParcelTime")
              }
            ],
            { cancelable: false }
          );
        }
        this.props.scope.setState({ loaderIsVisible: false });
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
      console.log(resp);
    });*/
  };

  render() {
    return <Text />;
  }
}
