import React from "react";
import { Image, Dimensions, Platform, ScrollView, StyleSheet, View, TextInput, Alert, ActivityIndicator } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking'
import { Button, Text, Overlay,ButtonGroup } from "react-native-elements";

const dimensions = Dimensions.get("window");
const whfactor = dimensions.width / dimensions.height;
const imageHeight = Math.round((dimensions.width * 444) / 999);
const imageWidth = dimensions.width;
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

const img = require("../../assets/images/mrpostman/addcredit.jpg");

export default class AddCreditLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      selectedIndex: 0,
    };
    this.updateIndex = this.updateIndex.bind(this);
  }
  updateIndex(selectedIndex) {
    this.setState({ selectedIndex });
  }
  render() {
    const buttons = ["Paypal", "Überweisung"];
    const { selectedIndex } = this.state;
    return (
      <View style={styles.container}>
        <View style={{ padding: 5, justifyContent: "flex-start" }}>
          <Text h4 style={{ alignSelf: "center", paddingBottom: 10 }}>
            Guthaben aufladen
          </Text>
          <Text style={{ alignSelf: "center", paddingBottom: 10 }}>Bestimme wie Du überweisen willst und wähle dann die Summe.</Text>
        </View>

        <Image style={{ height: "20%", width: "50%", alignSelf: "center" }} source={img} resizeMode="contain" />
        <ButtonGroup onPress={this.updateIndex} selectedIndex={selectedIndex} buttons={buttons} containerStyle={{ height: 30, marginTop: 20, marginBottom: 20 }} />


        <View style={{ padding: 5, justifyContent: "center", flex: 1, flexDirection: "column", paddingTop: 10}}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button onPress={() => this._addCredit(10)} title={"10€"} style={{ width: "75%", alignSelf: "center" }} buttonStyle={{ borderRadius: 0 }} />
          </View>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button onPress={() => this._addCredit(15)} title={"15€"} style={{ width: "75%", alignSelf: "center" }} buttonStyle={{ borderRadius: 0 }} />
          </View>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button onPress={() => this._addCredit(20)} title={"20€"} style={{ width: "75%", alignSelf: "center" }} buttonStyle={{ borderRadius: 0 }} />
          </View>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button onPress={() => this._addCredit(25)} title={"25€"} style={{ width: "75%", alignSelf: "center" }} buttonStyle={{ borderRadius: 0 }} />
          </View>
        </View>



        <View style={{ padding: 5 }}>
          <Button onPress={this._close} title={"Abbrechen"} style={{ width: "75%", alignSelf: "center", marginBottom: 0, justifyContent: "flex-end" }} buttonStyle={{ borderRadius: 0 }} />
        </View>
      </View>
    );
  }
  _close = () => {
    this.props.closeFunc();
  };
  _addCredit = val => {
    const addval = val;



    Alert.alert(
      "Guthaben aufladen",
      "Bestätige, dass Du die Summe von " + addval + "€ überweisen möchtest.",
      [
        {
          text: "Ja",
          onPress: () => {
            fetch(apiUrl + "user/addcredit.php", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                userID: global.userID,
                loginKey: global.loginKey,
                amount: addval,
                transactionType:this.state.selectedIndex,
              })
            })
              .then(response => response.json())
              .then(async responseJson => {
                console.log(responseJson);
                if (typeof responseJson.ok !== "undefined") {
                  console.log(responseJson.ok);


                  if(this.state.selectedIndex == 0){
                    this._close();
                    Linking.openURL(responseJson.pplink);
                   // 
                  }else{

                    Alert.alert("Super!", "Überprüfe Deine Emails und überweise den Betrag.", [
                      {
                        text: "Schliessen",

                        onPress: () => this._close()
                      }
                    ]);
                  }
                } else if (typeof responseJson.error !== "undefined") {
                  Alert.alert(
                    "Ups...",
                    "Da hat etwas nicht geklappt!",
                    [
                      {
                        text: "OK",
                        onPress: () => this._close()
                      }
                    ],
                    { cancelable: false }
                  );
                }
              })
              .catch(error => {
                console.error(error);
              });
          }
        },
        {
          text: "Nein"
        }
      ],
      { cancelable: true }
    );
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  },
  abortscan: {
    backgroundColor: "#fff",
    marginBottom: 20
  }
});
