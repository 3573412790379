import React from "react";
import { View, Text, StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { stringFormatDate } from "../helper/DateFormat";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 7,
    flexDirection: "row",
    alignItems: "center"
  },
  innercontainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    paddingRight: 10,
    paddingBottom: 10,
    paddingTop: 10
  },
  text: {
    marginLeft: 12,
    fontSize: 15,
    flexWrap: "wrap"
  }
});

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {}
    };
  }
  render() {
    const { amount, time, text, credit, blocked, transferred, booked } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.innercontainer}>
          <View style={{ flexDirection: "column", width: "70%" }}>
            <Text style={{ fontWeight: "bold", color: booked == 1 && amount > 0 ? "green" : booked == 1 && amount < 0 ? "red" : "#000000" }}>{text}</Text>
            <View style={{ flexDirection: "row", paddingLeft: 0, paddingTop: 5 }}>
              <Text style={{ color: booked == 1 && amount > 0 ? "green" : booked == 1 && amount < 0 ? "red" : "#000000" }}>{stringFormatDate(time)} Uhr</Text>
            </View>
          </View>
          <View style={{ flexDirection: "column", alignSelf: "flex-end", width: "30%", alignItems: "flex-end" }}>
            <Text style={{ fontWeight: "bold", color: booked == 1 && amount > 0 ? "green" : booked == 1 && amount < 0 ? "red" : "#000000" }}> {amount.replace(".", ",")} €</Text>
            <View style={{ flexDirection: "row", paddingLeft: 5, paddingTop: 5 }}>
              <Text style={{ color: booked == 1 && amount > 0 ? "green" : booked == 1 && amount < 0 ? "red" : "#000000" }}>{booked == 1 ? "gebucht" : blocked == 1 ? "vorgemerkt" : "warten"}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
