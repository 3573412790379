import React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View, Alert, Dimensions, TextInput, Vibration, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, Keyboard } from "react-native";
import * as Linking from 'expo-linking'
import * as Permissions from "expo-permissions";
import { Button, Text, Image, Overlay } from "react-native-elements";
import HeaderImage from "../../components/HeaderImage";
import { Header } from "react-navigation";
import { TouchableItem } from "../../../components/TouchableItem";
import { formatDate, stringFormatDate } from "../../helper/DateFormat";
import Icon from "react-native-vector-icons/FontAwesome";
import { BarCodeScanner } from "expo-barcode-scanner";
import getConfVars from "../../../config";
const { apiUrl } = getConfVars();

var self;
export default class MyCourierJobDetailBringRejectScreen extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      hasCameraPermission: null,
      id: "",
      parcelId: "1",
      loaderIsVisible: false,
      mandateLayerVisible: false,
      pickupLayerVisible: false,
      pickupParcelNumber: "",
      pickupParcelFreetext: "",
      type: BarCodeScanner.Constants.Type.back,
      doscan: false,
      jobid: ""
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Sendungsdetails`,
      headerBackTitle: `zurück`
    };
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");
    if (nextProps.navigation.state.params.jobid != "") {
      this.setState({
        jobid: nextProps.navigation.state.params.jobid,
        parcelId: nextProps.navigation.state.params.parcelid
      });
    }
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);
  }

  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: this.refreshHandler,
      parcelId: tmp["id"]
    });
    //console.log(tmp);
  }
  async _get_cameramermission() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    this.setState({ hasCameraPermission: status === "granted" });
  }
  refreshHandler(id) {
    self.props.navigation.navigate("MyParcelEdit", { itemID: id });
  }
  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };
  _editParcel = () => {
    alert("_editParcel");
  };
  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, jobid, provider, recvr_firstname, recvr_lastname, dest_street, dest_no, dest_zip, dest_city, dest_lat, dest_lng, location_lat, location_lng, location_type, location_street, location_no, location_zip, location_city, time_from, time_to, time_from2, time_to2, distanceText, durationText, costs, currency } = this.props;

    const dest_coords = {
      latitude: parseFloat(itemdata["dest_lat"]),
      longitude: parseFloat(itemdata["dest_lng"])
    };
    const coords = {
      latitude: parseFloat(itemdata["location_lat"]),
      longitude: parseFloat(itemdata["location_lng"])
    };
    //const itemId = this.props.navigation.state.params.itemId;
    // const itemId = "test";
    if (this.state.doscan === true) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <BarCodeScanner onBarCodeScanned={this.handleBarCodeScanned} style={StyleSheet.absoluteFillObject} type={this.state.type} />

          <Button title={"Abbrechen"} style={styles.abortscan} onPress={() => this.setState({ doscan: false })} />
        </View>
      );
    }

    return (
      <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
            </View>
          </Overlay>

          <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={Platform.OS === "ios" ? Header.HEIGHT : Header.HEIGHT + 25} behavior={"padding"}>
            <ScrollView style={styles.container}>
              <HeaderImage image="scan" onPress={this._dismissKeyboard} />

              <Text style={{ alignSelf: "center", textAlign: "center" }}>Du wolltest das Paket abholen. Es ist aber beschädigt?</Text>
              <Text
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 20
                }}
              >
                Gib die Paketnummer ein und schreibe bei Bedarf etwas in das Freifeld:
              </Text>

              <View style={{ padding: 10, borderColor: "#ccc", borderWidth: 1 }}>
                <TextInput style={{ height: 20 }} placeholder={"Paketnummer"} onChangeText={value => this.setState({ pickupParcelNumber: value })} value={this.state.pickupParcelNumber} />
              </View>

              <Button onPress={this._startqr} title={"Code scannen"} icon={<Icon name="barcode" size={20} color="white" style={{ paddingRight: 5 }} />} buttonStyle={{ borderRadius: 0 }} />

              <View style={{ marginTop: 20, padding: 10, borderColor: "#ccc", borderWidth: 1 }}>
                <TextInput
                  multiline={true}
                  scrollEnabled={false}
                  inputContainerStyle={{ textAlignVertical: "top" }}
                  style={{ height: 70, padding: 0, textAlignVertical: "top" }}
                  placeholder={"Freitext"}
                  onChangeText={value => {
                    this.setState({ pickupParcelFreetext: value });
                  }}
                  value={this.state.pickupParcelFreetext}
                />
              </View>
            </ScrollView>
          </KeyboardAvoidingView>

          <View style={{ padding: 5 }}>
            <Button onPress={this._nextstep} title={"Zurückweisung bestätigen"} disabled={this.state.pickupParcelNumber != "" ? false : true} buttonStyle={{ borderRadius: 0 }} />
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
  _nextstep = () => {
    Alert.alert(
      "Paket zurückweisen",
      "Soll die Abholung des Pakets jetzt aufgrund einer Beschädigung oder sonstiger Gründe zurückgewiesen werden?",
      [
        {
          text: "Nein",
          onPress: () => {}
        },
        {
          text: "Ja",
          onPress: () => this._pickup(this.state.jobid, this.state.parcelId)
        }
      ],
      { cancelable: true }
    );
  };
  _extractNumber = nr => {
    console.log(nr);
    if (nr.split("http://nd.dpd.de/?pknr=").length > 1) {
      return nr.split("http://nd.dpd.de/?pknr=")[1];
    } else if (nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=").length > 1) {
      return nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=")[1];
    } else if (nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=").length > 1) {
      return nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=")[1];
    } else {
      return "";
    }
  };
  handleBarCodeScanned = async ({ type, data }) => {
    //Vibration.vibrate(100);
    if (this.isURL(data)) {
      this.setState({ pickupParcelNumber: this._extractNumber(data) });
    } else if (data.indexOf(" ") == -1) {
      // this.setState({ number: data });
      this.setState({ pickupParcelNumber: data });
    }
    this.setState({ scanned: true });
    this.setState({ doscan: false });

    //alert(`Code erkannt! Typ: ${type} mit folgenden Daten: ${data}`);
  };
  isURL = str => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };
  _startqr = async () => {
    if (this.state.hasCameraPermission === null) {
      //
      await this._get_cameramermission();
      if (this.state.hasCameraPermission === true) {
        this.setState({ doscan: true });
      } else {
        Alert.alert(
          "Hinweis",
          "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
          [
            {
              text: "OK",
              onPress: () => {
                this.setState({ loaderIsVisible: false });
                Linking.openURL("app-settings://notification/misterpostman");
              }
            }
          ],
          { cancelable: false }
        );
      }
    } else if (this.state.hasCameraPermission === false) {
      Alert.alert(
        "Hinweis",
        "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
        [
          {
            text: "OK",
            onPress: () => {
              this.setState({ loaderIsVisible: false });
              Linking.openURL("app-settings://notification/misterpostman");
            }
          }
        ],
        { cancelable: false }
      );
      return;
    } else {
      this.setState({ doscan: true });
    }
  };

  _pickup = (jobid, parcelid) => {
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);

    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/reject.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: jobid,
        parcelID: parcelid,
        parcelNumber: this.state.pickupParcelNumber,
        parcelFreetext: this.state.pickupParcelFreetext
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {
          Alert.alert(
            "Ok, die Abholung wurde zurückgewiesen!",
            "Du musst das Paket somit jetzt nicht mehr liefern!",
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate("MyDeliveryParcelDetail", {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "notexist") {
            errmess = "Das ist nicht die richtige Sendungsnummer.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt...",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },
  containerTop: {
    flex: 1
  }
});
