import React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View, Alert, Dimensions, TextInput,FlatList,Image} from "react-native";
import * as Linking from 'expo-linking'
import * as Permissions from "expo-permissions";
import { Camera } from 'expo-camera';
import { Button, Text, Overlay } from "react-native-elements";
import HeaderImage from "../components/HeaderImage";
import { TouchableItem } from "../../components/TouchableItem";
import { formatDate, stringFormatDate } from "../helper/DateFormat";
import Icon from "react-native-vector-icons/FontAwesome";
import { BarCodeScanner } from "expo-barcode-scanner";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

var self;
export default class MyDeliveryParcelDeliveryReturn extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      hasCameraPermission: null,
      id: "",
      parcelId: "1",
      loaderIsVisible: false,
      mandateLayerVisible: false,
      pickupLayerVisible: false,
      type: Camera.Constants.Type.back,
      cameraReady:false,
      showWaiting:false,
      doscan: false,
      jobid: "",
      returndlry:false,
      imgArr:[],
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Sendungsdetails`,
      headerBackTitle: `zurück`
    };
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");
    if (nextProps.navigation.state.params.jobid != "") {
      this.setState({
        jobid: nextProps.navigation.state.params.jobid,
        parcelId: nextProps.navigation.state.params.parcelid,
        returndlry: nextProps.navigation.state.params.returndlry
      });
    }
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);
    console.log("this.state.returndlry " + this.state.returndlry);
  }

  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: this.refreshHandler,
      parcelId: tmp["id"]
    });
    //console.log(tmp);
  }
  async _get_cameramermission() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    this.setState({ hasCameraPermission: status === "granted" });
  }
  refreshHandler(id) {
    //self.props.navigation.navigate("MyParcelEdit", { itemID: id });
  }


  takePicture = async() => {
    if (this.camera) {
      this.setState({cameraReady:false});
      this.setState({showWaiting:true});
      const options = { quality: 0.1, base64: true };
      const data = await this.camera.takePictureAsync(options)
        .then(data => {
          console.log('data raw:' + data);
          console.log('data uri:' + data.base64);
          this._saveImage('data:image/png;base64,'+data.base64);
         
        });
    }else{
     // alert("no cam");
    }
  };
  _saveImage = (uri) => {

    var newobj=[...this.state.imgArr,uri];
    this.setState({imgArr:newobj});
    this.setState({ doscan: false });
    this.setState({showWaiting:false});
  }
  
  
  async accessCamera()
  {
    if (this.state.isCapturing)
    {
        //let photo = await this.camera.takePictureAsync();

        try {
          const options = { quality: 0.5, base64: true };
          const cameraData = await this.camera.takePictureAsync(options)
          console.log(cameraData.uri);
        } catch (e) {
         // This logs the error
          console.log(e)
        }


        this.setState({ isCapturing: false, accessCameraLabel: 'Retake', capturedPhoto: photo.uri});
    }
    else
    {
          this.setState({ isCapturing: true, accessCameraLabel: 'Capture', capturedPhoto: null});
    }
  }
  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, jobid, provider, recvr_firstname, recvr_lastname, dest_street, dest_no, dest_zip, dest_city, dest_lat, dest_lng, location_lat, location_lng, location_type, location_street, location_no, location_zip, location_city, time_from, time_to, time_from2, time_to2, distanceText, durationText, costs, currency } = this.props;

    const dest_coords = {
      latitude: parseFloat(itemdata["dest_lat"]),
      longitude: parseFloat(itemdata["dest_lng"])
    };
    const coords = {
      latitude: parseFloat(itemdata["location_lat"]),
      longitude: parseFloat(itemdata["location_lng"])
    };

    
    //const itemId = this.props.navigation.state.params.itemId;
    // const itemId = "test";
    if (this.state.doscan === true) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <Camera 
          
          ref={ref => {
            this.camera = ref;
          }}
          style={{ flex: 1 }} type={this.state.type}
          onCameraReady={this._cameraReady}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: 'transparent',
                flexDirection: 'column',
              }}>

               
              <View style={styles.CameraRotateButton}>
              <TouchableOpacity
                
                onPress={() => {
                  this.setState({type:
                    this.state.type === Camera.Constants.Type.back
                      ? Camera.Constants.Type.front
                      : Camera.Constants.Type.back
                   });
                }}>
               
                <Icon name="refresh" size={26} color="white" style={{ }} />
               
              </TouchableOpacity>
              </View>

              <View  style={styles.CameraCenter} >

                <View style={[styles.waitingText,{display:this.state.showWaiting ? 'flex':'none'}]}><Text style={{color:'white'}}>Bitte warten...</Text></View>
              </View>

              <View  style={styles.CameraButton} >
                 <TouchableOpacity style={[styles.CameraButtonInner,{display:this.state.cameraReady ? 'flex':'none'}]} onPress={()=>this.takePicture()}> 
                          <Icon name="camera" size={20} color="white" style={styles.CameraIcon} />
                  </TouchableOpacity>
                  </View>


            </View>
          </Camera>

          <Button title={"Abbrechen"} style={styles.abortscan} onPress={() => this.setState({ doscan: false })} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>

        <View style={{  padding: 0, justifyContent: "flex-start"}}>
          <HeaderImage image="scan" />
        </View>
        <View style={{  padding: 5, justifyContent: "center", flex: 1}}>
          
          <Text
            style={{
              alignSelf: "center",
              textAlign: "center",
              marginBottom: 10
            }}
          >
            Bestätige die Abgabe durch ein Foto des Pakets/ der einzelnen Pakete und der Quittung:
          </Text>


          <FlatList ItemSeparatorComponent={this.ListViewItemSeparator} 
          data={this.state.imgArr} 
          keyExtractor={item => item}
          style={{borderColor:'#ccc',borderWidth:1,height:200,flex:0.5,flexDirection: 'column'}} 
          numColumns={2}
          renderItem={({ item, index, separators }) => (
              <View style={{flex:0.5,width:'25%'}}>

              <View style={{borderColor:'#cccccc',borderWidth:1,margin:5}}> 
              <Image style={{width:'100%',height:180,resizeMode: 'contain' }} source={{ uri: item}} />
                <View style={{flexDirection:'row'}}>
                <Text style={[styles.ListEntry,{flexGrow:1,textAlignVertical:'center'}]}>Paket {index+1}</Text>

                <Button onPress={() => {this.removeNumber(index)}} 
                color="#ffffff" 
                icon={<Icon name="remove" size={20} color="#666666" style={{ }} />} 
                buttonStyle={{ alignSelf:"flex-end",backgroundColor:'rgba(52, 52, 52, 0)',borderWidth: 0,padding:0,borderRadius: 0,width:50 ,height:40}} />
                 </View>
                 </View>
            </View>
            )}
          />

         
            <Button onPress={this._startqr} 
            icon={<Icon name="camera" size={20} color="white" style={{ }} />} 
            buttonStyle={{ marginTop:5,borderWidth: 0,padding:10,borderRadius: 0}} />


        </View>
        <View style={{ padding: 5, justifyContent: "flex-end" ,height:60 }}>
          <Button onPress={this._nextstep} title={"Bestätigen"} disabled={this.state.imgArr.length > 0 ? false : true} buttonStyle={{ borderRadius: 0 }} />
        </View>
      </View>
    );
  }

  _cameraReady = () =>{

    this.setState({cameraReady:true});
    this.setState({isCapturing:true});
    
  }

  removeNumber = (indx) =>{
    var tmp=this.state.imgArr;
    tmp.splice(indx, 1);
    this.setState({imgArr: tmp});
  }
  _nextstep = () => {

    Alert.alert(
      "Paket abgeholt",
      "Soll die Abgabe des Pakets/ der Pakete jetzt bestätigt werden?",
      [
        {
          text: "Nein",
          onPress: () => {}
        },
        {
          text: "Ja",
          onPress: () => this._pickup(this.state.jobid, this.state.parcelId,+this.state.returndlry)
        }
      ],
      { cancelable: true }
    );
  };

  _startqr = async () => {
    if (this.state.hasCameraPermission === null) {
      //
      await this._get_cameramermission();
      if (this.state.hasCameraPermission === true) {
        this.setState({ doscan: true });
      } else {
        Alert.alert(
          "Hinweis",
          "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
          [
            {
              text: "OK",
              onPress: () => {
                this.setState({ loaderIsVisible: false });
                Linking.openURL("app-settings://notification/misterpostman");
              }
            }
          ],
          { cancelable: false }
        );
      }
    } else if (this.state.hasCameraPermission === false) {
      Alert.alert(
        "Hinweis",
        "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
        [
          {
            text: "OK",
            onPress: () => {
              this.setState({ loaderIsVisible: false });
              Linking.openURL("app-settings://notification/misterpostman");
            }
          }
        ],
        { cancelable: false }
      );
      return;
    } else {
      this.setState({ doscan: true });
    }
  };

  _pickup = (jobid, parcelid,returndlry) => {
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);

    
    
    var next_screen="MyDeliveryParcelDetailRetour";


    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/deliver_return.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: jobid,
        parcelID: parcelid,
        do_return: returndlry,
        images:this.state.imgArr
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {

          var  okmess = "Super, das Paket ist abgeliefert!";
          var alert_text="Dein Job ist hiermit erfolgreicht erledigt!";
          if (responseJson.code == "multi") {
            okmess = "Super, die Pakete wurden abgeliefert!";
            alert_text="Dein Job ist hiermit erfolgreicht erledigt!";
          }
          
          Alert.alert(
            okmess,
            alert_text,
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate(next_screen, {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "wrongcount") {
            errmess = "Bitte prüfe die Anzahl der Pakete.";
          }else if (responseJson.code == "notexist1") {
            errmess = "Das ist nicht die richtige Sendungsnummer.";
          }else if (responseJson.code == "notexist2") {
            errmess = "Das sind nicht die richtigen Sendungsnummer."; 
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt...",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };
}
function  Paketfoto () {
  //const {width, height} = Image.resolveAssetSource(picture);
  

  Image.getSize(this.props.source, (width, height) => { 
    console.log(width);
    //this.setState({ width, height }) 
   //return (<Text>{width} {height}</Text>)
  }
  );
  
}
const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    flex: 1,
    marginBottom: 10
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  },
  ListEntry:{
    textAlign:'left',
    padding:10,
    flex:1,
  },
  CameraRotateButton:{
    
    justifyContent:'center',
    height:50,
    alignItems:'flex-end',
   paddingRight:10,
  },
  CameraButton:{
    justifyContent:'center',
    alignItems:'center',
    height:60,
    marginBottom:30,
},
CameraButtonInner:{
  paddingTop:0,
  paddingLeft:0,
  borderRadius: 100,
  backgroundColor:"rgba(255, 255, 255, .2)",
  borderWidth:1,
  borderColor:'#cccccc',
},
CameraIcon:{
  padding:20,
},
CameraCenter:{
  justifyContent:'center',
  alignItems:'center',
  flex:1,
},
waitingText:{
  borderWidth:1,
  borderColor:'#cccccc',
  color:'white',
  backgroundColor:"rgba(255, 255, 255, .2)",
  padding:10,
  borderRadius: 10,
}

});
