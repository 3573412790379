import React from "react";
import { StyleSheet, View, TextInput, Alert, ActivityIndicator, Vibration } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Permissions from "expo-permissions";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as Linking from 'expo-linking'
import { Button, Text } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";

export default class AddCourierNumberScreen extends React.Component {

 
  render() {
    if (this.props.navigation.state.params != undefined && this.props.navigation.state.params.editParcel == true) {
      var parcelItem = this.props.navigation.state.params.parcelItem;
      var editParcel = true;
    } else {
      var parcelItem = [];
      var editParcel = false;
    }
    return <MyContext.Consumer>{context => <ParcelDataHandler editParcel={editParcel}  context={context} navigation={this.props.navigation} />}</MyContext.Consumer>;
  }
}

class ParcelDataHandler extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      hasCameraPermission: null,
      scanned: false,
      doscan: false,
      type: BarCodeScanner.Constants.Type.back,
      result: null
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.navigation.state.params != undefined && this.props.navigation.state.params.editParcel == true) {



      var parcelItem=this.props.navigation.state.params.parcelItem;


      this.props.context.setNewParcelValue("parcelService", 'courier');
      this.props.context.setNewParcelValue("parcelCourierService", parcelItem["service2"]);
      this.props.context.setNewParcelValue("parcelProvider", parcelItem["provider"]);
      this.props.context.setNewParcelValue("parcelNumber", parcelItem["parcel_id"]);
      this.props.context.setNewParcelValue("parcelType", parcelItem["location_type"]);
      this.props.context.setNewParcelValue("parcelWeight", parcelItem["weight"]);
      this.props.context.setNewParcelValue("parcelCode", parcelItem["parcel_pin"]);
      this.props.context.setNewParcelValue("parcelCustomerID", parcelItem["parcel_customerid"]);
      this.props.context.setNewParcelValue("parcelLocFirstname", parcelItem["location_firstname"]);
      this.props.context.setNewParcelValue("parcelLocLastname", parcelItem["location_lastname"]);
      this.props.context.setNewParcelValue("parcelLocStreet", parcelItem["location_street"]);
      this.props.context.setNewParcelValue("parcelLocNumber", parcelItem["location_no"]);
      this.props.context.setNewParcelValue("parcelLocAdditional", parcelItem["location_additional"]);
      this.props.context.setNewParcelValue("parcelLocZip", parcelItem["location_zip"]);
      this.props.context.setNewParcelValue("parcelLocCity", parcelItem["location_city"]);

      var tmploc_formaddress = parcelItem["location_street"] + " " + parcelItem["location_no"] + ", " + parcelItem["location_zip"] + " " + parcelItem["location_city"] + " Deutschland";
      this.props.context.setNewParcelValue("parcelLocFormattedAddress", tmploc_formaddress);

      this.props.context.setNewParcelValue("parcelLocPlaceID", parcelItem["location_placeid"]);

      this.props.context.setNewParcelValue("parcelLocLocation", {
        coords: {
          latitude: Number.parseFloat(parcelItem["location_lat"]),
          longitude: Number.parseFloat(parcelItem["location_lng"])
        }
      });
      this.props.context.setNewParcelValue("parcelDestStreet", parcelItem["dest_street"]);
      this.props.context.setNewParcelValue("parcelDestNumber", parcelItem["dest_no"]);
      this.props.context.setNewParcelValue("parcelDestAdditional", parcelItem["dest_additional"]);
      this.props.context.setNewParcelValue("parcelDestZip", parcelItem["dest_zip"]);
      this.props.context.setNewParcelValue("parcelDestCity", parcelItem["dest_city"]);

      var tmpdest_formaddress = parcelItem["dest_street"] + " " + parcelItem["dest_no"] + ", " + parcelItem["dest_zip"] + " " + parcelItem["dest_city"] + " Deutschland";
      this.props.context.setNewParcelValue("parcelDestFormattedAddress", tmpdest_formaddress);

      this.props.context.setNewParcelValue("parcelDestPlaceID", parcelItem["dest_placeid"]);
      this.props.context.setNewParcelValue("parcelDestLocation", {
        coords: {
          latitude: Number.parseFloat(parcelItem["dest_lat"]),
          longitude: Number.parseFloat(parcelItem["dest_lng"])
        }
      });
      /*
      convert pickuptime
      */
      var t = parcelItem["time_from_pickup"].split(/[- :]/);
      var tmpFrom = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
      t = parcelItem["time_to_pickup"].split(/[- :]/);
      var tmpTo = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

      this.props.context.setNewParcelValue("parcelDatePickupFrom", tmpFrom);
      this.props.context.setNewParcelValue("parcelDatePickupTo", tmpTo);

      this.props.context.setNewParcelValue("parcelEditID", parcelItem["id"]);
    }
  }
  componentDidUpdate(prevProps, prevState) {}

  async _get_cameramermission() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    this.setState({ hasCameraPermission: status === "granted" });
  }

  _extractNumber = nr => {
    console.log(nr);
    if (nr.split("http://nd.dpd.de/?pknr=").length > 1) {
      return nr.split("http://nd.dpd.de/?pknr=")[1];
    } else if (nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=").length > 1) {
      return nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=")[1];
    } else if (nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=").length > 1) {
      return nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=")[1];
    } else {
      return "";
    }
  };
  handleBarCodeScanned = async ({ type, data }) => {
    //Vibration.vibrate(100);
    if (this.isURL(data)) {
      this.props.context.setNewParcelValue("parcelNumber", this._extractNumber(data));
    } else if (data.indexOf(" ") == -1) {
  
      this.props.context.setNewParcelValue("parcelNumber", data);
    }
    this.setState({ scanned: true });
    this.setState({ doscan: false });

    //alert(`Code erkannt! Typ: ${type} mit folgenden Daten: ${data}`);
  };
  isURL = str => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };

  render() {
    const { hasCameraPermission, scanned, doscan } = this.state;

    /* if (hasCameraPermission === null) {
      return (
        <View style={{ flex: 1, paddingTop: 20 }}>
          <ActivityIndicator />
        </View>
      );
    }
    if (hasCameraPermission === false) {
      return <Text>Kein Zugriff auf die Kamera</Text>;
    }*/

    if (doscan === true) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <BarCodeScanner onBarCodeScanned={this.handleBarCodeScanned} style={StyleSheet.absoluteFillObject} type={this.state.type} />

          <Button title={"Abbrechen"} style={styles.abortscan} onPress={() => this.setState({ doscan: false })} buttonStyle={{ borderRadius: 0 }} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
          <HeaderImage image="scan" />
        </View>
        <View style={{ padding: 5, justifyContent: "center", flex: 1 }}>
          <View style={{ padding: 10, borderColor: "#ccc", borderWidth: 1 ,flexDirection:'row'  }}>

                <View>
              <Button  onPress={this._startqr} icon={<Icon name="barcode" size={22} color="#1e90ff" style={{ padding:0}} />} titleStyle={{fontSize:0}} buttonStyle={{ backgroundColor:'#ffffff',paddingTop:4, paddingLeft:10,paddingRight:10, borderWidth: 0,padding:0,borderRadius: 0,height:28,alignSelf:"flex-start"}} />
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:2,left:5}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:21,left:5}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:2,left:30}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:21,left:30}}></View>
              </View>

            <TextInput style={{paddingLeft:5,height:32,borderColor: "#ff0000", borderWidth: 0,alignSelf:"flex-start" ,flexGrow: 1}} placeholder={"Paketnummer"} onChangeText={value => this.props.context.setNewParcelValue("parcelNumber", value)} value={this.props.context.state.parcelNumber} />
          </View>

          <Button onPress={this._startqr} title={"Scannen"} icon={<Icon name="barcode" size={20} color="white" style={{ paddingRight: 5 }} />} buttonStyle={{display:"none",  borderRadius: 0 }} />
        </View>

        <View style={{ padding: 5, justifyContent: "flex-end", flex: 1 }}>
          <Button onPress={this._nextstep} title={"Weiter"}  disabled={this.props.context.state.parcelNumber != "" ? false : true} buttonStyle={{borderRadius: 0 }} />
        </View>
      </View>
    );
  }

  _openqr = () => {
    alert("open qr scanner");
  };

  _startqr = async () => {
    if (this.state.hasCameraPermission === null) {
      //
      await this._get_cameramermission();
      if (this.state.hasCameraPermission === true) {
        this.setState({ doscan: true });
      } else {
        Alert.alert(
          "Hinweis",
          "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
          [
            {
              text: "OK",
              onPress: () => {
                this.setState({ loaderIsVisible: false });
                Linking.openURL("app-settings://notification/misterpostman");
              }
            }
          ],
          { cancelable: false }
        );
      }
    } else if (this.state.hasCameraPermission === false) {
      Alert.alert(
        "Hinweis",
        "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
        [
          {
            text: "OK",
            onPress: () => {
              this.setState({ loaderIsVisible: false });
              Linking.openURL("app-settings://notification/misterpostman");
            }
          }
        ],
        { cancelable: false }
      );
      return;
    } else {
      this.setState({ doscan: true });
    }
  };

  _nextstep = () => {
    this.props.navigation.navigate("AddCourierWeight");
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  },
  abortscan: {
    backgroundColor: "#fff",
    marginBottom: 20
  }
});
