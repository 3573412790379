import React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View, Alert, Dimensions, TextInput, Platform } from "react-native";
import * as Linking from 'expo-linking'
import { Button, Text, Image, Overlay, AirbnbRating } from "react-native-elements";
import ProviderName from "../helper/ProviderName";
import ProviderLogo from "../helper/ProviderLogo";
import LocationType from "../helper/LocationType";
import HeaderImage from "../components/HeaderImage";
import { TouchableItem } from "../../components/TouchableItem";
import { formatDate, stringFormatDate } from "../helper/DateFormat";
import MapView from "react-native-maps";
import ImageZoom from "react-native-image-pan-zoom";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

var self;
export default class MyDeliveryParcelDetailRetour extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      id: "",
      parcelId: "1",
      jobid: "",
      loaderIsVisible: true,
      pickupLayerVisible: false,
      pickupParcelNumber: "",
      pickupStatus: false,
      rejectStatus: "",
      rejectFreetext: "",
      deliveryStatus: false,
      stornoStatus: false,
      itemData: "",
      ratingIsVisible: false,
      ratingValue: 4,
      ratingIsSet: false,
      ringIsVisible:false
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Sendungsdetails`,
      headerBackTitle: `zurück`
    };
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.check == true) {
      var { navigation } = this.props;
      var itemdata = navigation.getParam("itemdata", []);
      this.setState({
        itemData: itemdata,
        jobid: itemdata["jobid"],
        jobid: itemdata["jobid"],
        parcelId: itemdata["id"]
      });

      this._statuscheck(itemdata["jobid"]);
    }
  }

  _statuscheck = jobid => {
    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/checkParcelStatus.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: jobid
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        //console.log(responseJson);
        if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!" + responseJson.test;
          var errtit = "Ups...";
          if (responseJson.code == "notexist") {
            errmess = "Lieferung existiert nicht.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          this.setState({ loaderIsVisible: false });
          if (responseJson.pickup == "0") {
            this.setState({ pickupStatus: false });
          } else {
            this.setState({ pickupStatus: true });
            this.setState({ stornoStatus: true });
          }
          if (responseJson.reject == "0") {
            this.setState({ rejectStatus: false });
          } else {
            this.setState({ pickupStatus: true });
            this.setState({ rejectStatus: true });
            this.setState({ stornoStatus: true });
          }
          this.setState({ rejectFreetext: responseJson.freetext });
          if (responseJson.delivery == "0") {
            this.setState({ deliveryStatus: false });
          } else {
            this.setState({ deliveryStatus: true });
          }
          if (responseJson.rating != undefined) {
            this.setState({
              ratingValue: responseJson.rating,
              ratingIsSet: true
            });
          }
          console.log(responseJson);
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };
  _ring= (ringval) => {
    this.setState({ isLoading: true });

    fetch(apiUrl + "parcel/ring.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: this.state.jobid,
        parcelID: this.state.parcelId,
        type: "pickup",
        ring: ringval
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {
          
          this.setState({ isLoading: false });
          Alert.alert(
            "Super!",
            "Danke, dass Du hast Dich angekündigt hast!",
            [
              {
                text: "OK",
                onPress: () => this.setState({ ringIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else if(typeof responseJson.error !== "undefined") {

          this.setState({ isLoading: false });
          Alert.alert(
            "Hinweis",
            "Du hast Dich bereits für diesen Zeitpunkt angekündigt.",
            [
              {
                text: "OK",
                onPress: () => this.setState({ ringIsVisible: false })
              }
            ],
            { cancelable: false }
          );

        } else {
          this.setState({ isLoading: false });
          Alert.alert(
            "Ups...",
            "Da ist etwas schief gelaufen....",
            [
              {
                text: "OK",
                onPress: () => this.setState({ ringIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
       
      })
      .catch(error => {
        console.error(error);
      });
  };
  _rateDelivery = () => {
    this.setState({ isLoading: true });

    fetch(apiUrl + "parcel/rate.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: this.state.jobid,
        parcelID: this.state.parcelId,
        ratingValue: this.state.ratingValue,
        rateType: "misterPostman"
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {
          this.setState({ isLoading: false, ratingIsSet: true });
        } else {
          this.setState({ isLoading: false });
          Alert.alert(
            "Ups...",
            "Da ist etwas schief gelaufen....",
            [
              {
                text: "OK"
              }
            ],
            { cancelable: false }
          );
        }
        this.setState({ ratingIsVisible: false });
      })
      .catch(error => {
        console.error(error);
      });
  };
  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: this.refreshHandler,
      parcelId: tmp["id"]
    });
    //console.log(tmp);
  }
  refreshHandler(id) {
    self.props.navigation.navigate("MyParcelEdit", { itemID: id });
  }

  _editParcel = () => {
    alert("_editParcel");
  };
  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, jobid, provider, recvr_firstname, recvr_lastname, dest_street, dest_no, dest_zip, dest_city, dest_lat, dest_lng, location_lat, location_lng, location_type, parcel_customerid, parcel_pin, location_street, location_no, location_zip, location_city, time_from, time_to, time2, time_from2, time_to2, distanceText, durationText, profit, currency } = this.props;

    const coords = {
      latitude: parseFloat(itemdata["location_lat"]),
      longitude: parseFloat(itemdata["location_lng"])
    };
    //const itemId = this.props.navigation.state.params.itemId;
    // const itemId = "test";

    return (
      <ScrollView>
        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>

        <Overlay isVisible={this.state.ratingIsVisible}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View style={{ padding: 5, justifyContent: "flex-start" }}>
              <Text style={{ alignSelf: "center", paddingTop: 10, fontSize: 20 }}>Bewerte diese Retoure</Text>
            </View>
            <View
              style={{
                padding: 5,
                justifyContent: "center",
                flex: 1,
                flexDirection: "column",
                paddingTop: 10
              }}
            >
              <AirbnbRating
                count={5}
                reviews={["Schlecht", "Nicht so gut", "Noch OK", "Gut", "Super"]}
                defaultRating={this.state.ratingValue}
                onFinishRating={val => {
                  this.setState({ ratingValue: val });
                }}
              />
            </View>
            <View style={{ padding: 5 }}>
              <Button
                onPress={() => this._rateDelivery()}
                title={"OK"}
                style={{
                  width: "75%",
                  alignSelf: "center",
                  marginBottom: 0,
                  justifyContent: "flex-end"
                }}
                buttonStyle={{ borderRadius: 0 }}
              />
            </View>
          </View>
        </Overlay>

        <Overlay isVisible={this.state.ringIsVisible}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View style={{ padding: 5, justifyContent: "flex-start" }}>
              <Text style={{ alignSelf: "center", paddingTop: 10, fontSize: 18, textAlign: "center"  }}>Kündige Dich für die Abgabe der Lieferung an:</Text>
            </View>
            <View
              style={{
                padding: 5,
                justifyContent: "center",
                flex: 1,
                flexDirection: "column",
                paddingTop: 10
              }}
            >
              <Button
                onPress={() => {this._ring(1);}} title={"Ich bin in 45 min da!"} style={{width: "75%", alignSelf: "center",marginBottom: 10,justifyContent: "flex-end"}} buttonStyle={{ borderRadius: 0 }}
              />
              <Button
                onPress={() => {this._ring(2);}} title={"Ich bin in 30 min da!"} style={{width: "75%", alignSelf: "center",marginBottom: 10,justifyContent: "flex-end"}} buttonStyle={{ borderRadius: 0 }}
              />
               <Button
                  onPress={() => {this._ring(3);}} title={"Ich bin in 15 min da!"} style={{width: "75%", alignSelf: "center",marginBottom: 10,justifyContent: "flex-end"}} buttonStyle={{ borderRadius: 0 }}
              />
               <Button
                 onPress={() => {this._ring(4);}} title={"Ich bin da!"} style={{width: "75%", alignSelf: "center",marginBottom: 0,justifyContent: "flex-end"}} buttonStyle={{ borderRadius: 0 }}
              />
            </View>
            <View style={{ padding: 5 }}>
              <Button
                onPress={() => {
                  this.setState({ ringIsVisible: false });
                }}
                title={"Abbrechen"}
                style={{
                  width: "75%",
                  alignSelf: "center",
                  marginBottom: 0,
                  justifyContent: "flex-end"
                }}
                buttonStyle={{ borderRadius: 0 }}
              />
            </View>
          </View>
        </Overlay>



        <View style={styles.container}>
          <HeaderImage image="empfangen" />

          <View style={[styles.entryRow, { borderColor: "grey", borderWidth: 1, paddingLeft: 10 }]}>
            <View style={styles.entryRowTitle}>
              <ProviderLogo provider={itemdata["provider"]} />
            </View>
            <View style={styles.entryRowDetail}>
              {this.parcelNumberList(itemdata["parcel_id"])}
            </View>
          </View>

          <View>
            <Button
              onPress={() => {
                this.setState({ ringIsVisible: true });
              }}
              title={"Klingeln"}
              buttonStyle={{marginTop: 10, marginBottom: 10, borderRadius: 0 }}
              disabled={this.state.pickupStatus}
              iconRight
              icon={<Icon name="bell" size={20} color="#0ad000" style={{ paddingLeft: 5, color: !this.state.pickupStatus  ? "white" : "#999999" } } /> }
            />
          </View> 

          <View style={{}}>
            <Button
              onPress={() => this._pickup(itemdata["id"], itemdata["jobid"])}
              title={"Abholung bestätigen"}
              buttonStyle={{  marginBottom: 10, borderRadius: 0 }}
              disabled={this.state.pickupStatus}
              iconRight
              icon={this.state.pickupStatus ? <Icon name={this.state.pickupStatus && !this.state.rejectStatus ? "check" : "check"} size={20} color="grey" style={this.state.pickupStatus && !this.state.rejectStatus ? { paddingLeft: 5 } : { paddingLeft: 0, display: "none" }} /> : <Icon />}
            />
          </View>
          <View style={{}}>
            <Button
              onPress={() => this._reject(itemdata["id"], itemdata["jobid"])}
              title={"Abholung nicht möglich"}
              buttonStyle={{ marginBottom: 10, borderRadius: 0 }}
              disabled={this.state.pickupStatus}
              iconRight
            />
          </View>
          <Text style={{ marginBottom: 15, padding: 10, backgroundColor: "#ffefbf", display: this.state.pickupStatus == true && this.state.rejectStatus == true ? "flex" : "none" }}>Grund: {this.state.rejectFreetext}</Text>

          <View style={{}}>

            <Button
              onPress={() => this._deliver(itemdata["id"], itemdata["jobid"])}
              title={"Abgabe bestätigen"}
              buttonStyle={{ marginBottom: 10, borderRadius: 0 }}
              disabled={this.state.pickupStatus == true &&  this.state.deliveryStatus != true ? false : true}
              iconRight
              icon={this.state.deliveryStatus ? <Icon name={this.state.deliveryStatus ? "check" : "check"} size={20} color="grey" style={this.state.deliveryStatus  ? { paddingLeft: 5 } : { paddingLeft: 0, display: "none" }} /> : <Icon />}
            />
          </View>


          <View>
            <Button
              onPress={() => {
                this.setState({ ratingIsVisible: true });
              }}
              title={"Abholung bewerten"}
              buttonStyle={{ marginBottom: 10, borderRadius: 0 }}
              disabled={this.state.deliveryStatus == true ? false : true}
              iconRight
              icon={this.state.ratingIsSet ? <Icon name={this.state.ratingIsSet ? "check" : ""} size={20} color="#0ad000" style={this.state.ratingIsSet ? { paddingLeft: 5 } : ""} /> : <Icon />}
            />
          </View>

          <View style={styles.entryRow}>
            <Text h4Style={styles.entryRowSubHeadline} h4>
              Abholadresse
            </Text>
          </View>
          <View style={[styles.entryRow,{display:itemdata["location_company"] != "" ?"flex" :"none"}]}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Firma</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_company"]}
              </Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Vorname Nachname</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_firstname"]} {itemdata["location_lastname"]}
              </Text>
            </View>
          </View>


          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_additional"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Strasse, Nr.</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_street"]} {itemdata["location_no"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>PLZ Ort</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_zip"]} {itemdata["location_city"]}
              </Text>
            </View>
          </View>
          <View pointerEvents="none">
            <MapView
              initialRegion={{
                latitude: parseFloat(itemdata["location_lat"]),
                longitude: parseFloat(itemdata["location_lng"]),
                latitudeDelta: 0.01,
                longitudeDelta: 0.01
              }}
              style={{ flex: 1, height: 100 }}
              // onPress={() => alert('hello')}
              mapType="standard"
              showsCompass={true}
              showsBuildings={true}
              showsTraffic={true}
              showsIndoors={true}
            >
              <MapView.Marker coordinate={coords} />
            </MapView>
          </View>
          <Button onPress={() => this._openmaps(itemdata["location_street"] + " " + itemdata["location_no"] + ", " + itemdata["location_zip"] + " " + itemdata["location_city"])} title={"Karte öffnen"} buttonStyle={{ marginTop: 30, marginBottom: 10, borderRadius: 0 }} />
            
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>von</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_from"])}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>bis</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_to"])}</Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <Text h4Style={styles.entryRowSubHeadline} h4>
              Paket
            </Text>
          </View>


          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Verdienst</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["profit"]} {itemdata["currency"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Versandunternehmen</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text>
                <ProviderName provider={itemdata["provider"]} />
              </Text>
              <ProviderLogo provider={itemdata["provider"]} />
            </View>
          </View>

            
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Gewicht</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                <LocationType value={itemdata["weight"]} /> Kg
              </Text>
            </View>
          </View>






          <View style={{}}>
            <Button
              onPress={() => this._storno(itemdata["jobid"])}
              title={"Abholung stornieren"}
              disabled={this.state.stornoStatus}
              buttonStyle={{
                marginTop: 10,
                marginBottom: 20,
                backgroundColor: "#dc143c",
                borderRadius: 0
              }}
            />
          </View>
        </View>
      </ScrollView>
    );
  }
  parcelNumberList = (obj) => {

    return  obj.split(",").map((data) => {
      return (
        <View key={data} style={{paddingRight: 15,paddingBottom:5, }}><Text>{data}</Text></View>
      )
    })
  }
  _openmaps = address => {
    if (Platform.OS === "ios") {
      Linking.openURL("http://maps.apple.com/?daddr=" + address);
    } else {
      Linking.openURL("http://maps.apple.com/?daddr=" + address);
    }
  };
  _pickup = (id, jobid) => {
    this.props.navigation.navigate("MyDeliveryParcelPickupReturn", {
      parcelid: id,
      jobid: jobid,
      returndlry:true,
    });
  };
  _deliver = (id, jobid) => {
    this.props.navigation.navigate("MyDeliveryParcelDeliveryReturn", {
      parcelid: id,
      jobid: jobid,
      returndlry:true,
    });
  };
  _reject = (id, jobid) => {
    this.props.navigation.navigate("MyDeliveryParcelRejectReturn", {
      parcelid: id,
      jobid: jobid,
      returndlry:true
    });
  };


  _storno = jobid => {
    Alert.alert(
      "Lieferung stornieren",
      "Bist Du Dir sicher, dass Du die Lieferung stornieren möchtest? ",
      [
        {
          text: "Ja",
          onPress: () =>
            // this.props.navigation.navigate("MyParcels", { refresh: true })
            this._storno2(jobid)
        },
        {
          text: "Nein"
        }
      ],
      { cancelable: true }
    );
  };
  _storno2 = jobid => {
    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/stornojob.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: jobid,
        do_return:true
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        //console.log(responseJson);
        if (typeof responseJson.success !== "undefined") {
          Alert.alert(
            "Abholung ist storniert!",
            "Du musst dieses Paket nicht mehr abholen. ",
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate("DeliveryOverview", {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "notexist") {
            errmess = "Lieferung existiert nicht.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          // await alert("Login fehlgeschlagen.");
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt...",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
          //this.setState({ loaderIsVisible: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
