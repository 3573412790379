import React from "react";
import { ScrollView, StyleSheet, View, Alert, Dimensions, TextInput,FlatList } from "react-native";
import * as Linking from 'expo-linking'
import * as Permissions from "expo-permissions";
import { Button, Text, Image, Overlay } from "react-native-elements";
import HeaderImage from "../components/HeaderImage";
import { TouchableItem } from "../../components/TouchableItem";
import { formatDate, stringFormatDate } from "../helper/DateFormat";
import Icon from "react-native-vector-icons/FontAwesome";
import { BarCodeScanner } from "expo-barcode-scanner";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

var self;
export default class MyDeliveryParcelPickup extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      hasCameraPermission: null,
      id: "",
      parcelId: "1",
      loaderIsVisible: false,
      mandateLayerVisible: false,
      pickupLayerVisible: false,
      pickupParcelNumber: "",
      pickupParcelNumbers: [],
      type: BarCodeScanner.Constants.Type.back,
      doscan: false,
      jobid: "",
      returndlry:false
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Sendungsdetails`,
      headerBackTitle: `zurück`
    };
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("UNSAFE_componentWillReceiveProps");
    if (nextProps.navigation.state.params.jobid != "") {
      this.setState({
        jobid: nextProps.navigation.state.params.jobid,
        parcelId: nextProps.navigation.state.params.parcelid,
        returndlry: nextProps.navigation.state.params.returndlry
      });
    }
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);
    console.log("this.state.returndlry " + this.state.returndlry);
  }

  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: this.refreshHandler,
      parcelId: tmp["id"]
    });
    //console.log(tmp);
  }
  async _get_cameramermission() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    this.setState({ hasCameraPermission: status === "granted" });
  }
  refreshHandler(id) {
    self.props.navigation.navigate("MyParcelEdit", { itemID: id });
  }

  _editParcel = () => {
    alert("_editParcel");
  };
  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, jobid, provider, recvr_firstname, recvr_lastname, dest_street, dest_no, dest_zip, dest_city, dest_lat, dest_lng, location_lat, location_lng, location_type, location_street, location_no, location_zip, location_city, time_from, time_to, time_from2, time_to2, distanceText, durationText, costs, currency } = this.props;

    const dest_coords = {
      latitude: parseFloat(itemdata["dest_lat"]),
      longitude: parseFloat(itemdata["dest_lng"])
    };
    const coords = {
      latitude: parseFloat(itemdata["location_lat"]),
      longitude: parseFloat(itemdata["location_lng"])
    };
    //const itemId = this.props.navigation.state.params.itemId;
    // const itemId = "test";
    if (this.state.doscan === true) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <BarCodeScanner onBarCodeScanned={this.handleBarCodeScanned} style={StyleSheet.absoluteFillObject} type={this.state.type} />

          <Button title={"Abbrechen"} style={styles.abortscan} onPress={() => this.setState({ doscan: false })} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>

        <View style={{ padding: 5, justifyContent: "flex-start"}}>
          <HeaderImage image="scan" />
        </View>
        <View style={{ padding: 5, justifyContent: "center", flex: 1}}>
          <Text style={{ alignSelf: "center" }}>Du hast das Paket/ die Pakete abgeholt?</Text>
          <Text
            style={{
              alignSelf: "center",
              textAlign: "center",
              marginBottom: 20
            }}
          >
            Bestätige die Abholung durch die Eingabe der Paketnummer(n):
          </Text>


          <View style={{ marginTop:5,height: 32,padding: 0, borderColor: "#ccc", borderWidth: 1,flexDirection:'row'  }}>
            <View>
              <Button  onPress={this._startqr}  icon={<Icon name="barcode" size={22} color="#1e90ff" style={{ padding:0}} />} titleStyle={{fontSize:0}} buttonStyle={{ backgroundColor:'#ffffff',paddingTop:4, paddingLeft:10,paddingRight:10, borderWidth: 0,padding:0,borderRadius: 0,height:28,alignSelf:"flex-start"}} />
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:2,left:5}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:21,left:5}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:2,left:30}}></View>
              <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:21,left:30}}></View>
              </View>
     
            <TextInput style={{ paddingLeft:5,height:32,alignSelf:"flex-start" ,flexGrow: 1 }} placeholder={"Paketnummer"} onChangeText={value => this.setState({ pickupParcelNumber: value })} value={this.state.pickupParcelNumber} />

            <Button disabled={this.state.pickupParcelNumber != "" ? false : true}  onPress={this._addnumber} icon={<Icon name="plus" size={12} color="white" style={{ }} />} buttonStyle={{ borderWidth: 0,padding:0,borderRadius: 0,width:30 ,height:30,alignSelf:"flex-end"}} />

          </View>

          <FlatList ItemSeparatorComponent={this.ListViewItemSeparator} 
          data={this.state.pickupParcelNumbers} 
          keyExtractor={item => item}
          style={{borderColor:'#ccc',borderWidth:1,height:200,borderTopWidth:0, marginBottom:30}} 
          renderItem={({ item, index, separators }) => (
              <View style={{flexDirection: 'row',backgroundColor: index % 2 == 0  ? "#f2f2f2" : "#FFFFFF"}}>

                <Text style={styles.ListEntry}>{item}</Text>
                <Button onPress={() => {this.removeNumber(index)}} color="#ffffff" icon={<Icon name="remove" size={10} color="#666666" style={{ }} />} buttonStyle={{ alignSelf:"flex-end",backgroundColor:'rgba(52, 52, 52, 0)',borderWidth: 0,padding:0,borderRadius: 0,width:50 ,height:35}} />
        
              
            </View>
            )}
          />

  

        </View>
        <View style={{ padding: 5, justifyContent: "flex-end" ,height:80}}>
          <Button onPress={this._nextstep} title={"Bestätigen"} disabled={this.state.pickupParcelNumbers.length > 0 ? false : true} buttonStyle={{ borderRadius: 0 }} />
        </View>
      </View>
    );
  }
  _addnumber = () => {
 
    var chk=false;
    this.state.pickupParcelNumbers.forEach(element => {
      if(element==this.state.pickupParcelNumber){
        chk=true;
      }
    });
    if(chk==false){

      var newentry=this.state.pickupParcelNumber;
      var newobj=[...this.state.pickupParcelNumbers,newentry];
      this.setState({pickupParcelNumbers: newobj});
      this.setState({ pickupParcelNumber: '' });
    }else{
      Alert.alert("Ups", "Diese Paketnummer ist bereits dabei!", [
        {
          text: "OK"
        }
      ]);
    }
  };
  removeNumber = (indx) =>{
    var tmp=this.state.pickupParcelNumbers;
    tmp.splice(indx, 1);
    this.setState({pickupParcelNumbers: tmp});
  }
  _nextstep = () => {

    Alert.alert(
      "Paket abgeholt",
      "Soll die Abholung des Pakets jetzt bestätigt werden?",
      [
        {
          text: "Nein",
          onPress: () => {}
        },
        {
          text: "Ja",
          onPress: () => this._pickup(this.state.jobid, this.state.parcelId,+this.state.returndlry)
        }
      ],
      { cancelable: true }
    );
  };
  _extractNumber = nr => {
    console.log(nr);
    if (nr.split("http://nd.dpd.de/?pknr=").length > 1) {
      return nr.split("http://nd.dpd.de/?pknr=")[1];
    } else if (nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=").length > 1) {
      return nr.split("https://gls-group.eu/DE/de/paketverfolgung?match=")[1];
    } else if (nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=").length > 1) {
      return nr.split("https://m.ups.com/InfoNotice/?loc=de_DE&trackingNumber=")[1];
    } else {
      return "";
    }
  };
  handleBarCodeScanned = async ({ type, data }) => {
    //Vibration.vibrate(100);
    if (this.isURL(data)) {
      this.setState({ pickupParcelNumber: this._extractNumber(data) });
    } else if (data.indexOf(" ") == -1) {
      // this.setState({ number: data });
      this.setState({ pickupParcelNumber: data });
    }
    this.setState({ scanned: true });
    this.setState({ doscan: false });

    //alert(`Code erkannt! Typ: ${type} mit folgenden Daten: ${data}`);
  };
  isURL = str => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };
  _startqr = async () => {
    if (this.state.hasCameraPermission === null) {
      //
      await this._get_cameramermission();
      if (this.state.hasCameraPermission === true) {
        this.setState({ doscan: true });
      } else {
        Alert.alert(
          "Hinweis",
          "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
          [
            {
              text: "OK",
              onPress: () => {
                this.setState({ loaderIsVisible: false });
                Linking.openURL("app-settings://notification/misterpostman");
              }
            }
          ],
          { cancelable: false }
        );
      }
    } else if (this.state.hasCameraPermission === false) {
      Alert.alert(
        "Hinweis",
        "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
        [
          {
            text: "OK",
            onPress: () => {
              this.setState({ loaderIsVisible: false });
              Linking.openURL("app-settings://notification/misterpostman");
            }
          }
        ],
        { cancelable: false }
      );
      return;
    } else {
      this.setState({ doscan: true });
    }
  };

  _pickup = (jobid, parcelid,returndlry) => {
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);

    
    
    var next_screen="MyDeliveryParcelDetailRetour";


    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/pickup.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: jobid,
        parcelID: parcelid,
        do_return: returndlry,
        parcelNumber: this.state.pickupParcelNumbers
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {

          var  okmess = "Super, das Paket ist abgeholt!";
          var alert_text="Du kannst es jetzt an das Versandunternehmen übergeben!";
          if (responseJson.code == "multi") {
            okmess = "Super, die Pakete wurden abgeholt!";
            alert_text="Du kannst sie jetzt an das Versandunternehmen übergeben!";
          }
          
          Alert.alert(
            okmess,
            alert_text,
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate(next_screen, {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "wrongcount") {
            errmess = "Bitte prüfe die Anzahl der Pakete.";
          }else if (responseJson.code == "notexist1") {
            errmess = "Das ist nicht die richtige Sendungsnummer.";
          }else if (responseJson.code == "notexist2") {
            errmess = "Das sind nicht die richtigen Sendungsnummer."; 
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt...",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,

    flex: 1,
    flexDirection:'column',
    marginBottom: 20
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  },
  ListEntry:{

    padding:10,
    alignSelf:"flex-start",
    flexGrow: 1
  }
});
