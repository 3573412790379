import React from "react";
import { Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View, TextInput, Alert, ActivityIndicator, KeyboardAvoidingView ,Keyboard,TouchableWithoutFeedback} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Input, Button, Text, Overlay, Icon, ButtonGroup } from "react-native-elements";
import HeaderImage from "../components/HeaderImage";
import { Header } from "react-navigation";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company:"",
      firstname: "",
      lastname: "",
      street: "",
      zip: "",
      city: "",
      username: "",
      password: "",
      loaderIsVisible: false,
      selectedIndex: 0,
      email: "",
      email2: "",
      password: "",
      password2: "",
      verifyStatus: true
    };
    this.updateIndex = this.updateIndex.bind(this);
  }
  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };
  updateIndex(selectedIndex) {
    this.setState({ selectedIndex });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.refresh == true) {
      this._getmydata();
    }
  }
  UNSAFE_componentWillMount() {
    this._getmydata();
  }
  _getmydata = () => {
    this.setState({ refreshing: true });

    fetch(apiUrl + "user/settings.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        show: true
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson["verified"] == "1" || responseJson["verified"] == "-1") {
          this.setState({ verifyStatus: true });
        } else {
          this.setState({ verifyStatus: false });
        }
      
        this.setState(
          {
            isLoading: false,
            refreshing: false,
            email: responseJson["email"],
            company: responseJson["company"],
            firstname: responseJson["firstname"],
            lastname: responseJson["lastname"],
            street: responseJson["street"],
            zip: responseJson["zip"],
            city: responseJson["city"]
          },
          function() {
            // In this block you can do something with new state.
          }
        );
      })
      .catch(error => {
        console.error(error);
      });

    //  this.setState({ dataSource: this.ds.cloneWithRows([]) });
  };
  _focusNextField(nextField) {
    this.refs[nextField].focus();
  }
  _validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  _checkAllInputsPassword = () => {
    if (this.state.password2 == this.state.password && this.state.password.length > 5) {
      return false;
    } else {
      return true;
    }
  };
  _checkAllInputsEmail = () => {
    const { email } = this.state;

    if (email.length) {
      return false;
    } else {
      return true;
    }
  };
  _checkAllInputsAddress = () => {
    const { firstname, lastname, street, zip, city } = this.state;

    if (firstname.length > 1 && lastname.length > 1 && street.length > 1 && zip.length > 1 && city.length > 1) {
      return false;
    } else {
      return true;
    }
  };
  _updatemail = async () => {
    const { email } = this.state;

    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "user/editemail.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        email: email
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {
          this.setState({ email2: "" });
          Alert.alert("Super!", "Deine Email-Adresse ist geändert.", [
            {
              text: "OK",
              onPress: () => this.setState({ loaderIsVisible: false })
            }
          ]);
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt!",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  _updateaddress = async () => {
    const { company,firstname, lastname, street, zip, city } = this.state;

    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "user/editaddress.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        company:company,
        firstname: firstname,
        lastname: lastname,
        street: street,
        zip: zip,
        city: city
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {
          Alert.alert("Super!", "Deine Adresse ist geändert.", [
            {
              text: "OK",
              onPress: () => this.setState({ loaderIsVisible: false })
            }
          ]);
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt!",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  _updatepassword = async () => {
    const { password } = this.state;

    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "user/editpassword.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        password: password
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {
          this.setState({ password: "", password2: "" });

          Alert.alert("Super!", "Dein Passwort ist geändert.", [
            {
              text: "OK",
              onPress: () => this.setState({ loaderIsVisible: false })
            }
          ]);
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt!",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  render() {
    const buttons = ["Email", "Adresse", "Passwort"];
    const { selectedIndex } = this.state;
    return (
      <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
      <KeyboardAvoidingView style={{ flex: 1 }}  behavior={Platform.OS == "ios" ? "padding" : "height"}>
      <ScrollView style={{ paddingTop: 10,paddingBottom:50 }}>
        <View style={styles.container}>
          <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
            </View>
          </Overlay>

          <View style={{ padding: 0, justifyContent: "flex-start" }}>
            <HeaderImage image="profile" />
            <ButtonGroup onPress={this.updateIndex} selectedIndex={selectedIndex} buttons={buttons} containerStyle={{ height: 30, marginTop: 20, marginBottom: 20 }} />
          </View>

          <View style={{ flex: 1, display: this.state.selectedIndex == 0 ? "flex" : "none" }}>
            <Input
              inputContainerStyle={{
                padding: 0,
                borderColor: "#ccc",
                borderWidth: 1,
                borderRadius: 0,
                marginBottom: 5,
                paddingLeft: 10
              }}
              onChangeText={email => this.setState({ email })}
              onBlur={() => {
                this.setState({ email: this.state.email.trim() });
              }}
              value={this.state.email}
              placeholder=""
              label="Email"
              labelStyle={{ marginTop: 5 }}
              autoComplete="off"
              autoCorrect={false}
              rightIconContainerStyle={{ paddingRight: 20 }}
              rightIcon={<Icon name={this.state.email.length < 1 ? "check" : this._validateEmail(this.state.email) ? "check" : "close"} size={24} color={this.state.email.length < 1 ? "transparent" : this._validateEmail(this.state.email) ? "green" : "red"} _validateEmail />}
            />

            <Input
              inputContainerStyle={{
                padding: 0,
                borderColor: "#ccc",
                borderWidth: 1,
                borderRadius: 0,
                marginBottom: 5,
                paddingLeft: 10
              }}
              onChangeText={email2 => this.setState({ email2 })}
              onBlur={() => {
                this.setState({ email2: this.state.email2.trim() });
              }}
              value={this.state.email2}
              placeholder=""
              label="Email wiederholen"
              labelStyle={{ marginTop: 5 }}
              autoComplete="off"
              autoCorrect={false}
              rightIconContainerStyle={{ paddingRight: 20 }}
              rightIcon={
                <Icon
                  name={this.state.email2.length > 1 && this.state.email.toLowerCase() == this.state.email2.toLowerCase() && this._validateEmail(this.state.email2) ? "check" : this.state.email2.length > 1 ? "close" : "check"}
                  size={24}
                  color={this.state.email2.length > 1 && this.state.email.toLowerCase() == this.state.email2.toLowerCase() && this._validateEmail(this.state.email2) ? "green" : this.state.email2.length > 1 ? "red" : "transparent"}
                />
              }
            />
            <Button
              onPress={this._updatemail}
              title={"Email-Adresse ändern"}
              buttonStyle={{ borderRadius: 0, width: 250 }}
              containerStyle={{
                width: 250,
                alignSelf: "center",
                marginTop: 30,
                marginBottom: 100
              }}
              disabled={this._checkAllInputsEmail()}
              accessibilityLabel="Email-Adresse ändern"
            />
          </View>
          <View style={{ flex: 1, display: this.state.selectedIndex == 2 ? "flex" : "none" }}>
            <Input
              inputContainerStyle={{
                padding: 0,
                borderColor: "#ccc",
                borderWidth: 1,
                borderRadius: 0,
                marginBottom: 5,
                paddingLeft: 10
              }}
              onChangeText={password => this.setState({ password })}
              value={this.state.password}
              label="Passwort"
              labelStyle={{ marginTop: 5 }}
              secureTextEntry
              rightIconContainerStyle={{ paddingRight: 10 }}
              rightIcon={<Icon name={"check"} size={24} color={this.state.password.length < 1 ? "transparent" : this.state.password.length > 5 ? "green" : "red"} />}
            />
            <Input
              inputContainerStyle={{
                padding: 0,
                borderColor: "#ccc",
                borderWidth: 1,
                borderRadius: 0,
                marginBottom: 5,
                paddingLeft: 10
              }}
              onChangeText={password2 => this.setState({ password2 })}
              value={this.state.password2}
              label="Passwort wiederholen"
              labelStyle={{ marginTop: 5 }}
              secureTextEntry
              rightIconContainerStyle={{ paddingRight: 10 }}
              rightIcon={<Icon name={this.state.password2 == this.state.password ? "check" : "close"} size={24} color={this.state.password2.length < 1 ? "transparent" : this.state.password2 == this.state.password ? "green" : "red"} />}
            />
            <Button
              onPress={this._updatepassword}
              title={"Passwort ändern"}
              buttonStyle={{ borderRadius: 0, width: 250 }}
              containerStyle={{
                width: 250,
                alignSelf: "center",
                marginTop: 30,
                marginBottom: 100
              }}
              disabled={this._checkAllInputsPassword()}
              accessibilityLabel="Email-Adresse ändern"
            />
          </View>
          <View style={{ flex: 1, display: this.state.selectedIndex == 1 ? "flex" : "none" }}>

          <Input
                inputContainerStyle={{
                  padding: 0,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 0,
                  marginBottom: 5,
                  paddingLeft: 10
                }}
                onChangeText={company => this.setState({ company })}
                value={this.state.company}
                placeholder=""
                label="Firma:"
                labelStyle={{ marginTop: 5 }}
                autoComplete="off"
                autoCorrect={false}

                returnKeyType="next"
                disabled={this.state.verifyStatus}
                onSubmitEditing={() => this._focusNextField("2")}
                ref="1"
              />
            
              <Input
                inputContainerStyle={{
                  padding: 0,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 0,
                  marginBottom: 5,
                  paddingLeft: 10
                }}
                onChangeText={firstname => this.setState({ firstname })}
                value={this.state.firstname}
                placeholder=""
                label="Vorname:"
                labelStyle={{ marginTop: 5 }}
                autoComplete="off"
                autoCorrect={false}
                rightIconContainerStyle={{ paddingRight: 20 }}
                rightIcon={<Icon name="check" size={24} color={this.state.firstname.length > 1 ? "green" : "transparent"} />}
                returnKeyType="next"
                disabled={this.state.verifyStatus}
                onSubmitEditing={() => this._focusNextField("3")}
                ref="2"
              />

              <Input
                inputContainerStyle={{
                  padding: 0,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 0,
                  marginBottom: 5,
                  paddingLeft: 10
                }}
                onChangeText={lastname => this.setState({ lastname })}
                value={this.state.lastname}
                placeholder=""
                label="Nachname:"
                labelStyle={{ marginTop: 5 }}
                autoComplete="off"
                autoCorrect={false}
                rightIconContainerStyle={{ paddingRight: 20 }}
                rightIcon={<Icon name="check" size={24} color={this.state.lastname.length > 1 ? "green" : "transparent"} />}
                returnKeyType="next"
                disabled={this.state.verifyStatus}
                onSubmitEditing={() => this._focusNextField("4")}
                ref="3"
              />

              <Input
                inputContainerStyle={{
                  padding: 0,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 0,
                  marginBottom: 5,
                  paddingLeft: 10
                }}
                onChangeText={street => this.setState({ street })}
                value={this.state.street}
                placeholder=""
                label="Strasse Nr.:"
                labelStyle={{ marginTop: 5 }}
                autoComplete="off"
                autoCorrect={false}
                rightIconContainerStyle={{ paddingRight: 20 }}
                rightIcon={<Icon name="check" size={24} color={this.state.street.length > 1 ? "green" : "transparent"} />}
                returnKeyType="next"
                onSubmitEditing={() => this._focusNextField("5")}
                ref="4"
              />

              <Input
                inputContainerStyle={{
                  padding: 0,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 0,
                  marginBottom: 5,
                  paddingLeft: 10
                }}
                onChangeText={zip => this.setState({ zip })}
                value={this.state.zip}
                placeholder=""
                label="PLZ"
                labelStyle={{ marginTop: 5 }}
                autoComplete="off"
                autoCorrect={false}
                rightIconContainerStyle={{ paddingRight: 20 }}
                rightIcon={<Icon name="check" size={24} color={this.state.zip.length > 1 ? "green" : "transparent"} />}
                returnKeyType="next"
                onSubmitEditing={() => this._focusNextField("6")}
                ref="5"
              />

              <Input
                inputContainerStyle={{
                  padding: 0,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderRadius: 0,
                  marginBottom: 5,
                  paddingLeft: 10
                }}
                onChangeText={city => this.setState({ city })}
                value={this.state.city}
                placeholder=""
                label="Ort"
                labelStyle={{ marginTop: 5 }}
                autoComplete="off"
                autoCorrect={false}
                rightIconContainerStyle={{ paddingRight: 20 }}
                rightIcon={<Icon name="check" size={24} color={this.state.city.length > 1 ? "green" : "transparent"} />}
                returnKeyType="done"
                ref="6"
              />
              <Button
                onPress={this._updateaddress}
                title={"Adresse ändern"}
                buttonStyle={{ borderRadius: 0, width: 250 }}
                containerStyle={{
                  width: 250,
                  alignSelf: "center",
                  marginTop: 30,
                  marginBottom: 100
                }}
                disabled={this._checkAllInputsAddress()}
                accessibilityLabel="Email-Adresse ändern"
              />
            
          </View>
        </View>
        </ScrollView>
      </KeyboardAvoidingView>
      </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,

    backgroundColor: "#fff",
    paddingTop: 20
  }
});
