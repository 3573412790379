import React from "react";
import { Image, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View, TextInput, Alert, ActivityIndicator, Dimensions, FlatList, RefreshControl } from "react-native";
import Constants from "expo-constants";
//import * as Permissions from "expo-permissions";
//import * as Location from "expo-location";
import MapView from "react-native-maps";
import { Button } from "react-native-elements";
import Row from "./DeliveryParcelRow";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

const markerimg = require("../../assets/images/icons/marker-empty.png");
const markerimg_return = require("../../assets/images/icons/marker-retoure-empty.png");
const markerimg_courier= require("../../assets/images/icons/marker-courier-empty.png");
const markerimg_courier2= require("../../assets/images/icons/marker-courier2-empty.png");

const dimensions = Dimensions.get("window");
const screenHeight = dimensions.height - 280;
const screenWidth = dimensions.width;

export default class DeliveryMapScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      refreshing: true,
      // location: null,
      location: {
        coords: {
          latitude: 51.45756,
          longitude: 6.856997
        }
      },
      errorMessage: null,
      refreshavailable: true,
      data: [],
      latitude: 51.45756,
      longitude: 6.856997,
      latitudeDelta: 0.5,
      longitudeDelta: 0.5,
      old_latitude: 0,
      old_longitude: 0,
      old_latitudeDelta: 0,
      old_longitudeDelta: 0,
      zoomLevel: 10,
      initload: false,
      markerArray: [],
      markers: [
        {
          index: 1,
          coordinates: {
            latitude: 3.148561,
            longitude: 101.652778
          }
        },
        {
          index: 2,
          coordinates: {
            latitude: 3.149771,
            longitude: 101.655449
          }
        }
      ]
    };
  }
  /*_manualcheck = async () => {
    console.log("_manualcheck");
    let testvar = await Location.hasServicesEnabledAsync();
    console.log(testvar);
  };
  _manualcheck2 = async () => {
    console.log("_manualcheck2");
    let { status } = await Permissions.askAsync(Permissions.LOCATION);
    console.log(status);
  };
  _manualcheck3 = async () => {
    console.log("_manualcheck3");
    let location = await Location.getCurrentPositionAsync({ enableHighAccuracy: true });
    console.log(location);
  };
  _manualcheck4 = async () => {
    console.log("_manualcheck4");
    let stat = await Location.getProviderStatusAsync();
    console.log(stat);
  };
  */
 UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.refresh == true) {
      //this._refreshListView();
    }
  }

  UNSAFE_componentWillMount() {
    console.log("componentWillMount");
    if (Platform.OS === "android" && !Constants.isDevice) {
      this.setState({
        errorMessage: "Oops, this will not work on Sketch in an Android emulator. Try it on your device!"
      });
      console.log("Oops, this will not work on Sketch in an Android emulator. Try it on your device!");
    } else {
      console.log("this._getLocationAsync()");
      // this._getLocationAsync();
    }
    this._refreshListView();
  }

  _getLocationAsync = async () => {
   /* console.log("_getLocationAsync...");
    let { status } = await Permissions.askAsync(Permissions.LOCATION);
    if (status !== "granted") {
      this.setState({
        errorMessage: "Zugriff auf Position wurde nicht gewährt."
      });
      console.log("Zugriff auf Position wurde nicht gewährt.");
    }

    let gpsServiceStatus = await Location.getProviderStatusAsync();
    if (gpsServiceStatus.locationServicesEnabled) {
      console.log("ok");
    }

    console.log("location wird gezogen");
    // IntentLauncherAndroid.startActivityAsync(IntentLauncherAndroid.ACTION_LOCATION_SOURCE_SETTINGS);
    //let location = await Location.getCurrentPositionAsync({ enableHighAccuracy: true });
    let location = await Location.getCurrentPositionAsync();
    console.log("location ist gezogen");
    console.log(location);

    this.setState({ location });

    this._refreshListView();*/
  };

  _refreshListView = (force = false) => {
    console.log("load data try");
    if (this.state.refreshavailable || force == true) {
      console.log("load data");

      console.log("latitude:" + this.state.latitude);
      console.log("longitude:" + this.state.longitude);
      console.log("latitudeDelta:" + this.state.latitudeDelta);
      console.log("longitudeDelta:" + this.state.longitudeDelta);

      this.setState({ refreshing: true, refreshavailable: false });

      fetch(apiUrl + "parcel/findparcels.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userID: global.userID,
          loginKey: global.loginKey,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          latitudeDelta: this.state.latitudeDelta,
          longitudeDelta: this.state.longitudeDelta,
          show_return:true,
          show_courier:true,
        })
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("received data");
          this.setState(
            {
              isLoading: false,
              isLoading2: false,
              refreshing: false,
              markerArray: [],
              data: responseJson.results
            },
            function() {
              // In this block you can do something with new state.
            }
          );
          this._buildMarker(responseJson.results);
        })
        .catch(error => {
          console.error(error);
        });
    }
    //  this.setState({ dataSource: this.ds.cloneWithRows([]) });
  };
  roundNumber = (num, scale) => {
    if (!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = "";
      if (+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
  };
  _buildMarker = responseJson => {
    var markerarr = Array();
    var markerarrPlaceID = Array();
    var zoomArray = Array(0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 3, 4, 5, 6, 7);
    responseJson.forEach(element => {
      //group by lng-lat

      /*
       calczoom = zoomArray[this.state.zoomLevel];
      if (this.state.zoomLevel <= 13) {
        var roundLng = this.roundNumber(element.location_lng, calczoom);
        var roundLat = this.roundNumber(element.location_lat, calczoom);
        var LngLatStr = roundLng.toString() + "-" + roundLat.toString();

        console.log("länge arr markerarr.length " + markerarr.length);
        console.log(markerarrPlaceID.indexOf(LngLatStr));
        if (markerarrPlaceID.indexOf(LngLatStr) != -1) {
          markerarr[markerarrPlaceID.indexOf(LngLatStr)].count++;
          console.log("found " + LngLatStr);
        } else {
          markerarr.push({
            index: element.id,
            count: 1,
            coordinates: {
              latitude: parseFloat(element.location_lat),
              longitude: parseFloat(element.location_lng)
            }
          });

          markerarrPlaceID[markerarr.length - 1] = LngLatStr;
        }


       
      } else {
        */
      //group palceid
      if (markerarrPlaceID[element.location_placeid] != undefined && element.location_placeid != "") {
        markerarr[markerarrPlaceID[element.location_placeid]].count++;
      } else {
        markerarr.push({
          index: element.id,
          count: 1,
          service: element.service,
          service2: element.service2,
          coordinates: {
            latitude: parseFloat(element.location_lat),
            longitude: parseFloat(element.location_lng)
          },
          data: element
        });
        markerarrPlaceID[element.location_placeid] = markerarr.length - 1;
      }
      //}
    });

    this.setState({ markers: markerarr });
  };
  ListViewItemSeparator = () => {
    return (
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: "#000"
        }}
      />
    );
  };

  render() {
    let text = "Warte...";
    if (this.state.errorMessage) {
      text = this.state.errorMessage;
    } else if (this.state.location) {
      text = JSON.stringify(this.state.location);
    }

    return this.state.isLoading == false ? ( //this.state.location &&
      <View style={styles.container}>
        <Text style={[styles.paragraph, { display: "none" }]}>{text}</Text>

        <ScrollView style={[styles.fixed, { backgroundColor: "transparent" }]} stickyHeaderIndices={[0]}>
          <View>
            <Text style={{ position: "absolute", top: 40, zIndex: 10000, opacity: 0.2 }}>{this.state.zoomLevel}</Text>
            <Button
              onPress={() => this._refreshListView()}
              title={"Hier neu suchen"}
              iconRight
              icon={<Icon name="search" size={20} color="white" style={{ paddingLeft: 10 }} />}
              buttonStyle={{
                position: "absolute",
                zIndex: 1111,
                top: 20,
                left: 10,
                opacity: 0.5,
                display: "none"
              }}
              disabled={!this.state.refreshavailable}
              buttonStyle={{
                borderRadius: 0,
                opacity: this.state.refreshavailable ? 1 : 0.5
              }}
            />
            <MapView
              initialRegion={{
                latitude: this.state.location.coords.latitude,
                longitude: this.state.location.coords.longitude,
                latitudeDelta: this.state.latitudeDelta,
                longitudeDelta: this.state.longitudeDelta
              }}
              style={{ flex: 1, height: screenHeight }}
              // onPress={() => alert('hello')}
              mapType="standard"
              onRegionChangeComplete={async region => {
                var newzoom = Math.round(Math.log(360 / region.latitudeDelta) / Math.LN2);
                await this.setState({
                  old_latitude: this.state.latitude,
                  old_longitude: this.state.longitude,
                  old_latitudeDelta: this.state.latitudeDelta,
                  old_longitudeDelta: this.state.longitudeDelta
                });

                await this.setState({
                  latitude: region.latitude,
                  longitude: region.longitude,
                  latitudeDelta: region.latitudeDelta,
                  longitudeDelta: region.longitudeDelta
                });

                if (this.state.latitude != this.state.old_latitude || this.state.longitude != this.state.old_longitude || this.state.latitudeDelta != this.state.old_latitudeDelta || this.state.longitudeDelta != this.state.old_longitudeDelta) {
                  this.setState({
                    refreshavailable: true
                  });
                }
                if (this.state.initload == false) {
                  console.log("initload");

                  this.setState({
                    initload: true,
                    refreshavailable: true
                  });
                  this._refreshListView(true);
                }
                if (newzoom != this.state.zoomLevel) {
                  await this.setState({
                    zoomLevel: newzoom
                  });
                  /* cluster view this._buildMarker(this.state.data);*/
                }
              }}
              showsCompass={true}
              showsBuildings={true}
              showsTraffic={true}
              showsIndoors={true}
            >
              {this.state.markers.map(marker => {
                //console.log(marker);

                //console.log(marker);

                return (
                  <MapView.Marker key={marker.index} coordinate={marker.coordinates} title={marker.title} onPress={e => this.onPressMarker(e, marker.index, marker.data)}>
                    <Image source={marker.service == "return" ? markerimg_return:  marker.service == "delivery" ? markerimg:  marker.service2== "bring" ? markerimg_courier:  markerimg_courier2} style={{ height: 61, width: 38 }} />
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: "bold",
                        position: "absolute",
                        left: 2,
                        top: 8,
                        width: 34
                      }}
                    >
                      {marker.count}
                    </Text>
                  </MapView.Marker>
                );
              })}
            </MapView>
          </View>

          <View
            style={{
              backgroundColor: "white",
              borderTopColor: "#d1d5da",
              borderTopWidth: 1,
              zIndex: 11,
              marginBottom: -100
            }}
          >
            <View
              style={{
                height: 2,
                backgroundColor: "#d1d5da",
                marginTop: 10,
                marginBottom: 10,
                width: 50,
                alignSelf: "center"
              }}
            />

            <FlatList enableEmptySections={true} ItemSeparatorComponent={this.ListViewItemSeparator} data={this.state.data} style={styles.container} renderItem={({ item }) => <Row {...item} rowData={item} func={this.handleClick} nav={this.props.navigation} />} refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={() => this._refreshListView()} />} />

            <View style={{ height: 100, backgroundColor: "#white" }} />
          </View>
        </ScrollView>
      </View>
    ) : (
      <View style={{ flex: 1, paddingTop: 20 }}>
        <ActivityIndicator />
      </View>
    );
  }
  onPressMarker = (e, indx, data) => {
    /*this.props.navigation.navigate("DeliveryParcelDetail", {
      itemdata: data
    });*/
  };
}

const styles = StyleSheet.create({
  calloutText: {},
  customView: {},
  scrollpadding: {
    height: screenHeight
  },
  fixed: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    flex: 1,
    height: "100%"
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    position: "relative"
  },
  developmentModeText: {
    marginBottom: 20,
    color: "rgba(0,0,0,0.4)",
    fontSize: 14,
    lineHeight: 19,
    textAlign: "center"
  },
  contentContainer: {
    paddingTop: 30
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: "contain",
    marginTop: 3,
    marginLeft: -10
  },
  getStartedContainer: {
    alignItems: "center",
    marginHorizontal: 50
  },
  homeScreenFilename: {
    marginVertical: 7
  },
  codeHighlightText: {
    color: "rgba(96,100,109, 0.8)"
  },
  codeHighlightContainer: {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 3,
    paddingHorizontal: 4
  },
  getStartedText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    lineHeight: 24,
    textAlign: "center"
  },
  tabBarInfoContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: "black",
        shadowOffset: { height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3
      },
      android: {
        elevation: 20
      }
    }),
    alignItems: "center",
    backgroundColor: "#fbfbfb",
    paddingVertical: 20
  },
  tabBarInfoText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    textAlign: "center"
  },
  navigationFilename: {
    marginTop: 5
  },
  helpContainer: {
    marginTop: 15,
    alignItems: "center"
  },
  helpLink: {
    paddingVertical: 15
  },
  helpLinkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
});
