import React from "react";
import { Image, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View, TextInput, Alert, FlatList, ActivityIndicator, RefreshControl } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import Row from "./MyParcelsRow";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

import HeaderImage from "../components/HeaderImage";

export default class MyParcelsScreen extends React.Component {
  /*static navigationOptions = {
    title: "Empfangen"
  };*/
  // static navigationOptions = { title: "Termos e Condições", headerStyle: { backgroundColor: "piurple" }, headerTintColor: "white", headerTitleStyle: { fontSize: 16, fontWeight: "normal", justifyContent: "center", alignSelf: "center", width: "65%" } };
  constructor(props) {
    super(props);

    this.state = {
      text: "Useless Placeholder",
      username: "",
      password: "",
      isLoading: true,
      isLoading2: true,
      refreshing: true,
      data: []
    };
    //
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.refresh == true) {
      // this._refreshListView();
    }
  }
  componentDidMount() {
    this.willFocusSubscription = this.props.navigation.addListener("willFocus", () => {
      this._refreshListView();
    });
  }
  componentWillUnmount() {
    this.willFocusSubscription.remove();
  }
  UNSAFE_componentWillMount() {
    // this._refreshListView();
  }

  _refreshListView = () => {
    this.setState({ refreshing: true });

    fetch(apiUrl + "parcel/owner/listparcels.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.exit !== "undefined") {
          await AsyncStorage.clear();
          this.props.navigation.navigate("Auth");
        } else {
          this.setState(
            {
              isLoading: false,
              isLoading2: false,
              refreshing: false,
              data: responseJson.results
            },
            function() {
              // In this block you can do something with new state.
            }
          );
          AsyncStorage.setItem("isPostman", responseJson.header.verified);
          global.isPostman = responseJson.header.verified;
        }
      })
      .catch(error => {
        console.error(error);
      });

    //  this.setState({ dataSource: this.ds.cloneWithRows([]) });
  };
  ListViewItemSeparator = () => {
    return (
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: "#000"
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1, paddingTop: 20 }}>
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <MyContext.Consumer>
        {context => (
          <View style={styles.container}>
            <HeaderImage image="empfangen" />

            <View style={styles.welcomeContainer}>
              <Button onPress={() => this._addparcel(context)} title={"Paket eintragen"} raised type="outline"  buttonStyle={{ borderRadius: 0, width: 250 }} />
            </View>
            <FlatList ItemSeparatorComponent={this.ListViewItemSeparator} data={this.state.data} style={styles.container} renderItem={({ item }) => <Row {...item} rowData={item} func={this.handleClick} nav={this.props.navigation} />} refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={() => this._refreshListView()} />} />
          </View>
        )}
      </MyContext.Consumer>
    );
  }
  handleClick(itemid) {
    alert(itemid);
    this.props.navigation.navigate("MyParcel");
  }
  _addparcel = context => {
    //alert("Neues Paket");
    if (context.state.parcelNumber != "") {
      Alert.alert(
        "",
        "Es wurden bereits Daten zu einem Paket eingegeben. Möchtest Du diese wieder aufnehmen? ",
        [
          {
            text: "Nein",
            onPress: () => {
              context.handleReset();
              this.props.navigation.navigate("AddParcelService");
            }
          },
          {
            text: "Ja",
            onPress: () => this.props.navigation.navigate("AddParcelService")
          }
        ],
        { cancelable: true }
      );
    } else {
      this.props.navigation.navigate("AddParcelService");
    }
  };
}

const styles = StyleSheet.create({
  listbox: {},

  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  developmentModeText: {
    marginBottom: 20,
    color: "rgba(0,0,0,0.4)",
    fontSize: 14,
    lineHeight: 19,
    textAlign: "center"
  },
  contentContainer: {
    paddingTop: 0
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: "contain",
    marginTop: 3,
    marginLeft: -10
  },
  getStartedContainer: {
    alignItems: "center",
    marginHorizontal: 50
  },
  homeScreenFilename: {
    marginVertical: 7
  },
  codeHighlightText: {
    color: "rgba(96,100,109, 0.8)"
  },
  codeHighlightContainer: {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 3,
    paddingHorizontal: 4
  },
  getStartedText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    lineHeight: 24,
    textAlign: "center"
  },
  tabBarInfoContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: "black",
        shadowOffset: { height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3
      },
      android: {
        elevation: 20
      }
    }),
    alignItems: "center",
    backgroundColor: "#fbfbfb",
    paddingVertical: 20
  },
  tabBarInfoText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    textAlign: "center"
  },
  navigationFilename: {
    marginTop: 5
  },
  helpContainer: {
    marginTop: 15,
    alignItems: "center"
  },
  helpLink: {
    paddingVertical: 15
  },
  helpLinkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
});
