import React from "react";
import { View, Text, StyleSheet, Image, TouchableHighlight, TouchableOpacity, TouchableNativeFeedback, Platform } from "react-native";
import LocationType from "../helper/LocationType";
import ProviderLogo from "../helper/ProviderLogo";
import Icon from "react-native-vector-icons/FontAwesome";
import Collapsible from "react-native-collapsible";
import { Rating } from "react-native-elements";
import AddParcelLocationRetourScreen from "../addParcel/AddParcelLocationRetourScreen";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 7,
    flexDirection: "row",
    alignItems: "center"
  },
  innercontainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    paddingRight: 10
  },
  text: {
    marginLeft: 12,
    fontSize: 15,
    flex: 0.85,
    flexWrap: "wrap"
  },
  photo: {
    height: 40,
    width: 40,
    borderRadius: 20
  },
  entryrow: {
    backgroundColor: "#99cc00",
    flexDirection: "row",
    flex: -1
  },
  shippingicon: {
    height: 61,
    width: 85.33
  }
});

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {},
      collapse: true
    };
  }
  render() {
    const { service2,service,provider, location_type, location_street, location_no, location_additional, location_zip, location_city, dest_street,dest_no, dest_additional, dest_zip,dest_city,parcel_id, distanceText, durationText, costs, currency, jobid, pickup, delivery, expired, rating, reject } = this.props;
    const TouchableItem = Platform.OS === "ios" ? TouchableOpacity : TouchableNativeFeedback;

    return (
      <View style={[styles.container, { opacity: expired == 1 ? 0.75 : 1, backgroundColor: expired == 1 ? "#fc3535" : "#ffffff" }]}>
        <TouchableItem onPress={()=>this._selectrow(service,service2)} style={styles.innercontainer}>
          <View style={styles.innercontainer}>
            <View style={{ flexDirection: "column" }}>
              <ProviderLogo provider={provider} size={"medium"} />
              <View style={{ flexDirection: "row", paddingLeft: 5 }}>
                <Icon name="check" color={jobid != undefined ? "#0ad000" : "#efefef"} size={18} />
                <Icon name="check" color={pickup == "1" ? "#0ad000" : "#efefef"} size={18} style={{ display: reject != "1" ? "flex" : "none" }} />
                <Icon name="warning" color={reject == "1" ? "#ff0000" : "#efefef"} size={18} style={{ display: reject == "1" ? "flex" : "none" }} />
                <Icon name="check" color={delivery == "1" ? "#0ad000" : "#efefef"} size={18} />
              </View>

              <View style={{ display: rating == "0" ? "none" : "flex" }}>
                <Rating type="rocket" imageSize={10} readonly startingValue={parseInt(rating)} ratingCount={5} type="custom" />
              </View>
            </View>

            <View style={{ flexDirection: "column", flex: 1 }}>
              <View style={{ flexDirection: "row", flex: 1, paddingTop: 4 }}>
                <Text style={[styles.text,{display:service=="courier"?"none":"flex"}]}>
                  <LocationType value={location_type} /> 
                  <Text style={{color:'#1e90ff'}}> {parcel_id.replace(',', ', ')}</Text>
                  {`: ${location_street} ${location_no}, ${location_additional!= '' ? location_additional+', ' :''}${location_zip} ${location_city}`}
                </Text>
                <Text style={[styles.text,{display:service=="courier"?"flex":"none"}]}> 
                  <Text style={{color:'#1e90ff'}}>{parcel_id.replace(',', ', ')}</Text>
                  {` abholen von ${location_street} ${location_no}, ${location_additional!= '' ? location_additional+', ' :''}${location_zip} ${location_city}`}
                </Text>

              </View>
              <View style={{ flexDirection: "row", flex: 1, paddingTop: 4 }}>
                <Text style={[styles.text,{display:service=="courier"?"flex":"none"}]}> 
                  {`liefern an ${dest_street} ${dest_no}, ${dest_additional!= '' ? dest_additional+', ' :''}${dest_zip} ${dest_city}`}
                </Text>
              </View>
     
              <Collapsible collapsed={this.state.collapse} style={{ width: "100%", paddingTop: 15 }}>
                
                <View style={{display:service == "return"?"none":"flex"}}>
                <Text style={styles.text}>Liefer-Km: {distanceText}</Text>
                <Text style={styles.text}>Liefer-Zeit: {durationText}</Text></View>
                <Text style={styles.text}>
                  Kosten: {costs} {currency}
                </Text>
              </Collapsible>
            </View>
          </View>
        </TouchableItem>

        <View style={{textAlign:"center",alignSelf: "flex-start",position:'absolute',right:9,top:12,backgroundColor:service == "return" ? "#1e90ff" : service == "delivery" ? "#fec600" : service2 =="bring" ? "#99cc00":"#f86c05",borderRadius:5,padding:2}}>
          <Text style={[styles.text],{marginRight:2,fontSize:10,color:service == "return" ? "white" :"black"}}> {service == "return" ? "Abholung" : service == "delivery" ? "Lieferung" :service2 =="bring" ? "Kurier (L)":"Kurier (R)"}</Text>
        </View>

        <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 20, paddingBottom: 0, alignSelf: "flex-end" }} >
          <TouchableItem onPress={this._collapseDetails}>
            <Icon name={this.state.collapse ? "angle-double-down" : "angle-double-up"} size={24} color={expired == 1 ? "white" : "grey"} />
          </TouchableItem>
        </View>

      </View>
    );
  }
  _collapseDetails = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  _selectrow = (ser,ser2) => {
    if(ser == "delivery"){
      this.props.nav.navigate("MyParcel", { itemdata: this.props.rowData });
    }else if(ser == "courier"){

      if(ser2=="bring"){
        this.props.nav.navigate("MyCourierBring", { itemdata: this.props.rowData });
      }else{
        this.props.nav.navigate("MyCourierPickup", { itemdata: this.props.rowData });
      }
      
      
    }else{
      this.props.nav.navigate("MyParcelRetour", { itemdata: this.props.rowData });
      
    }
   
    // this.props.func("wewew");
  };
  _rdicon = () => {
    var min = 0;
    var max = 7;
    var RandomNumber = Math.floor(Math.random() * (max - min)) + min;
    var ico = this.state.compIcons[RandomNumber];
    return { rd: RandomNumber, ico: ico };
  };
}
