import React from "react";
import { ScrollView, StyleSheet, View, Alert } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Text, Image, Overlay } from "react-native-elements";
import ProviderName from "../helper/ProviderName";
import ProviderLogo from "../helper/ProviderLogo";
import LocationType from "../helper/LocationType";
import HeaderImage from "../components/HeaderImage";
import { TouchableItem } from "../../components/TouchableItem";
import { formatDate, stringFormatDate } from "../helper/DateFormat";
import MapView from "react-native-maps";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

var self;
export default class DeliveryParcelDetail extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      id: "",
      parcelId: "1",
      loaderIsVisible: false
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Sendungsdetails`,
      headerBackTitle: `zurück`
    };
  };
  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: this.refreshHandler,
      parcelId: tmp["id"]
    });
    console.log(tmp);
  }
  refreshHandler(id) {
    self.props.navigation.navigate("MyParcelEdit", { itemID: id });
    //self.refs[WEBVIEW_REF].reload()
  }

  _editParcel = () => {};
  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, provider, location_lat, location_lng, location_type, location_street, location_no, location_zip, location_city, time_from, time_to, time2, time_from2, time_to2, distanceText, durationText, profit, currency } = this.props;

    const coords = {
      latitude: parseFloat(itemdata["location_lat"]),
      longitude: parseFloat(itemdata["location_lng"])
    };
    //const itemId = this.props.navigation.state.params.itemId;
    // const itemId = "test";
    return (
      <ScrollView>
        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>
        <View style={styles.container}>
          <HeaderImage image="empfangen" />
          <View style={styles.entryRow}>
            <Text h4Style={styles.entryRowSubHeadline} h4>
              Sendungsdetails
            </Text>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Km zum Empfänger</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{itemdata["distanceText"]}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Fahrtzeit zum Empfänger ca.</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{itemdata["durationText"]}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Verdienst</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["profit"]} {itemdata["currency"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Versandunternehmen</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text>
                <ProviderName provider={itemdata["provider"]} />
              </Text>
              <ProviderLogo provider={itemdata["provider"]} />
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Gewicht</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{itemdata["weight"]} Kg</Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Standort</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                <LocationType value={itemdata["location_type"]} />
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_additional"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Strasse, Nr.</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_street"]} {itemdata["location_no"]}
              </Text>
            </View>
          </View>



          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>PLZ, Ort</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_zip"]} {itemdata["location_city"]}
              </Text>
            </View>
          </View>

          <View pointerEvents="none">
            <MapView
              initialRegion={{
                latitude: parseFloat(itemdata["location_lat"]),
                longitude: parseFloat(itemdata["location_lng"]),
                latitudeDelta: 0.01,
                longitudeDelta: 0.01
              }}
              style={{ flex: 1, height: 100 }}
              // onPress={() => alert('hello')}
              mapType="standard"
              showsCompass={true}
              showsBuildings={true}
              showsTraffic={true}
              showsIndoors={true}
            >
              <MapView.Marker coordinate={coords} />
            </MapView>
          </View>

          <View style={styles.entryRow}>
            <Text h5Style={styles.entryRowSubHeadline} h5>
              Abholung
            </Text>
          </View>
          <View style={[styles.entryRow, { display: itemdata["time_from_pickup"] != "0000-00-00 00:00:00" ? "flex" : "none" }]}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>von</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_from_pickup"])}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>bis</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_to_pickup"])}</Text>
            </View>
          </View>
          <View style={[styles.entryRow, { display: itemdata["dest_street"] != "" ? "flex" : "none" }]}>
            <Text h4Style={styles.entryRowSubHeadline} h4>
              Lieferadresse
            </Text>
          </View>

          <View style={[styles.entryRow, { display: itemdata["dest_street"] != "" ? "flex" : "none" }]}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_additional"]}
              </Text>
            </View>
          </View>
          
          <View style={[styles.entryRow, { display: itemdata["dest_street"] != "" ? "flex" : "none" }]}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Strasse, Nr.</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_street"]} {itemdata["dest_no"]}
              </Text>
            </View>
          </View>



          <View style={[styles.entryRow, { display: itemdata["dest_street"] != "" ? "flex" : "none" }]}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>PLZ Ort</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_zip"]} {itemdata["dest_city"]}
              </Text>
            </View>
          </View>



          <View style={styles.entryRow}>
            <Text h5Style={styles.entryRowSubHeadline} h5>
              Wunschtermin
            </Text>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>von</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_from"])}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>bis</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_to"])}</Text>
            </View>
          </View>

          <View>
            <View style={styles.entryRow}>
              <Text h5Style={styles.entryRowSubHeadline} h5>
                Ausweichtermin
              </Text>
            </View>
            <View style={styles.entryRow}>
              <View style={styles.entryRowTitle}>
                <Text style={styles.entryRowTitleText}>von</Text>
              </View>
              <View style={styles.entryRowDetail}>
                <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_from2"])}</Text>
              </View>
            </View>
            <View style={styles.entryRow}>
              <View style={styles.entryRowTitle}>
                <Text style={styles.entryRowTitleText}>bis</Text>
              </View>
              <View style={styles.entryRowDetail}>
                <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_to2"])}</Text>
              </View>
            </View>
          </View>

          <View style={{}}>
            <Button onPress={() => this._checkIn(itemdata["id"])} title={"Auftrag annehmen"} buttonStyle={{ marginTop: 30, marginBottom: 20, borderRadius: 0 }} />
          </View>
        </View>
      </ScrollView>
    );
  }
  _checkIn = parcelid => {
    Alert.alert(
      "Auftrag annehmen",
      "Bist Du Dir sicher, dass Du den Auftrag annehmen möchtest?",
      [
        {
          text: "Ja",
          onPress: () => this._dojob(parcelid)
        },
        {
          text: "Nein"
        }
      ],
      { cancelable: true }
    );
  };

  _dojob = parcelid => {
    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/dojob.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        parcelID: parcelid
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        console.log(responseJson);
        if (typeof responseJson.success !== "undefined") {
          Alert.alert(
            "Super!",
            "Du hast den Auftrag angenommen.",
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate("DeliveryOverview", {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );

          AsyncStorage.setItem("isPostman", responseJson.header.verified);
          global.isPostman = responseJson.header.verified;
        } else if (typeof responseJson.nopostman !== "undefined") {
          Alert.alert(
            "Verifiziere Dich",
            "Du kannst erst Pakete liefern, wenn Du Deine Identität bestätigt hast.",
            [
              {
                text: "Jetzt bestätigen",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate("Verify");
                }
              },
              {
                text: "Später",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "notexist") {
            errmess = "Sendung existiert nicht.";
          } else if (responseJson.code == "ownparcel") {
            errmess = "Du kannst Deine eigenen Pakete nicht selbst ausliefern!";
          } else if (responseJson.code == "jobExists") {
            errmess = "Das Paket wird bereits ausgeliefert.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          // await alert("Login fehlgeschlagen.");
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt...",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
          //this.setState({ loaderIsVisible: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
      console.log(resp);
    });*/
  };
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
