import React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View, Alert, Dimensions, TextInput, Modal } from "react-native";
import { Button, Text, Image, Overlay } from "react-native-elements";
import HeaderImage from "../../components/HeaderImage";
import { TouchableItem } from "../../../components/TouchableItem";
import { formatDate, stringFormatDate } from "../../helper/DateFormat";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../../config";
import SignContractLayer from "../../addParcel/SignContractLayer";
import * as ScreenOrientation from 'expo-screen-orientation';
const { apiUrl } = getConfVars();

var self;
export default class MyCourierJobDetailBringDeliverScreen extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      id: "",
      parcelId: "1",
      parcelNumber:'',
      loaderIsVisible: false,
      mandateLayerVisible: false,
      pickupLayerVisible: false,
      pickupParcelNumber: "",
      pickupParcelRecName: "",
      jobid: "",
      courier_todo:"",
      contractLayerVisible: false,
      signature: null
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Sendungsdetails`,
      headerBackTitle: `zurück`
    };
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("UNSAFE_componentWillReceiveProps");
    if (nextProps.navigation.state.params.jobid != "") {
      this.setState({
        jobid: nextProps.navigation.state.params.jobid,
        parcelId: nextProps.navigation.state.params.parcelid,
        parcelNumber: nextProps.navigation.state.params.parcelNumber,
        pickupParcelRecName: nextProps.navigation.state.params.destFullName,
        courier_todo: nextProps.navigation.state.params.courier_todo
      });
    }
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);
    console.log("this.state.courier_todo " + this.state.courier_todo);
  }

  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: this.refreshHandler,
      parcelId: tmp["id"]
    });
    //console.log(tmp);
  }

  refreshHandler(id) {
    self.props.navigation.navigate("MyParcelEdit", { itemID: id });
  }
  _openContract = async () =>{
    this.setState({ contractLayerVisible: true },()=>{ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)});



    //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
  }
    _closeContract = async () => {
      this.setState({ contractLayerVisible: false });
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    };
    _setSignature = sig => {
      this.setState({ signature: sig });
    };
  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, jobid, provider, recvr_firstname, recvr_lastname, dest_street, dest_no, dest_zip, dest_city, dest_lat, dest_lng, location_lat, location_lng, location_type, location_street, location_no, location_zip, location_city, time_from, time_to, time_from2, time_to2, distanceText, durationText, costs, currency } = this.props;

    return (
      <View style={styles.container}>


          <Modal visible={this.state.contractLayerVisible} width={"100%"} height={"100%"} supportedOrientations={['portrait', 'landscape']} >
            <View style={{padding:40,flex:1}}>
              <SignContractLayer closeFunc={this._closeContract} func={this._setSignature} />
              </View>
            </Modal>

        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>

        <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
          <HeaderImage image="scan" />
        </View>
        <View style={{ padding: 5, justifyContent: "center", flex: 1 }}>
    <Text style={{ alignSelf: "center",fontWeight:'bold' }}>Paket {this.state.parcelNumber} übergeben? </Text>
          <Text
            style={{
              alignSelf: "center",
              textAlign: "center",
              marginBottom: 20,
              marginTop:10
            }}
          >
            Überprüfe bitte den Namen des Empfängers/ der Empfängerin. Übernimmt eine andere Person die Annahme der Lieferung musst du den Namen im unteren Feld abändern.
          </Text>
          <Text
            style={{
              alignSelf: "center",
              textAlign: "center",
              marginBottom: 20
            }}
          >
            Aus hygienischen Gründen kannst du den Auftrag auch durch deine eigene Unterschrift abschließen.
          </Text>

          <View style={{ height:32,padding: 0, borderColor: "#ccc", borderWidth: 1,flexDirection:'row'  }}>
            
            <TextInput style={{ paddingLeft:5,height:32,alignSelf:"flex-start" ,flexGrow: 1 }} placeholder={"Name des Empfängers"} onChangeText={value => this.setState({ pickupParcelRecName: value })} value={this.state.pickupParcelRecName} />
          </View>

          <Button onPress={() => this._openContract()} title={"Unterschreiben lassen"} buttonStyle={{ marginTop: 10, marginBottom: 20, borderRadius: 0 }} />

          <Image resizeMode={"contain"} style={{ width: "100%", height: 114,display:this.state.signature!= null ? 'flex' : 'none' }} source={{ uri: this.state.signature!= null ? this.state.signature: ' ' }} />
        </View>
        <View style={{ padding: 5, justifyContent: "flex-end", flex: 1 }}>
          <Button onPress={this._nextstep} title={"Bestätigen"} disabled={this.state.pickupParcelRecName != ""  && this.state.signature!= null ? false : true} buttonStyle={{ borderRadius: 0 }} />
        </View>
      </View>
    );
  }
  _nextstep = () => {

    Alert.alert(
      "Fast geschafft!",
      "Du hast das Paket abgegben?",
      [
        {
          text: "Nein",
          onPress: () => {}
        },
        {
          text: "Ja",
          onPress: () => this._deliver(this.state.jobid, this.state.parcelId,this.state.courier_todo)
        }
      ],
      { cancelable: true }
    );
  };



  _deliver = (jobid, parcelid,courier_todo) => {
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);

    /*if(courier_todo=="bring"){
      var alert_text="Du kannst es jetzt an das Versandunternehmen übergeben!";
      var next_screen="MyDeliveryParcelDetailRetour";
    }else{*/
      var alert_text="Dein Job ist jetzt erledigt.";
      var next_screen="MyCourierJobDetailBring";
   // }

    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/deliver_courier.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: jobid,
        parcelID: parcelid,
        parcelNumber:this.state.parcelNumber,
        parcelRecName: this.state.pickupParcelRecName,
        courier_todo: courier_todo,
        signature: this.state.signature,
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {
          Alert.alert(
            "Danke!",
            alert_text,
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate(next_screen, {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "notexist") {
            errmess = "Das stimmt etwas nicht. Bitte wende Dich an den Support.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt...",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
