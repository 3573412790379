import React from "react";
import { StyleSheet, Text, View, Image, Alert, Platform, TouchableOpacity } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import Constants from "expo-constants";
import * as Linking from 'expo-linking'
import { Button } from "react-native-elements";
import { MyProvider, MyContext } from "../rootProvider";
import * as Permissions from "expo-permissions";
//import * as Device from 'expo-device';

import getConfVars from "../config";
const { apiUrl } = getConfVars();

export default class TodoScreen extends React.Component {
  static navigationOptions = {
    // title: 'app.json',
    header: null
  };

  constructor(props) {
    super(props);
    this.state = {
      loaderIsVisible: false,
      versioncheck: false
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this._initialcheck();
  }
  UNSAFE_componentWillMount() {
    this._initialcheck();
  }
  async componentDidMount() {
    //
    let token = await AsyncStorage.getItem("pushToken");
    if (token == null) {
      this.enablePushNotifications();
    }
  }
  _initialcheck = () => {
    console.log("chk inital");
    this.setState({ refreshing: true, versioncheck: true });



    fetch(apiUrl + "version/check.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
       // deviceBrand:Device.brand,
      //  deviceManufacturer:Device.manufacturer,
        
        userID: global.userID,
        loginKey: global.loginKey,
        version: Constants.manifest.version,
        deviceID:Constants.deviceId,
        deviceName:Constants.deviceName,
        installationId:Constants.installationId,
        nativeAppVersion:Constants.nativeAppVersion,
        nativeBuildVersion:Constants.nativeBuildVersion,
        deviceYearClass:Constants.deviceYearClass,
        userPlatform: Platform.OS
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.exit !== "undefined") {
          await AsyncStorage.clear();
          this.props.navigation.navigate("Auth");
        } else if (typeof responseJson.update !== "undefined") {
          Alert.alert(
            "Hinweis",
            "Bitte führe ein Update auf die neuste version durch. ",
            [
              {
                text: "OK",
                onPress: async () => {
                  await AsyncStorage.clear();
                  this.props.navigation.navigate("Auth");
                }
              }
            ],
            { cancelable: false }
          );
        } else {
          AsyncStorage.setItem("isPostman", responseJson.header.verified);
          global.isPostman = responseJson.header.verified;
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  async enablePushNotifications() {
    const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
    let finalStatus = existingStatus;

    console.log("existingStatus: " + existingStatus);
    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.

    if (existingStatus !== "granted") {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
      console.log("finalStatus: " + finalStatus);
    }

    // Stop here if the user did not grant permissions
    if (finalStatus !== "granted") {
      console.log("finalStatus !== granted");
    } else {
      // Get the token that uniquely identifies this device
      let tokenobj = await Notifications.getExpoPushTokenAsync();
      let token="";
      if(tokenobj.data != undefined){
        token= tokenobj.data;
      }else{
        token= tokenobj;
      }
     
      console.log("token: " + token);
      this.setState(state => {
        return {
          ...state
        };
      });

      const resp = await fetch(apiUrl + "user/setpushtoken.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          pushToken: token,
          todo: "enable",
          userID: global.userID,
          loginKey: global.loginKey,
          userPlatform: Platform.OS
        })
      })
        .then(response => response.json())
        .then(async responseJson => {
          if (typeof responseJson.success !== "undefined") {
            //alert(responseJson.realname);


            await AsyncStorage.setItem("pushToken", token);
            await AsyncStorage.setItem("notifyEnabled", "1");

            this.setState({ notificationsEnabled: true });
          } else {
            Alert.alert("Hinweis", "Fehler TD01", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
          }
        })
        .catch(error => {
          console.error(error);
          Alert.alert("Hinweis", "Fehler TD01", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
        });
    }
  }
  _newparcel = context => {
    if (context.state.parcelNumber != "") {
      Alert.alert(
        "",
        "Es wurden bereits Daten zu einem Paket eingegeben. Möchtest Du diese wieder aufnehmen? ",
        [
          {
            text: "Nein",
            onPress: () => {
              context.handleReset();
              this.props.navigation.navigate("AddParcelService");
            }
          },
          {
            text: "Ja",
            onPress: () => this.props.navigation.navigate("AddParcelService")
          }
        ],
        { cancelable: true }
      );
    } else {
      this.props.navigation.navigate("AddParcelService");
    }
  };
  _findparcels = () => {
    this.props.navigation.navigate("DeliveryMap");
  };

  render() {
    /* Go ahead and delete ExpoConfigView and replace it with your
     * content, we just wanted to give you a quick view of your config */
    if (this.state.versioncheck == false) {
      this._initialcheck();
    }
    return (
      <MyContext.Consumer>
        {context => (
          <View style={styles.container}>
            <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}></View>
            <View style={styles.welcomeContainer}>
              <Image source={require("../assets/images/mrpostman.jpg")} style={{ width: 191, height: 100 }} />

              <Text></Text>

              <View style={styles.buttons}>
                <Button onPress={() => this._newparcel(context)} title={"Mein Paket eintragen"} raised type="outline" buttonStyle={{ borderRadius: 0, width: 250 }} />
              </View>

              <View style={styles.buttons}>
                <Button onPress={this._findparcels} raised title={"Pakete liefern"} buttonStyle={{ borderRadius: 0, width: 250 }} />
              </View>
            </View>
            <View style={{ padding: 5, justifyContent: "flex-end", flex: 1, marginBottom: 1 }}>
              <View style={{ justifyContent: "center", flexDirection: "row", marginBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("https://www.mister-postman.net/datenschutz");
                  }}
                >
                  <Text style={styles.legaltext}>Datenschutz</Text>
                </TouchableOpacity>
                <Text style={styles.separator}>|</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("https://www.mister-postman.net/impressum");
                  }}
                >
                  <Text style={styles.legaltext}>Impressum</Text>
                </TouchableOpacity>
                <Text style={styles.separator}>|</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("https://www.mister-postman.net/agb");
                  }}
                >
                  <Text style={styles.legaltext}>AGB</Text>
                </TouchableOpacity>
              </View>
              <Text style={[styles.legaltext, { textAlign: "center", fontSize: 9, color: "#d8d8d8" }]}>Version: {Constants.manifest.version}</Text>
            </View>
          </View>
        )}
      </MyContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  legaltext: {
    fontSize: 11,
    color: "grey"
  },
  separator: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: 11
  },
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    justifyContent: "center"
  },
  buttons: {
    margin: 10
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  }
});
