import React from "react";
import { Image, Dimensions } from "react-native";

const dimensions = Dimensions.get("window");
const imageHeight = Math.round((dimensions.width * 444) / 1500);
const imageWidth = dimensions.width;

const images = {
  register: require("../../assets/images/header/header_register.jpg"),
  empfangen: require("../../assets/images/header/header_empfangen.jpg"),
  standort: require("../../assets/images/header/header_standort.jpg"),
  scan: require("../../assets/images/header/header_scan.jpg"),
  provider: require("../../assets/images/header/header_provider.jpg"),
  code: require("../../assets/images/header/header_code.jpg"),
  roller: require("../../assets/images/header/header_rollerfahrer.jpg"),
  muenze: require("../../assets/images/header/header_muenze.jpg"),
  uhren: require("../../assets/images/header/header_uhren.jpg"),
  profile: require("../../assets/images/header/header_profile.jpg"),
  size: require("../../assets/images/header/header_size.jpg"),
  courier: require("../../assets/images/header/header_courier.jpg")
};

export default class HeaderImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: null
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ img: images[this.props.image] });
  }
  render() {
    return <Image style={{ height: imageHeight, width: imageWidth,resizeMode: 'contain' }} source={this.state.img} />;
  }
}
