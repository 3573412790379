import React from "react";
import { Image, Platform, ScrollView, StyleSheet, TouchableWithoutFeedback, View, TextInput, Alert, Keyboard, Dimensions, KeyboardAvoidingView } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking'
import { Input, Button, Text, Overlay, CheckBox } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import HeaderImage from "../components/HeaderImage";
import { Header } from "react-navigation";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class RegisterScreen extends React.Component {
  /*static navigationOptions = {
    title: "Registrieren"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      available: false,
      company:"",
      firstname: "",
      lastname: "",
      street: "",
      zip: "",
      city: "",
      email: "",
      email2: "",
      loaderIsVisible: false,
      checkedDSGVO: false,
      checkedAGB: false,
    };
  }
  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  async UNSAFE_componentWillMount() {}

  render() {
    return (
      <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
            </View>
          </Overlay>


          <View style={styles.containerTop}>
            <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={Platform.OS === "ios" ? Header.HEIGHT : Header.HEIGHT + 25}  behavior={Platform.OS == "ios" ? "padding" : "height"}>
              <ScrollView style={styles.container}>
                <HeaderImage image="register" />
                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={company => this.setState({ company })}
                  value={this.state.company}
                  placeholder="Optional"
                  label="Firma:"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("2")}
                  ref="1"
                />
                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={firstname => this.setState({ firstname })}
                  value={this.state.firstname}
                  placeholder=""
                  label="Vorname:"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 20 }}
                  rightIcon={<Icon name="check" size={24} color={this.state.firstname.length > 1 ? "green" : "transparent"} />}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("3")}
                  ref="2"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={lastname => this.setState({ lastname })}
                  value={this.state.lastname}
                  placeholder=""
                  label="Nachname:"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 20 }}
                  rightIcon={<Icon name="check" size={24} color={this.state.lastname.length > 1 ? "green" : "transparent"} />}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("4")}
                  ref="3"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={street => this.setState({ street })}
                  value={this.state.street}
                  placeholder=""
                  label="Strasse Nr.:"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 20 }}
                  rightIcon={<Icon name="check" size={24} color={this.state.street.length > 1 ? "green" : "transparent"} />}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("5")}
                  ref="4"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={zip => this.setState({ zip })}
                  value={this.state.zip}
                  placeholder=""
                  label="PLZ"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 20 }}
                  rightIcon={<Icon name="check" size={24} color={this.state.zip.length > 1 ? "green" : "transparent"} />}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("6")}
                  ref="5"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={city => this.setState({ city })}
                  value={this.state.city}
                  placeholder=""
                  label="Ort"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 20 }}
                  rightIcon={<Icon name="check" size={24} color={this.state.city.length > 1 ? "green" : "transparent"} />}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("7")}
                  ref="6"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={email => this.setState({ email })}
                  onBlur={() => {
                    this.setState({ email: this.state.email.trim() });
                  }}
                  value={this.state.email}
                  placeholder=""
                  label="Email"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 20 }}
                  rightIcon={<Icon name={this.state.email.length < 1 ? "check" : this._validateEmail(this.state.email) ? "check" : "close"} size={24} color={this.state.email.length < 1 ? "transparent" : this._validateEmail(this.state.email) ? "green" : "red"} _validateEmail />}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("8")}
                  ref="7"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  onChangeText={email2 => this.setState({ email2 })}
                  onBlur={() => {
                    this.setState({ email2: this.state.email2.trim() });
                  }}
                  value={this.state.email2}
                  placeholder=""
                  label="Email wiederholen"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 20 }}
                  rightIcon={
                    <Icon
                      name={this.state.email2.length > 1 && this.state.email.toLowerCase() == this.state.email2.toLowerCase() && this._validateEmail(this.state.email2) ? "check" : this.state.email2.length > 1 ? "close" : "check"}
                      size={24}
                      color={this.state.email2.length > 1 && this.state.email.toLowerCase() == this.state.email2.toLowerCase() && this._validateEmail(this.state.email2) ? "green" : this.state.email2.length > 1 ? "red" : "transparent"}
                    />
                  }
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("9")}
                  ref="8"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  containerStyle={{ marginTop: 20 }}
                  onChangeText={username => this._checkusername(username)}
                  onBlur={() => {
                    this.setState({ username: this.state.username.trim() });
                  }}
                  value={this.state.username}
                  label="Username"
                  labelStyle={{ marginTop: 5 }}
                  autoComplete="off"
                  autoCorrect={false}
                  rightIconContainerStyle={{ paddingRight: 10 }}
                  rightIcon={<Icon name={this.state.available ? "thumbs-up" : "thumbs-down"} size={24} color={this.state.username.length < 3 ? "transparent" : this.state.available ? "green" : "red"} />}
                  returnKeyType="done"
                  ref="9"
                />



                <View style={{ textAlign: "left", paddingTop:30  }}>
                  <Button
                    onPress={() => {
                      Linking.openURL("https://www.mister-postman.net/datenschutz");
                    }}
                    title={"Datenschutzbestimmungen"}
                    buttonStyle={{ borderRadius: 0 }}
                    containerStyle={{ marginLeft: 0 }}
                    type="clear"
                    iconRight
                    icon={<Icon name="external-link" size={17} color="#007AFF" />}
                  />

                  <Button
                    onPress={() => {
                      Linking.openURL("https://www.mister-postman.net/agb");
                    }}
                    title={"AGB  "}
                    buttonStyle={{ borderRadius: 0 }}
                    containerStyle={{ marginLeft: 0 }}
                    type="clear"
                    accessibilityLabel="AGB"
                    iconRight
                    icon={<Icon name="external-link" size={17} color="#007AFF" />}
                  />
                </View>
                <CheckBox title="Ich akzeptiere die Datenschutzbestimmungen" checked={this.state.checkedDSGVO} onPress={() => this.setState({ checkedDSGVO: !this.state.checkedDSGVO })} />

                <CheckBox title="Ich akzeptiere die AGB" checked={this.state.checkedAGB} onPress={() => this.setState({ checkedAGB: !this.state.checkedAGB })} />

                <Button
                  onPress={this.state.available ? this._register : null}
                  title={"Registrieren"}
                  buttonStyle={{ borderRadius: 0, width: 250 }}
                  containerStyle={{
                    width: 250,
                    alignSelf: "center",
                    marginTop: 30,
                    marginBottom: 100
                  }}
                  disabled={this._checkAllInputs()}
                  accessibilityLabel="Registrieren"
                />
              </ScrollView>
            </KeyboardAvoidingView>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
  _focusNextField(nextField) {
    this.refs[nextField].focus();
  }
  _checkAllInputs = () => {
    const { firstname, lastname, street, zip, city, email,email2, available, checkedDSGVO, checkedAGB } = this.state;

    if (firstname.length > 1 && lastname.length > 1 && street.length > 1 && zip.length > 1 && city.length > 1 && email.length > 1&& email==email2 && available == true && checkedDSGVO && checkedAGB ) {
      return false;
    } else {
      return true;
    }
  };

  _validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  _checkusername = async username => {
    await this.setState({ username: username });

    const uname = this.state.username;

    fetch(apiUrl + "user/checkusername.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: uname
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (responseJson.used === "0") {
          this.setState({ available: true });
        } else {
          this.setState({ available: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  _register = async () => {
    const {company, firstname, lastname, street, zip, city, email, username } = this.state;

    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "user/register.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        company: company,
        firstname: firstname,
        username: username,
        lastname: lastname,
        street: street,
        zip: zip,
        city: city,
        email: email
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {


          Alert.alert("Anmeldung erfolgreich!", "Dein Passwort wurde Dir soeben an Deine Email Adresse geschickt.", [
            {
              text: "Jetzt einloggen",
              onPress: () => this.props.navigation.navigate("SignIn")
            }
          ]);
        } else {
          // await alert("Login fehlgeschlagen.");

          Alert.alert(
            "Ups...",
            "Login fehlgeschlagen.",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );

          //this.setState({ loaderIsVisible: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  containerTop: {
    flex: 1,
    justifyContent: "center"
  },
  developmentModeText: {
    marginBottom: 20,
    color: "rgba(0,0,0,0.4)",
    fontSize: 14,
    lineHeight: 19,
    textAlign: "center"
  },
  contentContainer: {
    paddingTop: 30
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  },
  welcomeImage: {
    width: 200,
    height: 100,
    resizeMode: "contain",
    marginTop: 3,
    marginLeft: -10
  },
  getStartedContainer: {
    alignItems: "center",
    marginHorizontal: 50
  },
  homeScreenFilename: {
    marginVertical: 7
  },
  codeHighlightText: {
    color: "rgba(96,100,109, 0.8)"
  },
  codeHighlightContainer: {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 3,
    paddingHorizontal: 4
  },
  getStartedText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    lineHeight: 24,
    textAlign: "center"
  },
  tabBarInfoContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: "black",
        shadowOffset: { height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3
      },
      android: {
        elevation: 20
      }
    }),
    alignItems: "center",
    backgroundColor: "#fbfbfb",
    paddingVertical: 20
  },
  tabBarInfoText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    textAlign: "center"
  },
  navigationFilename: {
    marginTop: 5
  },
  helpContainer: {
    marginTop: 15,
    alignItems: "center"
  },
  helpLink: {
    paddingVertical: 15
  },
  helpLinkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
});
