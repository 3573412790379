export const formatDate = dateobj => {
  var fhour = ("0" + dateobj.getHours().toString()).slice(-2);
  var fmin = ("0" + dateobj.getMinutes().toString()).slice(-2);
  var fday = ("0" + dateobj.getDate().toString()).slice(-2);
  var fmonth = ("0" + (dateobj.getMonth() + 1)).slice(-2);
  var fyear = dateobj.getFullYear();
  return `${fday}. ${fmonth}. ${fyear}   ${fhour}:${fmin}`;
};

export const stringFormatDate_old = stringDate => {
  var dateobj = new Date(stringDate.split(" ")[0] + "T" + stringDate.split(" ")[1]);

  var date = new Date();
  var offsetInHours = date.getTimezoneOffset() / 60;

  dateobj.setHours(dateobj.getHours() + offsetInHours);

  var fhour = ("0" + dateobj.getHours().toString()).slice(-2);
  var fmin = ("0" + dateobj.getMinutes().toString()).slice(-2);
  var fday = ("0" + dateobj.getDate().toString()).slice(-2);
  var fmonth = ("0" + (dateobj.getMonth() + 1)).slice(-2);
  var fyear = dateobj.getFullYear();
  return `${fday}. ${fmonth}. ${fyear}   ${fhour}:${fmin}`;
};
export const stringFormatDate = stringDate => {
  var dateArr = stringDate.split(" ");
  var datePart=dateArr[0].split("-");
  var timePart=dateArr[1].split(":");

  var fhour = timePart[0];
  var fmin = timePart[1];
  var fday = datePart[2];
  var fmonth = datePart[1];
  var fyear = datePart[0];
  return `${fday}. ${fmonth}. ${fyear}   ${fhour}:${fmin}`;
};