import React from "react";
import { Image } from "react-native";
export default class ProviderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compIcons: {
        dpd: require("../../assets/images/shipping-icons/dpd.png"),
        gls: require("../../assets/images/shipping-icons/gls.png"),
        ups: require("../../assets/images/shipping-icons/ups.png"),
        dhl: require("../../assets/images/shipping-icons/post-germany.png"),
        hermes: require("../../assets/images/shipping-icons/hermes.png"),
        fedex: require("../../assets/images/shipping-icons/fedex.png"),
        tnt: require("../../assets/images/shipping-icons/tnt.png"),
        aznlocker: require("../../assets/images/shipping-icons/amazon-locker.png"),
        mp: require("../../assets/images/shipping-icons/mpcourier.png")
      },
      sizes: {
        small: {
          width: 85.33,
          height: 61
        },
        medium: {
          width: 62.49,
          height: 42.7
        },
        large: {
          width: 85.33,
          height: 61
        }
      }
    };
  }
  render() {
    const ico = this.state.compIcons[this.props.provider];
    if (this.props.size == null) {
      var logosize = "medium";
    } else {
      var logosize = this.props.size;
    }
    return <Image source={ico} style={{ height: this.state.sizes[logosize].height, width: this.state.sizes[logosize].width, marginRight: -4 }} />;
  }
}
