import React from "react";
import { ScrollView, StyleSheet,Picker, View, Alert, TextInput, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, Keyboard } from "react-native";
import * as Permissions from "expo-permissions";
import { Button, Text, Image, Overlay } from "react-native-elements";
import HeaderImage from "../components/HeaderImage";
import { Header } from "react-navigation";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

var self;
export default class MyDeliveryParcelRejectReturn extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      hasCameraPermission: null,
      id: "",
      parcelId: "1",
      loaderIsVisible: false,
      mandateLayerVisible: false,
      pickupLayerVisible: false,
      pickupParcelNumber: "",
      pickupParcelFreetext: "",
      doscan: false,
      jobid: "",
      reason:"custom",
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Sendungsdetails`,
      headerBackTitle: `zurück`
    };
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("UNSAFE_componentWillReceiveProps");
    if (nextProps.navigation.state.params.jobid != "") {
      this.setState({
        jobid: nextProps.navigation.state.params.jobid,
        parcelId: nextProps.navigation.state.params.parcelid
      });
    }
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);
  }

  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: this.refreshHandler,
      parcelId: tmp["id"]
    });
    //console.log(tmp);
  }
  async _get_cameramermission() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    this.setState({ hasCameraPermission: status === "granted" });
  }
  refreshHandler(id) {
    self.props.navigation.navigate("MyParcelEdit", { itemID: id });
  }
  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, jobid, provider, recvr_firstname, recvr_lastname, dest_street, dest_no, dest_zip, dest_city, dest_lat, dest_lng, location_lat, location_lng, location_type, location_street, location_no, location_zip, location_city, time_from, time_to, time_from2, time_to2, distanceText, durationText, costs, currency } = this.props;

    const dest_coords = {
      latitude: parseFloat(itemdata["dest_lat"]),
      longitude: parseFloat(itemdata["dest_lng"])
    };
    const coords = {
      latitude: parseFloat(itemdata["location_lat"]),
      longitude: parseFloat(itemdata["location_lng"])
    };
    //const itemId = this.props.navigation.state.params.itemId;
    // const itemId = "test";

    return (
      <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
            </View>
          </Overlay>

          <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={Platform.OS === "ios" ? Header.HEIGHT : Header.HEIGHT + 25} behavior={"padding"}>
            <ScrollView style={styles.container}>
              <HeaderImage image="scan" onPress={this._dismissKeyboard} />

              <Text style={{ alignSelf: "center", textAlign: "center" }}>Die Abholung war nicht möglich.</Text>
              <Text
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 20
                }}
              >
                Wähle aus den vorgegebenen Gründen aus.
              </Text>

              <Picker
              
                selectedValue={this.state.reason}
                style={{
                  width: "100%",
                  height: 200
                }}
                itemStyle={{ height: 200 }}
                onValueChange={(itemValue, itemIndex) => this.setState({reason: itemValue})}
       
              >
                <Picker.Item label="Es hat keiner aufgemacht" value="Es hat keiner aufgemacht" />
                <Picker.Item label="Adresse nicht gefunden" value="Adresse nicht gefunden" />
                <Picker.Item label="Adresse wohl falsch" value="Adresse wohl falsch" />
                <Picker.Item label="Übergabe verweigert" value="bergabe verweigert" />
                <Picker.Item label="Pakete(e) nicht frankiert" value="Pakete(e) nicht frankiert" />
                <Picker.Item label="Paket(e) beschädigt" value="Paket(e) beschädigt" />
                <Picker.Item label="anderer Grund" value="custom" />
              </Picker>



              <View style={{ marginTop: 20, padding: 10, borderColor: "#ccc", borderWidth: 1 }}>
                <TextInput
                  editable={this.state.reason != "custom" ? false : true} 
                  multiline={true}
                  scrollEnabled={false}
                  inputContainerStyle={{ textAlignVertical: "top" }}
                  style={{ height: 70, padding: 0, textAlignVertical: "top",opacity: this.state.reason != "custom" ? 0.5 : 1 }}
                  placeholder={"anderer Grund"}
                  onChangeText={value => {
                    this.setState({ pickupParcelFreetext: value });
                  }}
                  value={this.state.pickupParcelFreetext}
                />
              </View>
            </ScrollView>
          </KeyboardAvoidingView>

          <View style={{ padding: 5 }}>
            <Button onPress={this._nextstep} title={"Bestätigen"} disabled={this.state.reason != "custom" || this.state.pickupParcelFreetext != "" ? false : true} buttonStyle={{ borderRadius: 0 }} />
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
  _nextstep = () => {
    Alert.alert(
      "Abholung nicht möglich",
      "Hiermit bestätigst Du, das die Abholung der Retoure nicht möglich war.",
      [
        {
          text: "Nein",
          onPress: () => {}
        },
        {
          text: "Ja",
          onPress: () => this._pickup(this.state.jobid, this.state.parcelId)
        }
      ],
      { cancelable: true }
    );
  };

  _pickup = (jobid, parcelid) => {
    console.log("global.userID " + global.userID);
    console.log("this.state.jobid " + this.state.jobid);
    console.log("this.state.parcelId " + this.state.parcelId);

    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/reject_return.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: jobid,
        parcelID: parcelid,
        reason: this.state.reason,
        parcelFreetext: this.state.pickupParcelFreetext
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {
          Alert.alert(
            "Ok, die Abholung war nicht möglich.",
            "Du musst das Paket jetzt nicht abholen.",
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate("MyDeliveryParcelDetailRetour", {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "notexist") {
            errmess = "Das ist nicht die richtige Sendungsnummer.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Ups...",
            "Da hat etwas nicht geklappt...",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },
  containerTop: {
    flex: 1
  }
});
