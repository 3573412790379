import React from "react";
import { Platform, Text, View } from "react-native";
import { createStackNavigator, createBottomTabNavigator } from "react-navigation";
import TabBarIcon from "../components/TabBarIcon";
import TodoScreen from "../screens/TodoScreen";
import AddParcelServiceScreen from "../screens/addParcel/AddParcelServiceScreen";
import AddParcelProviderRetourScreen from "../screens/addParcel/AddParcelProviderRetourScreen";
import AddParcelProviderScreen from "../screens/addParcel/AddParcelProviderScreen";
import AddParcelNumberRetourScreen from "../screens/addParcel/AddParcelNumberRetourScreen";
import AddParcelNumberScreen from "../screens/addParcel/AddParcelNumberScreen";
import AddParcelTypeScreen from "../screens/addParcel/AddParcelTypeScreen";
import AddParcelWeightRetourScreen from "../screens/addParcel/AddParcelWeightRetourScreen";
import AddParcelWeightScreen from "../screens/addParcel/AddParcelWeightScreen";
import AddParcelLocationRetourScreen from "../screens/addParcel/AddParcelLocationRetourScreen";
import AddParcelLocationScreen from "../screens/addParcel/AddParcelLocationScreen";
import AddParcelDestinationScreen from "../screens/addParcel/AddParcelDestinationScreen";
import AddParcelTimeRetourScreen from "../screens/addParcel/AddParcelTimeRetourScreen";
import AddParcelTimeScreen from "../screens/addParcel/AddParcelTimeScreen";
import AddParcelPickupTimeScreen from "../screens/addParcel/AddParcelPickupTimeScreen";
import AddParcelCodeScreen from "../screens/addParcel/AddParcelCodeScreen";
import AddParcelOverviewRetourScreen from "../screens/addParcel/AddParcelOverviewRetourScreen";
import AddParcelOverviewScreen from "../screens/addParcel/AddParcelOverviewScreen";
//courier
import AddCourierServiceScreen from "../screens/addCourier/AddCourierServiceScreen";
import AddCourierWeightScreen from "../screens/addCourier/AddCourierWeightScreen";
import AddCourierLocationScreen from "../screens/addCourier/AddCourierLocationScreen";
import AddCourierDestinationScreen from "../screens/addCourier/AddCourierDestinationScreen";
import AddCourierBring_PickupTimeScreen from "../screens/addCourier/AddCourierBring_PickupTimeScreen";
import AddCourierBring_DeliveryTimeScreen from "../screens/addCourier/AddCourierBring_DeliveryTimeScreen";
import AddCourierPickup_PickupTimeScreen from "../screens/addCourier/AddCourierPickup_PickupTimeScreen";
import AddCourierPickup_DeliveryTimeScreen from "../screens/addCourier/AddCourierPickup_DeliveryTimeScreen";
import AddCourierOverviewScreen from "../screens/addCourier/AddCourierOverviewScreen";
import AddCourierNumberScreen from "../screens/addCourier/AddCourierNumberScreen";
import MyCourierPickupScreen from "../screens/myparcels/MyCourierPickupScreen";
import MyCourierBringScreen from "../screens/myparcels/MyCourierBringScreen";
import MyCourierJobDetailBringScreen from "../screens/courier/bring/MyCourierJobDetailBringScreen";
import MyCourierJobDetailPickupScreen from "../screens/courier/pickup/MyCourierJobDetailPickupScreen";

import MyCourierJobDoPickupScreen from "../screens/courier/MyCourierJobDoPickupScreen";
import MyCourierJobDetailBringDeliverScreen from "../screens/courier/bring/MyCourierJobDetailBringDeliverScreen";
import MyCourierJobDetailBringRejectScreen from "../screens/courier/bring/MyCourierJobDetailBringRejectScreen";

import CourierDetailScreen from "../screens/deliver/CourierDetailScreen";

//
import DeliveryParcelDetailRetourScreen from "../screens/deliver/DeliveryParcelDetailRetourScreen";
import DeliveryParcelDetailScreen from "../screens/deliver/DeliveryParcelDetailScreen";
import MyDeliveryParcelDetailRetourScreen from "../screens/deliver/MyDeliveryParcelDetailRetourScreen";
import MyDeliveryParcelDetailScreen from "../screens/deliver/MyDeliveryParcelDetailScreen";
import MyDeliveryParcelPickupReturnScreen from "../screens/deliver/MyDeliveryParcelPickupReturnScreen";
import MyDeliveryParcelPickupScreen from "../screens/deliver/MyDeliveryParcelPickupScreen";
import MyDeliveryParcelRejectReturnScreen from "../screens/deliver/MyDeliveryParcelRejectReturnScreen";
import MyDeliveryParcelDeliveryReturnScreen from "../screens/deliver/MyDeliveryParcelDeliveryReturnScreen";
import MyDeliveryParcelRejectScreen from "../screens/deliver/MyDeliveryParcelRejectScreen";
import MyParcels from "../screens/myparcels/MyParcelsScreen";
import MyParcelRetour from "../screens/myparcels/MyParcelRetour";
import MyParcel from "../screens/myparcels/MyParcel";
import MyParcelReceiveScreen from "../screens/myparcels/MyParcelReceiveScreen";
import DeliveryOverviewScreen from "../screens/deliver/DeliveryOverviewScreen";
import DeliveryMapScreen from "../screens/deliver/DeliveryMapScreen";
import ProfileScreen from "../screens/ProfileScreen";
import DebitScreen from "../screens/profile/DebitScreen";
import PayoutScreen from "../screens/profile/PayoutScreen";
import VerifyScreen from "../screens/profile/VerifyScreen";
import SettingsScreen from "../screens/profile/SettingsScreen";
import { TouchableItem } from "../components/TouchableItem";

const TodoStack = createStackNavigator({
  Todo: {
    screen: TodoScreen,
    navigationOptions: () => ({
      headerStyle: {
        height: 0,
        backgroundColor: "#fec600"
      }
    })
  }
});

TodoStack.navigationOptions = {
  tabBarLabel: "Start",
  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={Platform.OS === "ios" ? "ios-home" : "md-home"} />
};

// static navigationOptions = { title: "Termos e Condições",
/*
  headerStyle: { backgroundColor: "piurple" }, 
  headerTintColor: "white", 
  headerTitleStyle: { fontSize: 16, fontWeight: "normal", justifyContent: "center", alignSelf: "center", width: "65%" } };
*/

const MyParcelsStack = createStackNavigator({
  MyParcels: {
    screen: MyParcels,
    navigationOptions: () => ({
      title: `Meine Pakete`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },

      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      }
    })
  },
  MyParcelRetour: {
    screen: MyParcelRetour
    /*navigationOptions: () => ({
      title: `Sendung`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``
    })*/
  },
  MyParcel: {
    screen: MyParcel
    /*navigationOptions: () => ({
      title: `Sendung`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``
    })*/
  },
  MyParcelReceive: {
    screen: MyParcelReceiveScreen,
    navigationOptions: () => ({
      title: `Empfang bestätigen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },

      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      }
    })
    /*navigationOptions: () => ({
      title: `Sendung bearbeiten`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``
    })*/
  },

  /* AddParcel: {
    screen: AddParcelProviderScreen,
    navigationOptions: () => ({
      title: `Paketdient`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      }
    })
  },*/
  AddCourierService: {
    screen: AddCourierServiceScreen,
    navigationOptions: () => ({
      title: `Kurierdienst`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierNumber: {
    screen: AddCourierNumberScreen,
    navigationOptions: () => ({
      title: `Kurier Retournummer`,
      headerBackTitle: `zurück`,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerRight: <View />,
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerTruncatedBackTitle: ``
    })
  },
  AddCourierWeight: {
    screen: AddCourierWeightScreen,
    navigationOptions: () => ({
      title: `Gewicht`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierLocation: {
    screen: AddCourierLocationScreen,
    navigationOptions: () => ({
      title: `Abhol-Adresse`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierDestination: {
    screen: AddCourierDestinationScreen,
    navigationOptions: () => ({
      title: `Ziel-Adresse`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierBring_PickupTime: {
    screen: AddCourierBring_PickupTimeScreen,
    navigationOptions: () => ({
      title: `Abholzeiten`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierBring_DeliveryTime: {
    screen: AddCourierBring_DeliveryTimeScreen,
    navigationOptions: () => ({
      title: `Abgabezeitraum`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierPickup_PickupTime: {
    screen: AddCourierPickup_PickupTimeScreen,
    navigationOptions: () => ({
      title: `Abholzeiten`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierPickup_DeliveryTime: {
    screen: AddCourierPickup_DeliveryTimeScreen,
    navigationOptions: () => ({
      title: `Abgabezeitraum`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddCourierOverview: {
    screen: AddCourierOverviewScreen,
    navigationOptions: () => ({
      //title: `Kurier Übersicht`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyCourierPickup: {
    screen: MyCourierPickupScreen
    /*navigationOptions: () => ({
      title: `Sendung`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``
    })*/
  },
  MyCourierBring: {
    screen: MyCourierBringScreen
    /*navigationOptions: () => ({
      title: `Sendung`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``
    })*/
  },
  









  AddParcelService: {
    screen: AddParcelServiceScreen,
    navigationOptions: () => ({
      title: `Liefern oder Abholen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelProviderRetour: {
    screen: AddParcelProviderRetourScreen,
    navigationOptions: () => ({
      title: `Paketdienst`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelProvider: {
    screen: AddParcelProviderScreen,
    navigationOptions: () => ({
      title: `Paketdienst`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelNumberRetour: {
    screen: AddParcelNumberRetourScreen,
    navigationOptions: () => ({
      title: `Paketnummer`,
      headerBackTitle: `zurück`,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerRight: <View />,
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerTruncatedBackTitle: ``
    })
  },
  AddParcelNumber: {
    screen: AddParcelNumberScreen,
    navigationOptions: () => ({
      title: `Paketnummer`,
      headerBackTitle: `zurück`,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerRight: <View />,
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerTruncatedBackTitle: ``
    })
  },
  AddParcelWeightRetour: {
    screen: AddParcelWeightRetourScreen,
    navigationOptions: () => ({
      title: `Gewicht`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelWeight: {
    screen: AddParcelWeightScreen,
    navigationOptions: () => ({
      title: `Gewicht`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelType: {
    screen: AddParcelTypeScreen,
    navigationOptions: () => ({
      title: `Standort`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelLocationRetour: {
    screen: AddParcelLocationRetourScreen,
    navigationOptions: () => ({
      title: `Abhol-Adresse`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelLocation: {
    screen: AddParcelLocationScreen,
    navigationOptions: () => ({
      title: `Abhol-Adresse`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },

  AddParcelDestination: {
    screen: AddParcelDestinationScreen,
    navigationOptions: () => ({
      title: `Ziel-Adresse`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },

  AddParcelPickupTime: {
    screen: AddParcelPickupTimeScreen,
    navigationOptions: () => ({
      title: `Abzuholen bis`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelTimeRetour: {
    screen: AddParcelTimeRetourScreen,
    navigationOptions: () => ({
      title: `Abholzeit`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelTime: {
    screen: AddParcelTimeScreen,
    navigationOptions: () => ({
      title: `Lieferzeitraum`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelCode: {
    screen: AddParcelCodeScreen,
    navigationOptions: () => ({
      title: `Code`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelOverviewRetour: {
    screen: AddParcelOverviewRetourScreen,
    navigationOptions: () => ({
      title: `Übersicht Abholung`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  AddParcelOverview: {
    screen: AddParcelOverviewScreen,
    navigationOptions: () => ({
      title: `Übersicht`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  }
});
MyParcelsStack.navigationOptions = {
  tabBarLabel: "Pakete",
  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={Platform.OS === "ios" ? "ios-contract" : "md-contract"} />
};
const DeliveryStack = createStackNavigator({
  DeliveryOverview: {
    screen: DeliveryOverviewScreen,
    navigationOptions: () => ({
      title: `Paket liefern`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      }
    })
  },
  DeliveryMap: {
    screen: DeliveryMapScreen,
    navigationOptions: () => ({
      title: `Paket liefern`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  DeliveryParcelDetailRetour: {
    screen: DeliveryParcelDetailRetourScreen,
    navigationOptions: () => ({
      title: `Abhol-Details`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  DeliveryParcelDetail: {
    screen: DeliveryParcelDetailScreen,
    navigationOptions: () => ({
      title: `Sendungs-Details`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyDeliveryParcelDetailRetour: {
    screen: MyDeliveryParcelDetailRetourScreen,
    navigationOptions: () => ({
      title: `Abhol-Details`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyDeliveryParcelDetail: {
    screen: MyDeliveryParcelDetailScreen,
    navigationOptions: () => ({
      title: `Sendungs-Details`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyDeliveryParcelPickupReturn: {
    screen: MyDeliveryParcelPickupReturnScreen,
    navigationOptions: () => ({
      title: `Abholung bestätigen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyDeliveryParcelPickup: {
    screen: MyDeliveryParcelPickupScreen,
    navigationOptions: () => ({
      title: `Abholung bestätigen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyDeliveryParcelRejectReturn: {
    screen: MyDeliveryParcelRejectReturnScreen,
    navigationOptions: () => ({
      title: `Abholung nicht möglich`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyDeliveryParcelDeliveryReturn: {
    screen: MyDeliveryParcelDeliveryReturnScreen,
    navigationOptions: () => ({
      title: `Paket abgeben`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyDeliveryParcelReject: {
    screen: MyDeliveryParcelRejectScreen,
    navigationOptions: () => ({
      title: `Abholung zurückweisen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },

  CourierDetail: {
    screen: CourierDetailScreen,
    navigationOptions: () => ({
      title: `Kurier Sendungs-Details`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },

  
  MyCourierJobDetailBring: {
    screen: MyCourierJobDetailBringScreen,
    navigationOptions: () => ({
      title: `Kurier(L) Sendungs-Details`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyCourierJobDetailPickup: {
    screen: MyCourierJobDetailPickupScreen,
    navigationOptions: () => ({
      title: `Kurier(R) Sendungs-Details`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyCourierJobDoPickup: {
    screen: MyCourierJobDoPickupScreen,
    navigationOptions: () => ({
      title: `Kurier Abholung bestätigen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyCourierJobDetailBringDeliver: {
    screen: MyCourierJobDetailBringDeliverScreen,
    navigationOptions: () => ({
      title: `Kurier Abgabe bestätigen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  MyCourierJobDetailBringReject: {
    screen: MyCourierJobDetailBringRejectScreen,
    navigationOptions: () => ({
      title: `Abholung zurückweisen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },




});
DeliveryStack.navigationOptions = {
  tabBarLabel: "Liefern",
  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={Platform.OS === "ios" ? "ios-car" : "md-car"} />
};

const LinksStack = createStackNavigator({
  Links: LinksScreen
});

LinksStack.navigationOptions = {
  tabBarLabel: "Links",
  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={Platform.OS === "ios" ? "ios-link" : "md-link"} />
};

const ProfileStack = createStackNavigator({
  Profile: {
    screen: ProfileScreen
  },
  Debit: {
    screen: DebitScreen,
    navigationOptions: () => ({
      title: `Kontoaktivitäten`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },

      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  Payout: {
    screen: PayoutScreen,
    navigationOptions: () => ({
      title: `Guthaben auszahlen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },

      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  Verify: {
    screen: VerifyScreen,
    navigationOptions: () => ({
      title: `Identität bestätigen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },

      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  },
  Settings: {
    screen: SettingsScreen,
    navigationOptions: () => ({
      title: `Einstellungen`,
      headerBackTitle: `zurück`,
      headerTruncatedBackTitle: ``,
      headerTitleStyle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "center",
        alignSelf: "center",
        flex: 1
      },

      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: <View />
    })
  }
});

ProfileStack.navigationOptions = {
  tabBarLabel: "Profil",
  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name={"person-circle-outline"} />
};

export default createBottomTabNavigator({
  TodoStack,
  MyParcelsStack,
  DeliveryStack,
  ProfileStack
});
