import React from "react";
import { Platform, ScrollView, StyleSheet, View, Alert, ActivityIndicator, TextInput, Keyboard, TouchableOpacity } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import MapView from "react-native-maps";
import Constants from "expo-constants";
//import * as Permissions from "expo-permissions";
//import * as Location from "expo-location";
import { Button, Text, Overlay } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import SelectFromAddressCache from "./components/SelectFromAddressCache";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class AddParcelLocationRetourScreen extends React.Component {
  render() {
    return <MyContext.Consumer>{context => <ParcelDataHandler context={context} navigation={this.props.navigation} />}</MyContext.Consumer>;
  }
}
class ParcelDataHandler extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      street: "",
      zip: "",
      city: "",
      hideList: true,
      location: null,
      preselect: false,
      manualInpulock: false,
      parcelAddressManually: "",
      listViewDisplayed: true,
      loaderIsVisible: false,
      zips: ""
    };
  }
  /*componentWillMount() {
    this._updNewValue();
  }
  componentDidUpdate(prevProps, prevState) {
    this._updNewValue();
  }
  _updNewValue = async () => {
    try {
      await AsyncStorage.setItem("newParcelLocStreet", this.state.street);
      await AsyncStorage.setItem("newParcelLocZip", this.state.zip);
      await AsyncStorage.setItem("newParcelLocCity", this.state.city);
    } catch (error) {
   
    }
  };*/
  async componentDidMount() {
    //var zips = await AsyncStorage.getItem("allowedZips");
    //if (zips == "" || zips == null) {
    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "parcel/zips.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        this.setState(
          {
            loaderIsVisible: false
          },
          function() {
            // In this block you can do something with new state.
          }
        );
        await AsyncStorage.setItem("allowedZips", responseJson.zips);
        this.setState({ zips: responseJson.zips.split(",") });
      })
      .catch(error => {
        console.error(error);
      });
    //} else {
    // this.setState({ zips: zips.split(",") });
    //}
  }
  _fillAddress = place => {
    var componentForm = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name"
    };
    var arr = {};
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        arr[addressType] = place.address_components[i][componentForm[addressType]];
        //document.getElementById(addressType).value = val;
      }
    }
    return arr;
  };
  _loadLocArr = async () => {
    console.log("1");
    var locArr = null;

    //await AsyncStorage.removeItem("locArrStr");
    const locArrStr = await AsyncStorage.getItem("locArrStr");
    if (locArrStr == null) {
      console.log("leer");
      locArr = [];
    } else {
      console.log("nicht leer");
      locArr = JSON.parse(locArrStr);
      console.log(locArrStr);
    }
    //locArr = JSON.parse(locArrStr);
    // console.log(locArr);
    return locArr;
  };

  render() {
    let text = "Waiting..";
    if (this.state.errorMessage) {
      text = this.state.errorMessage;
    } else if (this.state.location) {
      text = JSON.stringify(this.state.location);
    }
    return this.state.location ? (
      <View style={styles.container}>
        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>
        <GooglePlacesAutocomplete
          enablePoweredByContainer={true}
          placeholder="Adresse eingeben"
          minLength={2} // minimum length of text to search
          autoFocus={false}
          returnKeyType={"search"} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
          keyboardAppearance={"light"} // Can be left out for default keyboardAppearance https://facebook.github.io/react-native/docs/textinput.html#keyboardappearance
          listViewDisplayed={this.state.listViewDisplayed}
          fetchDetails={true}
          renderDescription={row => row.description} // custom description render
          onPress={async (data, details = null) => {
            // 'details' is provided when fetchDetails = true
            console.log(data, details);

            var addressFields = this._fillAddress(details);

            var tmplocation = {
              coords: {
                latitude: details.geometry.location.lat,
                longitude: details.geometry.location.lng
              }
            };

            this._setMapAddress(addressFields, details.formatted_address, tmplocation, details.place_id);

            if (addressFields.street_number == null) {
              this.props.context.setNewParcelValue("parcelLocNumber", "");
              /*Alert.alert(
                "Ups...",
                "Bitte die Hausnummer ergänzen.",
                [
                  {
                    text: "OK"
                  }
                ],
                { cancelable: false }
              );*/
            }
            this.setState({ listViewDisplayed: false });
            Keyboard.dismiss();
          }}
          textInputProps={{
            onFocus: () => this.setState({ hideList: false }),
            onBlur: () => this.setState({ hideList: true })
          }}
          getDefaultValue={() => ""}
          query={{
            // available options: https://developers.google.com/places/web-service/autocomplete
            key: "AIzaSyD19zYBtwjNn0o-uAenvjNOlFdb5DPH9Sw",
            language: "de", // language of the results
            types: "geocode", // default: 'geocode' '(cities)'
            components: "country:de"
          }}
          styles={{
            container: {
              position: "absolute",
              zIndex: 9999,
              width: "100%",
              backgroundColor: "#ffffff"
            },
            textInputContainer: {
              width: "100%"
            },
            description: {
              fontWeight: "bold"
            },
            predefinedPlacesDescription: {
              color: "#1faadb"
            }
          }}
          //GooglePlacesDetailsQuery={{
            // available options for GooglePlacesDetails API : https://developers.google.com/places/web-service/details
           // fields: "formatted_address"
          //}}
          filterReverseGeocodingByTypes={["locality", "administrative_area_level_3"]} // filter the reverse geocoding results by types - ['locality', 'administrative_area_level_3'] if you want to display only cities
          debounce={220} // debounce the requests in ms. Set to 0 to remove debounce. By default 0ms.
          textInputProps={{
            onChangeText: text => {
              console.log(text);
            }
          }}
          text={this.state.parcelAddressManually}
        />

        <MapView
          region={{
            latitude: this.props.context.state.parcelLocLocation.coords.latitude,
            longitude: this.props.context.state.parcelLocLocation.coords.longitude,
            latitudeDelta: 0.01,
            longitudeDelta: 0.01
          }}
          initialRegion={{
            latitude: this.props.context.state.parcelLocLocation.coords.latitude,
            longitude: this.props.context.state.parcelLocLocation.coords.longitude,
            latitudeDelta: 0.01,
            longitudeDelta: 0.01
          }}
          style={{ flex: 1 }}
          // onPress={() => alert('hello')}
          mapType="standard"
          onRegionChangeComplete={region =>
            this.setState({
              latitude: region.latitude,
              longitude: region.longitude,
              latitudeDelta: region.latitudeDelta,
              longitudeDelta: region.longitudeDelta
            })
          }
          showsCompass={true}
          showsBuildings={true}
          showsTraffic={true}
          showsIndoors={true}
        >
          <MapView.Marker coordinate={this.props.context.state.parcelLocLocation.coords} />
        </MapView>

        <ScrollView style={{ padding: 5, flex: 1 }}>

        <TouchableOpacity
            style={styles.entryRow}
            onPress={() => {
              this.additionalinput.focus();
            }}
          >
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <TextInput
                ref={input => {
                  this.additionalinput = input;
                }}
                style={{ height: 20, width: "100%", textAlign: "right" }}
                placeholder={""}
                onFocus={() => {
                  //this.setState({ listViewDisplayed: true });
                }}
                onChangeText={async value => {
                  await this.props.context.setNewParcelValue("parcelLocAdditional", value);
                  //this._updateInputManually();
                }}
                value={this.props.context.state.parcelLocAdditional}
              />
            </View>
          </TouchableOpacity>     

          
          <TouchableOpacity
            style={styles.entryRow}
            onPress={() => {
              this.streetinput.focus();
            }}
          >
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Strasse</Text>
            </View>

            <View style={styles.entryRowDetail}>
              <TextInput
                ref={input => {
                  this.streetinput = input;
                }}
                style={{ height: 20, width: "100%", textAlign: "right" }}
                placeholder={""}
                onFocus={() => {
                  this.setState({ listViewDisplayed: true });
                }}
                onChangeText={async value => {
                  await this.props.context.setNewParcelValue("parcelLocStreet", value);
                  this._updateInputManually();
                }}
                value={this.props.context.state.parcelLocStreet}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.entryRow}
            onPress={() => {
              this.numberinput.focus();
            }}
          >
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Nr.</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <TextInput
                ref={input => {
                  this.numberinput = input;
                }}
                style={{ height: 20, width: "100%", textAlign: "right" }}
                placeholder={""}
                onFocus={() => {
                  this.setState({ listViewDisplayed: true });
                }}
                onChangeText={async value => {
                  await this.props.context.setNewParcelValue("parcelLocNumber", value);
                  this._updateInputManually();
                }}
                value={this.props.context.state.parcelLocNumber}
              />
            </View>
          </TouchableOpacity>
 

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>PLZ</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{this.props.context.state.parcelLocZip}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Ort</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{this.props.context.state.parcelLocCity}</Text>
            </View>
          </View>
        </ScrollView>

        <View style={{}}>
          <Button onPress={this._nextstep} title={"Weiter"} buttonStyle={{ borderRadius: 0 }} disabled={this.props.context.state.parcelLocStreet != "" && this.props.context.state.parcelLocNumber != "" && this.props.context.state.parcelLocZip != "" && this.props.context.state.parcelLocCity != "" && this.state.manualInpulock != true ? false : true} />
        </View>
      </View>
    ) : this.state.preselect ? (
      <Overlay
        isVisible={true}
        onBackdropPress={() => {
          this.setState({ preselect: false });
          this._getLocationAsync();
        }}
      >
        <SelectFromAddressCache storage={"locArrStr"} selectFunction={this} />
      </Overlay>
    ) : (
      <View style={{ flex: 1, paddingTop: 20 }}>
        <ActivityIndicator />
      </View>
    );
  }
  _updateInputManually = () => {
    console.log("this.props.context.state.parcelLocStreet = " + this.props.context.state.parcelLocStreet);
    console.log("this.props.context.state.parcelLocNumber = " + this.props.context.state.parcelLocNumber);
    console.log("this.props.context.state.parcelLocZip = " + this.props.context.state.parcelLocZip);
    console.log("this.props.context.state.parcelLocCity = " + this.props.context.state.parcelLocCity);
    this.setState({ manualInpulock: true });
    var tmpstreet = this.props.context.state.parcelLocStreet;
    if (tmpstreet == undefined) {
      tmpstreet = "";
    }
    var tmpnumber = this.props.context.state.parcelLocNumber;
    if (tmpnumber == undefined) {
      tmpnumber = "";
    }
    var tmp = this.props.context.state.parcelLocZip + " " + this.props.context.state.parcelLocCity + ", " + tmpstreet + " " + tmpnumber + " , Deutschland";
    this.setState({ parcelAddressManually: tmp });
  };
  async UNSAFE_componentWillReceiveProps() {
    console.log("UNSAFE_componentWillReceiveProps");
    // this.componentWillMount();
  }
  componentWillUnmount() {}
  UNSAFE_componentWillMount() {
    this._begin();
  }

  async _begin() {
    console.log("componentWillMount");
    if (Platform.OS === "android" && !Constants.isDevice) {
      this.setState({
        errorMessage: "Oops, this will not work on Sketch in an Android emulator. Try it on your device!"
      });
    } else if (this.props.context.state.parcelLocLocation.coords.latitude == "" || this.props.context.state.parcelLocStreet == "") {
      //await AsyncStorage.removeItem("locArrStr");
      const locArrStr = await AsyncStorage.getItem("locArrStr");
      console.log(JSON.parse(locArrStr));
      if (locArrStr == null) {
        this._getLocationAsync();
      } else {
        Alert.alert(
          "",
          "Möchtest Du aus den letzten Adressen wählen?",
          [
            {
              text: "Nein",
              onPress: () =>
                this.props.context.state.parcelLocLocation.coords.latitude == ""
                  ? this._getLocationAsync()
                  : this.setState({
                      location: this.props.context.state.parcelLocLocation
                    })
            },
            {
              text: "Ja",
              onPress: () => this.setState({ preselect: true })
            }
          ],
          { cancelable: true }
        );
      }
    } else {
      this.setState({ location: this.props.context.state.parcelLocLocation });
    }

    /*var lastinput = await AsyncStorage.getItem("last_parcelLocStreet");
    if (lastinput != null) {
      alert(lastinput);
    }*/
  }

  _getLocationAsync = async () => {
    /*console.log("check freigabe");
    let { status } = await Permissions.askAsync(Permissions.LOCATION);
    if (status !== "granted") {
      this.setState({
        errorMessage: "Um fortzufahren aktiviere bitte die Standortfreigabe."
      });
      console.log("Um fortzufahren aktiviere bitte die Standortfreigabe.");
    }

    let location = await Location.getCurrentPositionAsync();*/
    let location={coords: {
      latitude: 51.427810,
      longitude: 6.883210
      
    }};
    this.props.context.setNewParcelValue("parcelLocLocation", location);
    this.setState({ location });
  };

  _setMapAddress = (addressFields, formatted_address, tmplocation, placeid) => {
    console.log("_setMapAddress = str=" + addressFields.route + " num=" + addressFields.street_number + " zip=" + addressFields.postal_code + " ci=" + addressFields.locality);
    console.log("formatted_address = " + formatted_address);
    this.props.context.setNewParcelValue("parcelLocStreet", addressFields.route);
    this.props.context.setNewParcelValue("parcelLocNumber", addressFields.street_number);
    if (addressFields.postal_code != undefined) {
      this.props.context.setNewParcelValue("parcelLocZip", addressFields.postal_code);
    }
    if (addressFields.postal_code != undefined) {
      this.props.context.setNewParcelValue("parcelLocCity", addressFields.locality);
    }
    this.props.context.setNewParcelValue("parcelLocFormattedAddress", formatted_address);
    this.props.context.setNewParcelValue("parcelLocLocation", tmplocation);
    this.props.context.setNewParcelValue("parcelLocPlaceID", placeid);

    this.setState({
      location: tmplocation,
      manualInpulock: false
    });
  };

  _selectCacheAddress = rowData => {
    console.log(rowData);
    this._setMapAddress(rowData.addressFields, rowData.formaddress, rowData.location, rowData.placeid);
    this.setState({ preselect: false });
  };

  _nextstep = async () => {
    if (this.state.zips.includes(this.props.context.state.parcelLocZip)) {
      //save address locally
      var locItemArr = await this._loadLocArr();
      var newItem = {
        addressFields: {
          route: this.props.context.state.parcelLocStreet,
          street_number: this.props.context.state.parcelLocNumber,
          postal_code: this.props.context.state.parcelLocZip,
          locality: this.props.context.state.parcelLocCity
        },
        formaddress: this.props.context.state.parcelLocFormattedAddress,
        location: this.props.context.state.parcelLocLocation,
        placeid: this.props.context.state.parcelLocPlaceID,
        key: this.props.context.state.parcelLocPlaceID
      };
      var placeid = this.props.context.state.parcelLocPlaceID;
      var dowrite = true;
      locItemArr.forEach(function(el) {
        console.log(el.placeid);
        if (el.placeid == placeid) {
          dowrite = false;
        }
      });
      if (dowrite == true) {
        locItemArr = [newItem, ...locItemArr];
        await AsyncStorage.setItem("locArrStr", JSON.stringify(locItemArr));
        console.log(newItem);
        //console.log(locItemArr);
      }

      this.props.navigation.navigate("AddParcelTimeRetour");
    } else {
      Alert.alert(
        "Ups...",
        "Leider kannst Du im eingegebenen Ort Mister Postman nicht nutzen.",
        [
          {
            text: "OK"
          }
        ],
        { cancelable: false }
      );
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 0,
    marginBottom: 20
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 10,
    paddingTop: 10
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
