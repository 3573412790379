import React from "react";
import { Image, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View, TextInput, Alert, FlatList, ActivityIndicator, RefreshControl } from "react-native";
import { Button } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import Row from "./DebitRow";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class DebitScreen extends React.Component {
  /*static navigationOptions = {
    title: "Empfangen"
  };*/
  // static navigationOptions = { title: "Termos e Condições", headerStyle: { backgroundColor: "piurple" }, headerTintColor: "white", headerTitleStyle: { fontSize: 16, fontWeight: "normal", justifyContent: "center", alignSelf: "center", width: "65%" } };
  constructor(props) {
    super(props);
    this.state = {
      text: "Useless Placeholder",
      username: "",
      password: "",
      isLoading: true,
      isLoading2: true,
      refreshing: true,
      data: [],
      debit: 0,
      debitavail: 0,
      reserved: 0
    };
    //
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.refresh == true) {
      this._refreshListView();
    }
  }

  UNSAFE_componentWillMount() {
    this._refreshListView();
  }

  _refreshListView = () => {
    this.setState({ refreshing: true });

    fetch(apiUrl + "user/debit.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState(
          {
            isLoading: false,
            isLoading2: false,
            refreshing: false,
            data: responseJson[0],
            debit: responseJson[1],
            debitavail: responseJson[2],
            reserved: responseJson[3]
          },
          function() {
            // In this block you can do something with new state.
          }
        );
      })
      .catch(error => {
        console.error(error);
      });

    //  this.setState({ dataSource: this.ds.cloneWithRows([]) });
  };
  ListViewItemSeparator = () => {
    return (
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: "#000"
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1, paddingTop: 20 }}>
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <View style={{ width: "100%", backgroundColor: "grey", flexDirection: "row" }}>
          <View style={{ width: "50%", paddingRight: 5 }}>
            <Text style={{ alignSelf: "center", paddingTop: 20, color: "white", fontWeight: "bold", alignSelf: "flex-end" }}>Kontostand</Text>
            <Text style={{ alignSelf: "center", paddingTop: 10, color: "white", fontWeight: "bold", alignSelf: "flex-end" }}>davon reserviert</Text>
            <Text style={{ alignSelf: "center", paddingTop: 10, paddingBottom: 10, color: "white", fontWeight: "bold", alignSelf: "flex-end" }}>verfügbar</Text>
          </View>
          <View style={{ width: "50%", paddingLeft: 5 }}>
            <Text style={{ alignSelf: "center", paddingTop: 20, color: "white", fontWeight: "bold", alignSelf: "flex-start" }}>{this.state.debit} €</Text>
            <Text style={{ alignSelf: "center", paddingTop: 10, color: "white", fontWeight: "bold", alignSelf: "flex-start" }}>{this.state.reserved} €</Text>
            <Text style={{ alignSelf: "center", paddingTop: 10, paddingBottom: 10, color: "white", fontWeight: "bold", alignSelf: "flex-start" }}>{this.state.debitavail} €</Text>
          </View>
        </View>
        <FlatList ItemSeparatorComponent={this.ListViewItemSeparator} data={this.state.data} style={styles.container} renderItem={({ item }) => <Row {...item} rowData={item} func={this.handleClick} nav={this.props.navigation} p />} refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={() => this._refreshListView()} />} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  listbox: {},

  container: {
    flex: 1,
    backgroundColor: "#fff"
  }
});
