import React from "react";
import { Image, Platform, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, TextInput, Alert, Keyboard, KeyboardAvoidingView } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Input, Button, Text, Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class ReminderScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      loaderIsVisible: false
    };
  }
  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  async UNSAFE_componentWillMount() {}

  render() {
    return (
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
          <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : null}>
            <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)">
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
              </View>
            </Overlay>

            <View style={styles.containerTop}>
              <View style={styles.welcomeContainer}>
                <Image source={require("../../assets/images/mrpostman.jpg")} style={{ width: 191, height: 100 }} />
              </View>

              <Input
                inputContainerStyle={{ padding: 0, borderColor: "#ccc", borderWidth: 1, borderRadius: 0, margin: 10, width: 250, alignSelf: "center" }}
                onChangeText={username => {
                  this.setState({ username: username });
                }}
                onBlur={() => {
                  this.setState({ username: this.state.username.trim() });
                }}
                value={this.state.username}
                placeholder="Benutzername"
                autoComplete="off"
                autoCorrect={false}
                autoCapitalize="none"
                leftIconContainerStyle={{ paddingRight: 10 }}
                leftIcon={<Icon name="user" size={24} color="grey" />}
              />

              <Input
                inputContainerStyle={{ padding: 0, borderColor: "#ccc", borderWidth: 1, borderRadius: 0, margin: 10, width: 250, alignSelf: "center" }}
                onChangeText={email => this.setState({ email })}
                value={this.state.email}
                onBlur={() => {
                  this.setState({ email: this.state.email.trim() });
                }}
                placeholder="Email-Adresse"
                leftIconContainerStyle={{ paddingRight: 10 }}
                leftIcon={<Icon name="envelope" size={24} color="grey" />}
              />

              <Button onPress={this._login} title={"Passwort zurücksetzen"}  buttonStyle={{ borderRadius: 0, width: 250 }} containerStyle={{ width: 250, alignSelf: "center", marginTop: 30 }} disabled={this._checkAllInputs()} />
            </View>
          </KeyboardAvoidingView>
        </TouchableWithoutFeedback>
      </View>
    );
  }

  _fake = () => {
    Alert.alert("Huch", "Diese Funktion muss noch programmiert werden!", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
  };

  _checkAllInputs = () => {
    const { email, username } = this.state;

    if (this._validateEmail(email) == true && username.length > 1) {
      return false;
    } else {
      return true;
    }
  };

  _validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  _login = async () => {
    const { username, email } = this.state;
    this.setState({ loaderIsVisible: true });

    //Alert.alert('Credentials', `${username} + ${password}`);

    /*Alert.alert(
          'Alert Title',
          this.state.text,
          [
            {text: 'Ask me later', onPress: () => console.log('Ask me later pressed')},
            {text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel'},
            {text: 'OK', onPress: () => console.log('OK Pressed')},
          ],
          { cancelable: false }
        );*/

    fetch(apiUrl + "user/resetpassword.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: username,
        email: email
      })
    }) /*.then(function(response) {
      console.log(response);
    });*/
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {
          //alert(responseJson.realname);

          /*await AsyncStorage.setItem("userToken", "abc");
          await AsyncStorage.setItem("userRealname", responseJson.realname);
          global.userRealname = responseJson.realname;
          await AsyncStorage.setItem("userID", responseJson.id);
          global.userID = responseJson.id;
          await AsyncStorage.setItem("userName", responseJson.username);
          this.props.navigation.navigate("App");*/

          Alert.alert(
            "Super",
            "Bitte siehe in Deinen Emails nach und gib Dein neues Passwort auf der nächsten Seite ein.",
            [
              {
                text: "OK",
                onPress: () => {
                  this.setState({ loaderIsVisible: false });
                  this.props.navigation.navigate("NewPassword");
                }
              }
            ],
            { cancelable: false }
          );
        } else {
          // await alert("Login fehlgeschlagen.");

          Alert.alert("Ups...", "Benutzername und/oder Email konnten nicht gefunden werden.", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });

          //this.setState({ loaderIsVisible: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center"
  },
  containerTop: {
    flex: 1,
    justifyContent: "center"
  },
  contentContainer: {
    paddingTop: 30
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  },
  welcomeImage: {
    width: 200,
    height: 100,
    resizeMode: "contain",
    marginTop: 3,
    marginLeft: -10
  }
});
