import React from "react";
import { FlatList, StyleSheet, View, ActivityIndicator, RefreshControl } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Row from "./SelectFromAddressCacheRow";

export default class SelectFromAddressCache extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "Useless Placeholder",
      username: "",
      password: "",
      isLoading: true,
      isLoading2: true,
      refreshing: true,
      data: []
    };
    //
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.refresh == true) {
      this._refreshListView();
    }
  }

  UNSAFE_componentWillMount() {
    this._refreshListView();
  }

  _refreshListView = async () => {
    const { storage } = this.props;

    this.setState({ refreshing: true });

    const locArrStr = await AsyncStorage.getItem(storage);
    if (locArrStr == null) {
      console.log("leer");
      locArr = [];
    } else {
      console.log("nihct leer");
      locArr = JSON.parse(locArrStr);
    }
    this.setState({
      isLoading: false,
      isLoading2: false,
      refreshing: false,
      data: locArr
    });
  };
  ListViewItemSeparator = () => {
    return (
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: "#000"
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1, paddingTop: 20 }}>
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <FlatList style={styles.container} ItemSeparatorComponent={this.ListViewItemSeparator} data={this.state.data} renderItem={({ item }) => <Row {...item} rowData={item} selectFunction={this.props.selectFunction} />} refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={() => this._refreshListView()} />} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  listbox: {},

  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  developmentModeText: {
    marginBottom: 20,
    color: "rgba(0,0,0,0.4)",
    fontSize: 14,
    lineHeight: 19,
    textAlign: "center"
  },
  contentContainer: {
    paddingTop: 0
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: "contain",
    marginTop: 3,
    marginLeft: -10
  },
  getStartedContainer: {
    alignItems: "center",
    marginHorizontal: 50
  },
  homeScreenFilename: {
    marginVertical: 7
  },
  codeHighlightText: {
    color: "rgba(96,100,109, 0.8)"
  },
  codeHighlightContainer: {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 3,
    paddingHorizontal: 4
  },
  getStartedText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    lineHeight: 24,
    textAlign: "center"
  },

  tabBarInfoText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    textAlign: "center"
  },
  navigationFilename: {
    marginTop: 5
  },
  helpContainer: {
    marginTop: 15,
    alignItems: "center"
  },
  helpLink: {
    paddingVertical: 15
  },
  helpLinkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
});
