import React from "react";
import { View, Text, StyleSheet, Image, TouchableHighlight, TouchableOpacity, TouchableNativeFeedback, Platform } from "react-native";
import LocationType from "../helper/LocationType";
import ProviderLogo from "../helper/ProviderLogo";
import Icon from "react-native-vector-icons/FontAwesome";
import Collapsible from "react-native-collapsible";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 7,
    flexDirection: "row",
    alignItems: "center"
  },
  innercontainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    paddingRight: 30,

  },
  text: {
    marginLeft: 12,
    fontSize: 15,
    flexWrap: "wrap"
  },
  photo: {
    height: 40,
    width: 40,
    borderRadius: 20
  },
  entryrow: {
    backgroundColor: "#99cc00",
    flexDirection: "row",
    flex: -1
  },
  shippingicon: {
    height: 61,
    width: 85.33
  }
});

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {},
      collapse: true
    };
  }
  render() {
    const { service2, service,provider, location_type,loc_info,location_street, location_no, location_zip, location_city, parcel_id, distanceText, durationText, profit, currency, dest_info, dest_street, dest_no, dest_zip, dest_city } = this.props;
    const TouchableItem = Platform.OS === "ios" ? TouchableOpacity : TouchableNativeFeedback;
    return (
      <View style={styles.container}>
        <TouchableItem onPress={()=>{this._selectrow(service);}} style={styles.innercontainer}>
        
          <View style={styles.innercontainer}>
            <ProviderLogo provider={provider} size={"medium"} />
            
            <View style={{ flexDirection: "column", flex: 1 }}>
              
              <View style={{ flexDirection: "column", flex: 1, paddingTop: 4 }}>
              
              
              <View style={[{ display: service == "courier" ? "flex" : "none" }]}>
                    <Text style={[styles.text, { marginBottom: 5, display: dest_info != "" ? "flex" : "none" }]}>
                      {distanceText}, {durationText}
                    </Text>

                    <Text style={[styles.text, { marginBottom: 5, display: loc_info == "" ? "flex" : "none" }]}>
                      von: {location_city}
                    </Text>
                    <Text style={[styles.text, { marginBottom: 5, display: loc_info == "" ? "flex" : "none" }]}>
                      nach: {dest_city}
                    </Text>
                </View>

                <View style={[{ display: service == "delivery" ? "flex" : "none" }]}>
                    <Text style={[styles.text, { marginBottom: 5, display: dest_info != "" ? "flex" : "none" }]}>
                      {dest_info}: {distanceText}, {durationText}
                    </Text>

                    <Text style={[styles.text, { marginBottom: 5, display: dest_info == "" ? "flex" : "none" }]}>
                      {dest_street} {dest_no}, {dest_zip} {dest_city}: {distanceText}, {durationText}
                    </Text>
                </View>

                <View style={[{ display: service == "return" ? "flex" : "none" }]}>

                    <Text style={[styles.text, { marginBottom: 5, display: loc_info == "" ? "flex" : "none" }]}>
                    {`${location_street} ${location_no}, ${location_zip} ${location_city}`}
                    </Text>
                </View>

                <Text style={styles.text}>
                  Verdienst: {profit} {currency}
                </Text>
                
              </View>
              
              <Collapsible collapsed={this.state.collapse} style={{ width: "100%", paddingTop: 15 }}>
                <Text style={[styles.text,{display: service == "delivery" ? "flex" : "none"}]}>
                  <LocationType value={location_type} />
                  {`: ${location_street} ${location_no}, ${location_zip} ${location_city}`}
                </Text>



                <Text style={[styles.text,{display: service == "courier" && service2=='bring' && dest_info=='' ? "flex" : "none"}]}>
                    von: {dest_street} {dest_no}, {dest_zip} {dest_city} 
                </Text>
                <Text style={[styles.text,{display: service == "courier" && service2=='bring' && dest_info!='' ? "flex" : "none"}]}>
                    von: {dest_info}
                </Text>


                <Text style={[styles.text,{display: service == "courier" && service2=='pickup' && dest_info =='' ? "flex" : "none"}]}>
                    von: {dest_street} {dest_no}, {dest_zip} {dest_city} 
                </Text>
                <Text style={[styles.text,{display: service == "courier" && service2=='pickup' && dest_info !='' ? "flex" : "none"}]}>
                    von: {dest_info}
                </Text>




                <Text style={[styles.text,{display: service == "courier" && service2=='bring'  && loc_info =='' ? "flex" : "none"}]}>
                {`nach: ${location_street} ${location_no}, ${location_zip} ${location_city}`}
                </Text>
                <Text style={[styles.text,{display: service == "courier" && service2=='bring'  && loc_info !='' ? "flex" : "none"}]}>
                {`nach: ${loc_info}`}
                </Text>

                <Text style={[styles.text,{display: service == "courier" && service2=='pickup'  && loc_info =='' ? "flex" : "none"}]}>
                {`nach: ${location_street} ${location_no}, ${location_zip} ${location_city}`}
                </Text>
                <Text style={[styles.text,{display: service == "courier" && service2=='pickup'  && loc_info !='' ? "flex" : "none"}]}>
                {`nach: ${loc_info}`}
                </Text>


              </Collapsible>
              
            </View>
            

            
          </View>
        
        </TouchableItem>


        <View style={{textAlign:"center",alignSelf: "flex-start",position:'absolute',right:9,top:12,backgroundColor:service == "return" ? "#1e90ff" : service == "delivery" ? "#fec600" : service2 =="bring" ? "#99cc00":"#f86c05",borderRadius:5,padding:2}}>
    <Text style={[styles.text],{marginRight:2,fontSize:10,color:service == "return" ? "white" :"black"}}> {service == "return" ? "Abholung" : service =="delivery" ? "Lieferung" :service2 == "bring" ? "Kurier (L)":"Kurier (R)"}</Text>
        </View>



        <View style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 20, paddingBottom: 0, alignSelf: "flex-end",display: service == "return" ? "none" : "flex"}}>
        <TouchableItem  onPress={this._collapseDetails}>
          <Icon name={this.state.collapse ? "angle-double-down" : "angle-double-up"} size={24} color="grey" />
        </TouchableItem>
        </View>
       
      </View>
    );
  }
  _collapseDetails = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  _selectrow = (serv) => {
    if(serv == "delivery"){
      this.props.nav.navigate("DeliveryParcelDetail", { itemdata: this.props.rowData });
    }else if(serv == "courier"){
      this.props.nav.navigate("CourierDetail", { itemdata: this.props.rowData });
    }else{
      this.props.nav.navigate("DeliveryParcelDetailRetour", { itemdata: this.props.rowData });
    }
    
    // this.props.func("wewew");
  };
  _rdicon = () => {
    var min = 0;
    var max = 7;
    var RandomNumber = Math.floor(Math.random() * (max - min)) + min;
    var ico = this.state.compIcons[RandomNumber];
    return { rd: RandomNumber, ico: ico };
  };
}
