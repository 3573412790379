import React from "react";
import { Image, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View, TextInput, Alert, FlatList, ActivityIndicator, RefreshControl, KeyboardAvoidingView } from "react-native";
import { Button, ButtonGroup, Overlay, Input } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import Row from "./DebitRow";
import { Header } from "react-navigation";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class PayoutScreen extends React.Component {
  /*static navigationOptions = {
    title: "Empfangen"
  };*/
  // static navigationOptions = { title: "Termos e Condições", headerStyle: { backgroundColor: "piurple" }, headerTintColor: "white", headerTitleStyle: { fontSize: 16, fontWeight: "normal", justifyContent: "center", alignSelf: "center", width: "65%" } };
  constructor(props) {
    super(props);
    this.state = {
      loaderIsVisible: false,
      username: "",
      password: "",
      isLoading: true,
      refreshing: true,
      data: [],
      debit: 0,
      debitOwner: "",
      debitIBAN: "",
      debitBIC: "",
      debit4chars: "",
      selectedIndex: 0,
      payoutvalue: "0",
      debitrest: "0",
      debitavail: "0",
      payoutok: true,
      payoutminok: true
    };
    this.updateIndex = this.updateIndex.bind(this);
  }
  updateIndex(selectedIndex) {
    this.setState({ selectedIndex });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.state.params.refresh == true) {
      this._getsepa();
    }
  }

  UNSAFE_componentWillMount() {
    this._getsepa();
  }

  _getsepa = () => {
    this.setState({ refreshing: true });

    fetch(apiUrl + "user/sepa.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        show: true
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState(
          {
            isLoading: false,
            refreshing: false,
            debitOwner: responseJson[0],
            debitIBAN: responseJson[1],
            debitBIC: responseJson[2]
          },
          function() {
            this._getdebit();
          }
        );
      })
      .catch(error => {
        console.error(error);
      });
  };
  _getdebit = () => {
    this.setState({ refreshing: true });

    fetch(apiUrl + "user/debit.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState(
          {
            isLoading: false,
            loaderIsVisible: false,
            refreshing: false,
            data: responseJson[0],
            debit: responseJson[1],
            debitavailNumber: responseJson[2],
            debitavail: responseJson[2].toString(),
            payoutvalue: responseJson[2].toString(),
            reserved: responseJson[3]
          },
          function() {}
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  _paycheck = async () => {
    const { username, password } = this.state;
    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "user/sepa.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        debitOwner: this.state.debitOwner,
        debitIBAN: this.state.debitIBAN,
        debitBIC: this.state.debitBIC,
        debit4chars: this.state.debit4chars
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {
          Alert.alert("Super!", "Du hast Deine Bankverbindung geändert.", [
            {
              text: "OK",

              onPress: () => {
                this.setState({ loaderIsVisible: false });
              }
            }
          ]);
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "wrongiban") {
            errmess = "Ungültige IBAN.";
          }
          if (responseJson.code == "onlygermany") {
            errmess = "Nur deutsche Konten können verwendet werden.";
          }
          if (responseJson.code == "checkfailed") {
            errmess = "Die letzten 4 Ziffern sind leider falsch. Du hast max. 4 Versuche! ";
          }
          if (responseJson.code == "within2days") {
            errmess = "Die letzte Änderung bzw. das erstmalige Eingeben der Kontoverbindung muss mind. 2 Tage her sein um Änderungen vorzunehmen.";
          }
          if (responseJson.code == "checkblocked") {
            errmess = "Die letzten 4 Ziffern sind wiederholt falsch. Die Änderung der Kontoverbindung ist aus Sicherheitsgründen gesperrt. Bitte wenden Sie sich an den Support.";
          }

          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert("Ups...", "Da hat was nicht geklappt.", [{ text: "OK", onPress: () => {} }], { cancelable: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  _changepayout = val => {
    val = val.replace(" ", "");
    if (val == "") {
      // val = "0";
    }
    var dovalcheck = 0;
    var deciarr = val.replace(",", ".").split(".");
    var debitavailarr = this.state.debitavailNumber.toString().split(".");

    if (/^[0-9,.]*$/.test(val) && (deciarr.length == 1 || (deciarr.length == 2 && deciarr[1].length <= 2))) {
      var newval = parseFloat(val.replace(",", "."));
      var tmpval = this.fpArithmetic("-", this.state.debitavailNumber, parseFloat(newval));

      if (tmpval >= 0) {
        this.setState({ debitrest: tmpval.toString().replace(".", ",") });
      }
      this.setState({ payoutvalue: val.replace(".", ",") });

      dovalcheck = newval;
    } else if (/^[0-9,.]*$/.test(this.state.payoutvalue) && (debitavailarr.length == 1 || (debitavailarr.length == 2 && debitavailarr[1].length <= 2))) {
      dovalcheck = this.state.payoutvalue;
    } else {
      this.setState({ payoutok: false, payoutminok: true });
    }

    if (dovalcheck != 0) {
      if (dovalcheck <= this.state.debitavailNumber && dovalcheck >= 10) {
        this.setState({ payoutok: true, payoutminok: true });
      } else if (dovalcheck <= this.state.debitavailNumber && dovalcheck < 10) {
        this.setState({ payoutok: false, payoutminok: false });
      } else {
        this.setState({ payoutok: false, payoutminok: true });
      }
    }
  };
  fpArithmetic = function(op, x, y) {
    var n = {
      "*": x * y,
      "-": x - y,
      "+": x + y,
      "/": x / y
    }[op];

    return Math.round(n * 100) / 100;
  };

  _confirmpayout = () => {
    Alert.alert(
      "Betrag auszahlen",
      "Möchtest Du Dir den Betrag in Höhe von " + this.state.payoutvalue.replace(".", ",") + "€ auszahlen lassen?",
      [
        {
          text: "Ja",
          onPress: () => this._dopayout()
        },
        {
          text: "Nein"
        }
      ],
      { cancelable: true }
    );
  };
  _dopayout = async () => {
    const { username, password } = this.state;
    this.setState({ loaderIsVisible: true });

    fetch(apiUrl + "user/payout.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        amount: this.state.payoutvalue
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {
          Alert.alert("Super!", "Die Auszahlung ist ab jetzt in Bearbeitung.", [
            {
              text: "OK",
              onPress: () => {
                this.setState({ loaderIsVisible: false }, () => {
                  this._getdebit();
                });
              }
            }
          ]);
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "nocredit") {
            errmess = "Die Auszahlung kann nicht veranlasst werden. Bitte wende Dich an unseren Support.";
          }
          if (responseJson.code == "nosepa") {
            errmess = "Bitte trage erst Deine Kontoverbindung ein um eine Auszahlung zu veranlassen.";
          }
          if (responseJson.code == "within2days") {
            errmess = "Die letzte Änderung bzw. das erstmalige Eingeben der Kontoverbindung muss aus Sicherheitsgründen mind. 2 Tage her sein um eine Auszahlung zu veranlassen.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert("Ups...", "Da hat was nicht geklappt.", [{ text: "OK", onPress: () => {} }], { cancelable: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  _focusNextField(nextField) {
    this.refs[nextField].focus();
  }
  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1, paddingTop: 20 }}>
          <ActivityIndicator />
        </View>
      );
    }
    const buttons = ["Auszahlung", "Kontoverbindung"];
    const { selectedIndex } = this.state;
    return (
      <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={Platform.OS === "ios" ? Header.HEIGHT : Header.HEIGHT + 25}  behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <ScrollView>
          <View style={[styles.container]}>
            <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
              </View>
            </Overlay>
            <View style={{ width: "100%", backgroundColor: "grey" }}>
              <Text style={{ alignSelf: "center", padding: 20, color: "white", fontWeight: "bold" }}>verfügbarer Betrag {this.state.debitavail.replace(".", ",")} €</Text>
            </View>
            <ButtonGroup onPress={this.updateIndex} selectedIndex={selectedIndex} buttons={buttons} containerStyle={{ height: 30, marginTop: 20, marginBottom: 20 }} />
            <View style={([styles.container], { display: this.state.selectedIndex == 1 ? "flex" : "none" })}>
              <View style={{ paddingTop: 20 }}>
                <Text>Möchtest Du Dein Konto für die Auszahlung ändern, musst Du die letzten vier IBAN-Ziffern des Bankkontos eingeben, auf das Du zuletzt eine erfolgreiche SEPA-Auszahlung veranlasst hast.Diese Ziffern kann niemand aus Deinem MisterPostman-Konto auslesen, der sich unerlaubt Zugang zu Deinen Logindaten verschafft hat. Denn sie sind in Deinen Daten ge-xxx-t.</Text>
              </View>
              <View style={{ paddingTop: 20 }}>
                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  containerStyle={{ marginTop: 20 }}
                  onChangeText={value => this.setState({ debitOwner: value })}
                  onBlur={() => {
                    if(this.state.debitOwner != undefined){
                    this.setState({ debitOwner: this.state.debitOwner.trim() });
                    }
                  }}
                  value={this.state.debitOwner}
                  label="Kontoinhaber"
                  labelStyle={{ marginTop: 5, fontSize: 15 }}
                  autoComplete="off"
                  autoCorrect={false}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("2")}
                  ref="1"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  containerStyle={{ marginTop: 20 }}
                  onChangeText={value => this.setState({ debitIBAN: value })}
                  onBlur={() => {
                    if(this.state.debitIBAN != undefined){
                    this.setState({ debitIBAN: this.state.debitIBAN.trim() });
                    }
                  }}
                  value={this.state.debitIBAN}
                  label="IBAN"
                  labelStyle={{ marginTop: 5, fontSize: 15 }}
                  autoComplete="off"
                  autoCorrect={false}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("3")}
                  ref="2"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 5,
                    paddingLeft: 10
                  }}
                  containerStyle={{ marginTop: 20 }}
                  onChangeText={value => this.setState({ debitBIC: value })}
                  onBlur={() => {
                    if(this.state.debitBIC != undefined){
                    this.setState({ debitBIC: this.state.debitBIC.trim() });
                    }
                  }}
                  value={this.state.debitBIC}
                  label="BIC"
                  labelStyle={{ marginTop: 5, fontSize: 15 }}
                  autoComplete="off"
                  autoCorrect={false}
                  returnKeyType="next"
                  onSubmitEditing={() => this._focusNextField("4")}
                  ref="3"
                />

                <Input
                  inputContainerStyle={{
                    padding: 0,
                    borderColor: "#ccc",
                    borderWidth: 1,
                    borderRadius: 0,
                    marginBottom: 15,
                    paddingLeft: 10
                  }}
                  containerStyle={{ marginTop: 20 }}
                  onChangeText={value => this.setState({ debit4chars: value })}
                  onBlur={() => {
                    this.setState({ debit4chars: this.state.debit4chars.trim() });
                  }}
                  value={this.state.debit4chars}
                  label="Bitte gib die 4 letzten IBAN-Ziffern Deiner letzten erfolgreichen SEPA-Auszahlung ein."
                  labelStyle={{ marginTop: 5, fontSize: 15 }}
                  autoComplete="off"
                  autoCorrect={false}
                  returnKeyType="next"
                  ref="4"
                />

                <Button onPress={this._paycheck} title={"Speichern"} accessibilityLabel="" buttonStyle={{ borderRadius: 0 }} />
              </View>
            </View>
            <View style={([styles.container], { display: this.state.selectedIndex == 0 ? "flex" : "none" })}>
              <View style={{ paddingTop: 20 }}>
                <Text>Die Auszahlung ist nach Änderung oder erstmaligem Eingaben Deiner Kontoverbindung aus Sicherheitsgründen für 2 Werktage nicht möglich. Die Auszahlung ist Gebührenfrei.</Text>
                <Text>Der Gutschein kann nicht ausgezahlt werden. Die Restsumme eines Gutscheins wird bei einer Auszahlung in Abzug gebracht.</Text>
                <Text>Du möchtest Dir dein Guthaben auszahlen lassen, aber der Auszahlungsbetrag liegt unter unter 10 €. Kein Problem! Kontaktiere uns einfach unter support@mister-postman.net</Text>

                <View style={{ paddingTop: 20 }}>
                  <View style={styles.payoutrow}>
                    <Text style={styles.payoutcoltit}>verfügbarer Betrag</Text>
                    <Text style={styles.payoutcolval}>{this.state.debitavail.replace(".", ",")} €</Text>
                  </View>
                  <View style={styles.payoutrow}>
                    <Text style={styles.payoutcoltit}>Auszahlungsbetrag</Text>
                    <View style={([styles.payoutcolval], { flex: 1, flexDirection: "row", justifyContent: "flex-end" })}>
                      <TextInput style={{ textAlign: "right", fontSize: 20, width: 90, maxWidth: 90, height: 40, padding: 10, borderWidth: 1, borderColor: "grey", alignContent: "flex-end" }} placeholder={""} onChangeText={value => this._changepayout(value)} value={this.state.payoutvalue.replace(".", ",")} />
                      <View style={{ justifyContent: "center", paddingLeft: 5 }}>
                        <Text style={{ fontSize: 20 }}>€</Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.payoutrow, { minHeight: 40 }]}>
                    <Text style={styles.payoutcoltit}></Text>
                    <View style={([styles.payoutcolval], { flex: 1, display: this.state.payoutok == false && this.state.payoutminok == true ? "flex" : "none" })}>
                      <Text style={{ textAlign: "right", color: "red" }}>Gebe bitte einen gültigen Betrag ein **,**</Text>
                    </View>
                    <View style={([styles.payoutcolval], { flex: 1, display: this.state.payoutok == false && this.state.payoutminok == false ? "flex" : "none" })}>
                      <Text style={{ textAlign: "right", color: "red" }}>Gebe bitte einen Betrag von mindestes 10,00€ ein.</Text>
                    </View>
                  </View>
                  <View style={({}, styles.payoutrow)}>
                    <Text style={styles.payoutcoltit}>Neuer Kontostand</Text>
                    <Text style={styles.payoutcolval}>{this.state.debitrest} €</Text>
                  </View>
                </View>

                <Button onPress={this._confirmpayout} title={"Bestätigen"} accessibilityLabel="" disabled={!this.state.payoutok} buttonStyle={{ borderRadius: 0, marginTop: 30 }} />
              </View>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create({
  listbox: {},
  containerTop: {
    flex: 1,
    justifyContent: "center"
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 10
  },
  payoutrow: { flexDirection: "row", marginTop: 20 },
  payoutcoltit: { width: "50%" },
  payoutcolval: { alignItems: "flex-end", width: "50%", textAlign: "right" }
});
