const CON = {
  configVars: {
    apiUrl: "https://app.mister-postman.net/" //"https://appdev.mister-postman.net/" //"https://app.mister-postman.net/" //https://apptest.mister-postman.net/
  }
};

const getConfVars = () => {
  return CON.configVars;
};

export default getConfVars;
