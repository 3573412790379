import React from "react";
import { StyleSheet, View, Picker } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";

export default class AddParcelWeightRetourScreen extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      type: "station"
    };
  }
  UNSAFE_componentWillMount() {
    this._updNewValue();
  }
  componentDidUpdate(prevProps, prevState) {
    this._updNewValue();
  }
  _updNewValue = async () => {
    try {
      await AsyncStorage.setItem("newparcelWeight", this.state.type);
    } catch (error) {
      // Error saving data
    }
  };

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <View style={styles.container}>
            <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
              <HeaderImage image="size" />
            </View>
            <View style={{ padding: 5, justifyContent: "center", flex: 1 }}>
              <Picker
                selectedValue={context.state.parcelWeight}
                style={{
                  width: "100%",
                  height: 200
                }}
                itemStyle={{ height: 200 }}
                onValueChange={(itemValue, itemIndex) => context.setNewParcelValue("parcelWeight", itemValue)}
              >
                <Picker.Item label="0-3 KG" value="0-3" />
                <Picker.Item label="3-6 KG" value="3-6" />
                <Picker.Item label="6-9 KG" value="6-9" />
                <Picker.Item label="9-12 KG" value="9-12" />
              </Picker>
            </View>

            <View style={{ padding: 5, justifyContent: "flex-end", flex: 1 }}>
              <Button
                onPress={() => {
                  this.props.navigation.navigate("AddParcelLocationRetour");
                }}
                title={"Weiter"}
                buttonStyle={{ borderRadius: 0 }}
              />
            </View>
          </View>
        )}
      </MyContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  }
});
