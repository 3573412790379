import React from "react";
import { StyleSheet, View, Picker } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";

export default class AddParcelTypeScreen extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      type: "station"
    };
  }
  UNSAFE_componentWillMount() {
    this._updNewValue();
  }
  componentDidUpdate(prevProps, prevState) {
    this._updNewValue();
  }
  _updNewValue = async () => {
    try {
      await AsyncStorage.setItem("newParcelType", this.state.type);
    } catch (error) {
      // Error saving data
    }
  };

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <View style={styles.container}>
            <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
              <HeaderImage image="standort" />
            </View>
            <View style={{ padding: 5, justifyContent: "center", flex: 1 }}>
              <Picker
                selectedValue={context.state.parcelType}
                style={{
                  width: "100%",
                  height: 200
                }}
                itemStyle={{ height: 200 }}
                onValueChange={(itemValue, itemIndex) => context.setNewParcelValue("parcelType", itemValue)}
              >
                <Picker.Item label="Filiale" value="2" />
                <Picker.Item label="Packstation" value="1" />
              </Picker>
            </View>

            <View style={{ padding: 5, justifyContent: "flex-end", flex: 1 }}>
              <Button onPress={() => this._nextstep(context.state.parcelType)} title={"Weiter"} buttonStyle={{ borderRadius: 0 }} />
            </View>
          </View>
        )}
      </MyContext.Consumer>
    );
  }

  _nextstep = value => {
    //alert(this.state.step);
    //this.setState({ step: this.state.step + 1 });
    if (value == 1) {
      this.props.navigation.navigate("AddParcelCode");
    } else {
      this.props.navigation.navigate("AddParcelLocation");
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  }
});
