import React from "react";
import { Image, StyleSheet, View, Picker } from "react-native";
import { Button, Text } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";

class ParcelDataHandler extends React.Component {
  _nextstep = () => {
    //alert(this.state.step);
    //this.setState({ step: this.state.step + 1 });
    // this.props.context.setNewParcelValue("parcelProvider", "dsdsds");

    this.props.navigation.navigate("AddParcelNumberRetour");
  };
  UNSAFE_componentWillMount() {
    if (this.props.editParcel == true) {
      // alert("inner editParcel");
      this.props.context.setNewParcelValue("parcelProvider", this.props.parcelItem["provider"]);
      this.props.context.setNewParcelValue("parcelNumbers", this.props.parcelItem["parcel_id"].split(","));
      this.props.context.setNewParcelValue("parcelType", this.props.parcelItem["location_type"]);
      this.props.context.setNewParcelValue("parcelWeight", this.props.parcelItem["weight"]);
      this.props.context.setNewParcelValue("parcelCode", this.props.parcelItem["parcel_pin"]);
      this.props.context.setNewParcelValue("parcelCustomerID", this.props.parcelItem["parcel_customerid"]);
      this.props.context.setNewParcelValue("parcelLocStreet", this.props.parcelItem["location_street"]);
      this.props.context.setNewParcelValue("parcelLocNumber", this.props.parcelItem["location_no"]);
      this.props.context.setNewParcelValue("parcelLocAdditional", this.props.parcelItem["location_additional"]);
      this.props.context.setNewParcelValue("parcelLocZip", this.props.parcelItem["location_zip"]);
      this.props.context.setNewParcelValue("parcelLocCity", this.props.parcelItem["location_city"]);

      var tmploc_formaddress = this.props.parcelItem["location_street"] + " " + this.props.parcelItem["location_no"] + ", " + this.props.parcelItem["location_zip"] + " " + this.props.parcelItem["location_city"] + " Deutschland";
      this.props.context.setNewParcelValue("parcelLocFormattedAddress", tmploc_formaddress);

      this.props.context.setNewParcelValue("parcelLocPlaceID", this.props.parcelItem["location_placeid"]);

      this.props.context.setNewParcelValue("parcelLocLocation", {
        coords: {
          latitude: Number.parseFloat(this.props.parcelItem["location_lat"]),
          longitude: Number.parseFloat(this.props.parcelItem["location_lng"])
        }
      });
      this.props.context.setNewParcelValue("parcelDestStreet", this.props.parcelItem["dest_street"]);
      this.props.context.setNewParcelValue("parcelDestNumber", this.props.parcelItem["dest_no"]);
      this.props.context.setNewParcelValue("parcelDestAdditional", this.props.parcelItem["dest_additional"]);
      this.props.context.setNewParcelValue("parcelDestZip", this.props.parcelItem["dest_zip"]);
      this.props.context.setNewParcelValue("parcelDestCity", this.props.parcelItem["dest_city"]);

      var tmpdest_formaddress = this.props.parcelItem["dest_street"] + " " + this.props.parcelItem["dest_no"] + ", " + this.props.parcelItem["dest_zip"] + " " + this.props.parcelItem["dest_city"] + " Deutschland";
      this.props.context.setNewParcelValue("parcelDestFormattedAddress", tmpdest_formaddress);

      this.props.context.setNewParcelValue("parcelDestPlaceID", this.props.parcelItem["dest_placeid"]);
      this.props.context.setNewParcelValue("parcelDestLocation", {
        coords: {
          latitude: Number.parseFloat(this.props.parcelItem["dest_lat"]),
          longitude: Number.parseFloat(this.props.parcelItem["dest_lng"])
        }
      });
      /*
      convert pickuptime
      */
      var t = this.props.parcelItem["time_from_pickup"].split(/[- :]/);
      var tmpFrom = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
      t = this.props.parcelItem["time_to_pickup"].split(/[- :]/);
      var tmpTo = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

      this.props.context.setNewParcelValue("parcelDatePickupFrom", tmpFrom);
      this.props.context.setNewParcelValue("parcelDatePickupTo", tmpTo);

      this.props.context.setNewParcelValue("parcelEditID", this.props.parcelItem["id"]);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //  alert(nextProps.navigation.state.params.parcelItem["provider"]);
    // if (nextProps.navigation.state.params.editParcel == true) {
    //context.setNewParcelValue("parcelProvider", nextProps.navigation.state.params.parcelItem["provider"]);
    //}
  }

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <View style={styles.container}>
            <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
              <HeaderImage image="provider" />
            </View>
            <View style={{ padding: 5, justifyContent: "center", flex: 1 }}>
              <Picker
                selectedValue={context.state.parcelProvider}
                style={{
                  width: "100%",
                  height: 200
                }}
                itemStyle={{ height: 200 }}
                onValueChange={(itemValue, itemIndex) => context.setNewParcelValue("parcelProvider", itemValue)}
              >
 
                <Picker.Item label="DPD" value="dpd" />
                <Picker.Item label="DHL" value="dhl" />
                <Picker.Item label="GLS" value="gls" />
                <Picker.Item label="Hermes" value="hermes" />
                <Picker.Item label="UPS" value="ups" />
              </Picker>
            </View>

            <View style={{ padding: 5, justifyContent: "flex-end", flex: 1 }}>
              <Button onPress={this._nextstep} title={"Weiter"} buttonStyle={{ borderRadius: 0 }} />
            </View>
          </View>
        )}
      </MyContext.Consumer>
    );
  }
}

export default class AddParcelProviderRetourScreen extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      //provider: "dpd",
      number: "",
      contextProvider: ""
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // alert("UNSAFE_componentWillReceiveProps outer");
  }

  UNSAFE_componentWillMount(nextProps) {
    // alert("UNSAFE_componentWillMount outer");

    this._updNewValue();
  }
  componentDidUpdate(prevProps, prevState) {
    this._updNewValue();
  }
  _updNewValue = async () => {
    try {
      //await AsyncStorage.setItem("newParcelProvider", this.state.provider);
    } catch (error) {
      // Error saving data
    }
  };

  render() {
    if (this.props.navigation.state.params != undefined && this.props.navigation.state.params.editParcel == true) {
      var parcelItem = this.props.navigation.state.params.parcelItem;
      var editParcel = true;
    } else {
      var parcelItem = [];
      var editParcel = false;
    }
    return <MyContext.Consumer>{context => <ParcelDataHandler parcelItem={parcelItem} editParcel={editParcel} context={context} navigation={this.props.navigation} />}</MyContext.Consumer>;
  }

  _nextstep = () => {
    //alert(this.state.step);
    //this.setState({ step: this.state.step + 1 });
    //this.props.context.setNewParcelValue("parcelProvider", "dsdsds");
    alert("1");
    // this.props.navigation.navigate("AddParcelNumber");
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  }
});
