import React from "react";
import { Alert, StyleSheet, View, TextInput, Vibration,KeyboardAvoidingView,TouchableWithoutFeedback,Keyboard } from "react-native";
import * as Permissions from "expo-permissions";
import * as Linking from 'expo-linking'
import { BarCodeScanner } from "expo-barcode-scanner";
import { Button, Text } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";
import { Header } from "react-navigation";

export default class AddParcelCodeScreen extends React.Component {
  render() {
    return <MyContext.Consumer>{context => <ParcelDataHandler context={context} navigation={this.props.navigation} />}</MyContext.Consumer>;
  }
}

class ParcelDataHandler extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      hasCameraPermission: null,
      scanned: false,
      doscan: false,
      type: BarCodeScanner.Constants.Type.back,
      result: null
    };
  }

  UNSAFE_componentWillMount() {}
  componentDidUpdate(prevProps, prevState) {}

  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };
  async _get_cameramermission() {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    this.setState({ hasCameraPermission: status === "granted" });
  }

  render() {
    const { hasCameraPermission, scanned, doscan } = this.state;

    if (doscan === true) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end"
          }}
        >
          <BarCodeScanner onBarCodeScanned={this.handleBarCodeScanned} style={StyleSheet.absoluteFillObject} type={this.state.type} />

          <Button title={"Abbrechen"} style={styles.abortscan} onPress={() => this.setState({ doscan: false })} buttonStyle={{ borderRadius: 0 }} />
        </View>
      );
    }
    return (
      <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
      <View style={styles.container}> 
      <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={Platform.OS === "ios" ? Header.HEIGHT : Header.HEIGHT + 25}  behavior={Platform.OS == "ios" ? "padding" : "height"}>
        <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
         
          <HeaderImage image="code" />

          <View style={{ padding: 10, marginBottom: 15, borderColor: "#ccc", borderWidth: 1, display: this.props.context.state.parcelProvider == "dhl" ? "flex" : "none" }}>
            <TextInput style={{ height: 20 }} placeholder={"Postnummer/Kundennummer eingeben"} onChangeText={value => this.props.context.setNewParcelValue("parcelCustomerID", value)} value={this.props.context.state.parcelCustomerID} />
          </View>
     
          <View style={{ padding: 10, borderColor: "#ccc", borderWidth: 1,flexDirection:'row'  }}>

               <View>
               
                <Button  onPress={this._startqr}  icon={<Icon name="barcode" size={22} color="#1e90ff" style={{ padding:0}} />} titleStyle={{fontSize:0}} buttonStyle={{ backgroundColor:'#ffffff',paddingTop:4, paddingLeft:10,paddingRight:10, borderWidth: 0,padding:0,borderRadius: 0,height:28,alignSelf:"flex-start"}} />
                <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:2,left:5}}></View>
                <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderRightWidth:0,width:7,height:7,position:'absolute',top:21,left:5}}></View>
                <View style={{borderColor:'#1e90ff',borderWidth:2,borderBottomWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:2,left:30}}></View>
                <View style={{borderColor:'#1e90ff',borderWidth:2,borderTopWidth:0,borderLeftWidth:0,width:7,height:7,position:'absolute',top:21,left:30}}></View>
              </View>

            <TextInput style={{ paddingLeft:5,height:32 ,alignSelf:"flex-start" ,flexGrow: 1}} placeholder={"Code für das Schließfach eingeben"} onChangeText={value => this.props.context.setNewParcelValue("parcelCode", value)} value={this.props.context.state.parcelCode} />

          </View>


          <View style={{ marginTop: 10, padding: 10, borderColor: "#ccc", backgroundColor: "#fff6b9", borderWidth: 1, display: this.props.context.state.parcelProvider == "dhl" ? "flex" : "none" }}>
            <Text style={{}}>Für die Abholung an einer DHL-Packstation benötigen wir Deine Postnummer und den vierstelligen Code.</Text>
            <Text>Bei Erhalt einer Abholkarte kannst Du auch nur den 16-stelligen Abholcode scannen.</Text>
          </View>
          <View style={{ marginTop: 10, padding: 10, borderColor: "#ccc", backgroundColor: "#fff6b9", borderWidth: 1, display: this.props.context.state.parcelProvider == "aznlocker" ? "flex" : "none" }}>
            <Text style={{}}>Für die Abholung an einem Amazon Locker benötigen wir den sechsstelligen Code.</Text>
          </View>
        </View>

        <View style={{ padding: 5, justifyContent: "flex-end", flex: 1 }}>
          <Button onPress={this._nextstep} title={"Weiter"}  disabled={this._checkInputs()} buttonStyle={{ borderRadius: 0 }} d />
        </View>
        </KeyboardAvoidingView>
      </View>
      </TouchableWithoutFeedback>
    );
  }
  _checkInputs = () => {
    if (this.props.context.state.parcelProvider == "aznlocker") {
      if (this.props.context.state.parcelCode.length == 6) {
        return false;
      } else {
        return true;
      }
    } else if (this.props.context.state.parcelProvider == "dhl") {
      if (this.props.context.state.parcelCustomerID != "" && this.props.context.state.parcelCode.length == 4) {
        return false;
      } else if (this.props.context.state.parcelCustomerID == "" && this.props.context.state.parcelCode.length > 4) {
        return false;
      } else {
        return true;
      }
    }
  };
  _nextstep = () => {
    //this.setState({ step: this.state.step + 1 });
    this.props.navigation.navigate("AddParcelLocation");
  };
  _startqr = async () => {
    if (this.state.hasCameraPermission === null) {
      //
      await this._get_cameramermission();
      if (this.state.hasCameraPermission === true) {
        this.setState({ doscan: true });
      } else {
        Alert.alert(
          "Hinweis",
          "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
          [
            {
              text: "OK",
              onPress: () => {
                this.setState({ loaderIsVisible: false });
                Linking.openURL("app-settings://notification/misterpostman");
              }
            }
          ],
          { cancelable: false }
        );
      }
    } else if (this.state.hasCameraPermission === false) {
      Alert.alert(
        "Hinweis",
        "Aktiviere die Erlaubnis für den Kamerazugriff in den Einstellungen",
        [
          {
            text: "OK",
            onPress: () => {
              this.setState({ loaderIsVisible: false });
              Linking.openURL("app-settings://notification/misterpostman");
            }
          }
        ],
        { cancelable: false }
      );
      return;
    } else {
      this.setState({ doscan: true });
    }
  };
  handleBarCodeScanned = async ({ type, data }) => {
    //Vibration.vibrate(100);

    if (this.props.context.state.parcelProvider == "aznlocker") {
      var extractCode = this._extractNumberAmazon(data);

      if (extractCode != "") {
        this.props.context.setNewParcelValue("parcelCode", extractCode);
      } else {
        Alert.alert(
          "Hinweis",
          "Das ist kein Amazon Locker Code!",
          [
            {
              text: "OK",
              onPress: () => {}
            }
          ],
          { cancelable: false }
        );
      }
    } else {
      this.props.context.setNewParcelValue("parcelCode", data);
    }

    this.setState({ scanned: true });
    this.setState({ doscan: false });

    //alert(`Code erkannt! Typ: ${type} mit folgenden Daten: ${data}`);
  };
  _extractNumberAmazon = nr => {
    if (nr.substring(0, 2) == "AZ" && nr.substring(nr.length - 2) == "LK") {
      return nr.substring(2, nr.length - 2);
    } else {
      return "";
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  }
});
