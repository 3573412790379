import React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View, Alert } from "react-native";
import { Button, Text, Overlay, AirbnbRating } from "react-native-elements";
import ProviderName from "../helper/ProviderName";
import ProviderLogo from "../helper/ProviderLogo";
import LocationType from "../helper/LocationType";
import HeaderImage from "../components/HeaderImage";
import { TouchableItem } from "../../components/TouchableItem";
import { formatDate, stringFormatDate } from "../helper/DateFormat";
import * as Icons from "@expo/vector-icons";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

var self;
export default class MyCourierBringScreen extends React.Component {
  constructor(props) {
    super(props);
    self = this;
    this.state = {
      id: "",
      parcelId: "1",
      deliveryStatus: false,
      pickupStatus: false,
      rejectStatus: false,
      rejectFreetext: "",
      loaderIsVisible: true,
      jobid: "",
      detailsLoaded: false,
      ratingIsVisible: false,
      ratingValue: 4,
      ratingIsSet: false
    };
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Meine Kurier-Lieferung`,
      headerBackTitle: `zurück`,
      headerTitleStyle: { textAlign: "center", fontSize: 16, fontWeight: "bold", justifyContent: "center", alignSelf: "center", flex: 1 },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerRight: (
        <TouchableItem onPress={() => params.handleRefresh(params.parcelId)}>
          <Icons.Ionicons name="ios-create" size={26} style={{ marginBottom: 0, marginRight: 15 }} color="white" />
        </TouchableItem>
      )
    };
  };
  componentDidMount() {
    var tmp = this.props.navigation.getParam("itemdata", []);
    this.props.navigation.setParams({
      handleRefresh: () => this.refreshHandler(tmp),
      parcelId: tmp["id"]
    });
    console.log(tmp);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (nextProps.navigation.state.params.check == true) {
    var { navigation } = this.props;
    var itemdata = navigation.getParam("itemdata", []);
    this.setState({ itemData: itemdata });
    this.setState({ parcelId: itemdata["id"] });
    this._statuscheck(itemdata["id"]);
    //}
  }
  refreshHandler(tmpitem) {
    //alert(tmpitem["provider"]);
    if (this.state.jobid == "") {
      self.props.navigation.navigate("AddCourierWeight", { editParcel: true, parcelItem: tmpitem });
    } else {
      Alert.alert(
        "Der Job wurde bereits angenommen",
        "Du kannst Das Paket nicht mehr bearbeiten.",
        [
          {
            text: "OK"
          }
        ],
        { cancelable: false }
      );
    }
  }

  _statuscheck = parcelid => {
    this.setState({ loaderIsVisible: true });
    console.log("check....");
    fetch(apiUrl + "parcel/owner/parceldetails.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userid: global.userID,
        loginKey: global.loginKey,
        parcelid: parcelid
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        //console.log(responseJson);
        if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "notexist") {
            errmess = "Lieferung existiert nicht.";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          var presult = responseJson.result;
          this.setState({ detailsLoaded: true });
          this.setState({ jobid: presult.jobid });

          this.setState({ rejectFreetext: presult.freetext });

          if (presult.rating != undefined) {
            this.setState({ ratingValue: presult.rating, ratingIsSet: true });
          }

          this.setState({ loaderIsVisible: false });
          if (presult.pickup == "0" && presult.reject == "0") {
            this.setState({ pickupStatus: false });
          } else {
            this.setState({ pickupStatus: true });
            this.setState({ stornoStatus: true });
          }
          if (presult.reject == "0") {
            this.setState({ rejectStatus: false });
          } else {
            this.setState({ rejectStatus: true });
          }
          if (presult.delivery == "1" && presult.pickup == "1") {
            this.setState({ deliveryStatus: true });
            console.log("deliveryStatus true");
          } else {
            this.setState({ deliveryStatus: false });
            console.log("deliveryStatus false");
          }

          console.log(responseJson);
          //console.log(responseJson);
        }
      })
      .catch(error => {
        console.error(error);
      });
    /*.then(function(resp) {
        console.log(resp);
      });*/
  };

  _editParcel = () => {
    alert("_editParcel");
  };
  _checkIn = () => {
    console.log("--global.userID " + global.userID);
    console.log("--this.state.jobid " + this.state.jobid);
    console.log("--this.state.parcelId " + this.state.parcelId);
    this.props.navigation.navigate("MyParcelReceive", { parcelid: this.state.parcelId, jobid: this.state.jobid });
  };

  _rateDelivery = () => {
    this.setState({ isLoading: true });

    fetch(apiUrl + "parcel/rate.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        jobID: this.state.jobid,
        parcelID: this.state.parcelId,
        ratingValue: this.state.ratingValue,
        rateType: "parcelOwner"
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {
          this.setState({ isLoading: false, ratingIsSet: true });
        } else {
          this.setState({ isLoading: false });
          Alert.alert(
            "Ups...",
            "Da ist etwas schief gelaufen....",
            [
              {
                text: "OK"
              }
            ],
            { cancelable: false }
          );
        }
        this.setState({ ratingIsVisible: false });
      })
      .catch(error => {
        console.error(error);
      });
  };

  _deleteParcel = () => {
    Alert.alert(
      "Bist Du dir sicher?",
      "Soll das Paket wirklich gelöscht werden?",
      [
        {
          text: "Ja",
          onPress: () =>
            // this.props.navigation.navigate("MyParcels", { refresh: true })
            this._deleteParcel2()
        },
        {
          text: "Nein"
        }
      ],
      { cancelable: true }
    );
  };
  _deleteParcel2 = () => {
    var parcelId = this.state.parcelId;

    this.setState({ isLoading: true });

    fetch(apiUrl + "parcel/owner/delparcel.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        parcelID: parcelId
      })
    }) /*.then(function(ev) {
      console.log(ev);
    });*/
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.success !== "undefined") {
          this.setState({ isLoading: false });
          Alert.alert(
            "Ok",
            "Das Paket wurde gelöscht.",
            [
              {
                text: "OK",
                onPress: () => {
                  this.props.navigation.navigate("MyParcels", {
                    refresh: true
                  });
                }
              }
            ],
            { cancelable: false }
          );
        } else if (typeof responseJson.error !== "undefined" && responseJson.code == "jobplaced") {
          this.setState({ isLoading: false });
          Alert.alert(
            "Löschen nicht mehr möglich",
            "Der Job wurde bereits an einen Mister Postman vermittelt.",
            [
              {
                text: "OK"
              }
            ],
            { cancelable: false }
          );
        } else {
          this.setState({ isLoading: false });
          Alert.alert(
            "Ups...",
            "Da ist etwas schief gelaufen....",
            [
              {
                text: "OK"
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  parcelNumberList = (obj) => {

    return  obj.split(",").map((data) => {
      return (
        <View key={data}><Text>{data}</Text></View>
      )
    })
  }

  render() {
    const { navigation } = this.props;
    const itemdata = navigation.getParam("itemdata", []);
    const { id, provider, parcel_id, parcel_pin, location_type, parcel_weight, location_street, location_no, location_zip, location_city, dest_street, dest_no, dest_zip, dest_city, time_from, time_to, time2, time_from2, time_to2 } = this.props;

    //const itemId = this.props.navigation.state.params.itemId;
    // const itemId = "test";
    return (
      <ScrollView>
        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>

        <Overlay isVisible={this.state.ratingIsVisible}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View style={{ padding: 5, justifyContent: "flex-start" }}>
              <Text style={{ alignSelf: "center", paddingTop: 10, fontSize: 20 }}>Bewerte diese Kurier-Lieferung</Text>
            </View>
            <View style={{ padding: 5, justifyContent: "center", flex: 1, flexDirection: "column", paddingTop: 10 }}>
              <AirbnbRating
                count={5}
                reviews={["Schlecht", "Nicht so gut", "Noch OK", "Gut", "Super"]}
                defaultRating={this.state.ratingValue}
                onFinishRating={val => {
                  this.setState({ ratingValue: val });
                }}
              />
            </View>
            <View style={{ padding: 5 }}>
              <Button onPress={() => this._rateDelivery()} title={"OK"}  style={{ width: "75%", alignSelf: "center", marginBottom: 0, justifyContent: "flex-end" }} buttonStyle={{ borderRadius: 0 }} />
            </View>
          </View>
        </Overlay>

        <View style={styles.container}>
          <HeaderImage image="courier" />
          <View style={{ height: 40, marginBottom: 10, flexDirection: "row", alignContent: "flex-start", padding: 10, justifyContent: "center" }}>
            <Text style={{ display: this.state.jobid != "" && this.state.detailsLoaded == true ? "flex" : "none" }}>Job angenommen</Text>
            <Text style={{ display: this.state.jobid == "" && this.state.detailsLoaded == true ? "flex" : "none" }}>Job noch nicht angenommen</Text>
            <Icon name="check" color={"#0ad000"} size={18} style={{ paddingLeft: 10, display: this.state.jobid != "" && this.state.detailsLoaded == true ? "flex" : "none" }} />
            <Icon name="check" color={"#efefef"} size={18} style={{ paddingLeft: 10, display: this.state.jobid == "" && this.state.detailsLoaded == true ? "flex" : "none" }} />
          </View>

          <View style={{ height: 40, marginBottom: 10, flexDirection: "row", alignContent: "flex-start", padding: 10, justifyContent: "center" }}>
            <Text style={{ display: this.state.pickupStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == false ? "flex" : "none" }}>Paket wurde abgeholt</Text>
            <Text style={{ display: this.state.pickupStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == true ? "flex" : "none" }}>Keine Paketabholung erfolgt</Text>
            <Text style={{ display: this.state.pickupStatus == false && this.state.detailsLoaded == true ? "flex" : "none" }}>Paket noch nicht abgeholt</Text>
            <Icon name="check" color={"#0ad000"} size={18} style={{ paddingLeft: 10, display: this.state.pickupStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == false ? "flex" : "none" }} />
            <Icon name="warning" color={"#ff0000"} size={18} style={{ paddingLeft: 10, display: this.state.pickupStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == true ? "flex" : "none" }} />
            <Icon name="check" color={"#efefef"} size={18} style={{ paddingLeft: 10, display: this.state.pickupStatus == false && this.state.detailsLoaded == true ? "flex" : "none" }} />
          </View>

          <Text style={{ marginBottom: 15, padding: 10, backgroundColor: "#ffefbf", display: this.state.pickupStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == true ? "flex" : "none" }}>Notiz: {this.state.rejectFreetext}</Text>


          <View style={{ height: 40, marginBottom: 10, flexDirection: "row", alignContent: "flex-start", padding: 10, justifyContent: "center" }}>
            <Text style={{ display: this.state.deliveryStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == false ? "flex" : "none" }}>Paket wurde abgeliefert</Text>
            <Text style={{ display: this.state.deliveryStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == true ? "flex" : "none" }}>Keine Ablieferung erfolgt</Text>
            <Text style={{ display: this.state.deliveryStatus == false && this.state.detailsLoaded == true ? "flex" : "none" }}>Paket noch nicht abgeliefert</Text>
            <Icon name="check" color={"#0ad000"} size={18} style={{ paddingLeft: 10, display: this.state.deliveryStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == false ? "flex" : "none" }} />
            <Icon name="warning" color={"#ff0000"} size={18} style={{ paddingLeft: 10, display: this.state.deliveryStatus == true && this.state.detailsLoaded == true && this.state.rejectStatus == true ? "flex" : "none" }} />
            <Icon name="check" color={"#efefef"} size={18} style={{ paddingLeft: 10, display: this.state.deliveryStatus == false && this.state.detailsLoaded == true ? "flex" : "none" }} />
          </View>    


          <View>
            <Button
              style={{ display: "none"}}
              titleStyle={{ fontSize: 14 }}
              onPress={() => this._checkIn()}
              title={this.state.detailsLoaded == true && this.state.deliveryStatus == true ? "Paket wurde entgegen genommen" : "Paket jetzt entgegennehmen"}
              buttonStyle={{ marginBottom: 10, borderRadius: 0 }}
              disabled={this.state.deliveryStatus == false && this.state.pickupStatus == true && this.state.rejectStatus == false ? false : true}
              iconRight
              icon={this.state.deliveryStatus && this.state.pickupStatus ? <Icon name={this.state.deliveryStatus && this.state.pickupStatus ? "check" : ""} size={20} color="#0ad000" style={this.state.deliveryStatus && this.state.pickupStatus ? { paddingLeft: 5 } : ""} /> : <Icon />}
            />
          </View>

          <View>
            <Button
              titleStyle={{ fontSize: 14 }}
              onPress={() => {
                this.setState({ ratingIsVisible: true });
              }}
              title={"Kurier-Lieferung bewerten"}
              buttonStyle={{ marginBottom: 10, borderRadius: 0 }}
              disabled={this.state.deliveryStatus == true && this.state.pickupStatus == true ? false : true}
              iconRight
              icon={this.state.ratingIsSet ? <Icon name={this.state.ratingIsSet ? "check" : ""} size={20} color="#0ad000" style={this.state.ratingIsSet ? { paddingLeft: 5 } : ""} /> : <Icon />}
            />
          </View>

          <View style={styles.entryRow}>
            <Text h4Style={styles.entryRowSubHeadline} h4>
              Sendungsdetails
            </Text>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Sendungsnummer(n)</Text>
            </View>
            <View style={styles.entryRowDetail}>
            {this.parcelNumberList(itemdata["parcel_id"])}
   
            </View>
          </View>




          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Gewicht</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                <Text style={styles.entryRowDetailText}>{itemdata["weight"]} Kg</Text>
              </Text>
            </View>
          </View>


          <View style={styles.entryRow}>
            <Text h4Style={styles.entryRowSubHeadline} h4>
              Abholung
            </Text>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_additional"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Strasse, Nr.</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_street"]} {itemdata["location_no"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>PLZ, Ort</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["location_zip"]} {itemdata["location_city"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <Text h5Style={styles.entryRowSubHeadline} h5>
              Wunschtermin
            </Text>
          </View>
            
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>von</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_from_pickup"])}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>bis</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_to_pickup"])}</Text>
            </View>
          </View>



          <View style={styles.entryRow}>
            <Text h4Style={styles.entryRowSubHeadline} h4>
              Lieferung
            </Text>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Vorrname</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_firstname"]} 
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Nachname</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_lastname"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_additional"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>Strasse, Nr.</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_street"]} {itemdata["dest_no"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>PLZ, Ort</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>
                {itemdata["dest_zip"]} {itemdata["dest_city"]}
              </Text>
            </View>
          </View>

          <View style={styles.entryRow}>
            <Text h5Style={styles.entryRowSubHeadline} h5>
              Wunschtermin
            </Text>
          </View>

          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>von</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_from"])}</Text>
            </View>
          </View>
          <View style={styles.entryRow}>
            <View style={styles.entryRowTitle}>
              <Text style={styles.entryRowTitleText}>bis</Text>
            </View>
            <View style={styles.entryRowDetail}>
              <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_to"])}</Text>
            </View>
          </View>





          <View style={{ display: itemdata["time2"] == 1 ? "flex" : "none" }}>
            <View style={styles.entryRow}>
              <Text h5Style={styles.entryRowSubHeadline} h5>
                Ausweichtermin
              </Text>
            </View>
            <View style={styles.entryRow}>
              <View style={styles.entryRowTitle}>
                <Text style={styles.entryRowTitleText}>von</Text>
              </View>
              <View style={styles.entryRowDetail}>
                <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_from2"])}</Text>
              </View>
            </View>
            <View style={styles.entryRow}>
              <View style={styles.entryRowTitle}>
                <Text style={styles.entryRowTitleText}>bis</Text>
              </View>
              <View style={styles.entryRowDetail}>
                <Text style={styles.entryRowDetailText}>{stringFormatDate(itemdata["time_to2"])}</Text>
              </View>
            </View>
          </View>
          <View style={{}}>
            <Button onPress={() => this._deleteParcel()} title={"Paket löschen"} buttonStyle={{ backgroundColor: "#dc143c", borderRadius: 0 }} />
          </View>
        </View>
      </ScrollView>
    );
  }
}



const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
