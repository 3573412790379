import React from "react";
import { ActivityIndicator, StatusBar, StyleSheet, View } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class AuthLoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    global.userRealname = "";
    this._bootstrapAsync();
  }

  // Fetch the token from storage then navigate to our appropriate place
  _bootstrapAsync = async () => {
    const userToken = await AsyncStorage.getItem("userToken");
    global.userRealname = await AsyncStorage.getItem("userRealname");
    global.userFirstname = await AsyncStorage.getItem("userFirstname");
    global.userLastname = await AsyncStorage.getItem("userLastname");
    global.userID = await AsyncStorage.getItem("userID");
    global.loginKey = await AsyncStorage.getItem("loginKey");
    global.isPostman = await AsyncStorage.getItem("isPostman");

    // This will switch to the App screen or Auth screen and this loading
    // screen will be unmounted and thrown away.
    this.props.navigation.navigate(userToken ? "App" : "Auth");
  };

  // Render any loading content that you like here
  render() {
    return (
      <View>
        <ActivityIndicator />
        <StatusBar barStyle="default" />
      </View>
    );
  }
}
