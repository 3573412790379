import React from "react";
import { Text } from "react-native";
export default class ProviderName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provName: {
        dpd: "DPD",
        gls: "GLS",
        ups: "UPS",
        dhl: "DHL",
        hermes: "Hermes",
        fedex: "Fedex",
        tnt: "TNT",
        aznlocker: "Amazon Locker"
      }
    };
  }
  render() {
    return <Text>{this.state.provName[this.props.provider]}</Text>;
  }
}
