import React from "react";
import { StyleSheet, View,TouchableOpacity,Text } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button,Card } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";

export default class AddParcelServiceScreen extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      type: "station"
    };
  }
  UNSAFE_componentWillMount() {
 
  }
  componentDidUpdate(prevProps, prevState) {

  }
  _addparcel = async () => {

    try {
      await AsyncStorage.setItem("newParcelService", "delivery");
    } catch (error) {
      // Error saving data
    }

    this.props.navigation.navigate("AddParcelProvider");
  };

  _addretour = async () => {

    try {
      await AsyncStorage.setItem("newParcelService", "retour");
    } catch (error) {
      // Error saving data
    }

    this.props.navigation.navigate("AddParcelProviderRetour");
  };
  _addcourier = async () => {

    try {
      await AsyncStorage.setItem("newParcelService", "courier");
    } catch (error) {
      // Error saving data
    }

    this.props.navigation.navigate("AddCourierService");
  };

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <View style={styles.container}>
            <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
            <HeaderImage image="standort" />

            <TouchableOpacity  onPress={() => {context.setNewParcelValue("parcelService", "delivery");this._addparcel(context)}}  >
              <Card titleStyle={{color:"#ffffff",fontSize:15}} containerStyle={{backgroundColor:'#fec600',borderWidth:0}} dividerStyle={{backgroundColor:'#ffffff'}}>
              
              <Button
                onPress={() => {context.setNewParcelValue("parcelService", "delivery");this._addparcel(context)}} 
                  buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
                  titleStyle={{fontSize:13}}
                  title='Lieferdienst' />
                  <Text style={{marginTop: 10,textAlign:'center',fontSize:13,color:"#ffffff",}}>
              Lieferung von Paketen aus Paketshops und Packstationen 
              </Text>
              </Card>
              </TouchableOpacity>



              <TouchableOpacity onPress={() => {context.setNewParcelValue("parcelService", "retour");this._addretour(context)}} >
              <Card  titleStyle={{color:"#ffffff",fontSize:15}} containerStyle={{backgroundColor:'#fec600',borderWidth:0}} dividerStyle={{backgroundColor:'#ffffff'}}>
              
                <Button
                onPress={() => {context.setNewParcelValue("parcelService", "retour");this._addretour(context)}} 
                  buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
                  titleStyle={{fontSize:13}}
                  title='Abholdienst' />
                  <Text style={{marginTop: 10,textAlign:'center',fontSize:13,color:"#ffffff",}}>
                Abholung von Retouren und frankierten Paketen
                </Text>
              </Card>
              </TouchableOpacity>


              <TouchableOpacity onPress={() => {context.setNewParcelValue("parcelService", "courier");this._addcourier(context)}}>
              <Card  titleStyle={{color:"#ffffff",fontSize:15}} containerStyle={{backgroundColor:'#fec600',borderWidth:0}} dividerStyle={{backgroundColor:'#ffffff'}}>
             
                <Button
                onPress={() => {context.setNewParcelValue("parcelService", "courier");this._addcourier(context)}} 
                  buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
                  titleStyle={{fontSize:13}}
                  title='Kurierdienst' />
                   <Text style={{marginTop: 10,textAlign:'center',fontSize:13,color:"#ffffff",}}>
                   Für Privatpersonen, Unternehmen und den Einzelhandel mit Retouroption
                </Text>
              </Card>
              </TouchableOpacity>

            
            </View>
          </View>
        )}
      </MyContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  }
});
