import React from "react";
import { Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View, TextInput, Alert, ActivityIndicator } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import * as Linking from 'expo-linking'
import * as Permissions from "expo-permissions";
import { Button, Text, Overlay } from "react-native-elements";
import { MonoText } from "../components/StyledText";
import AddCreditLayer from "./profile/AddCreditLayer";
import HeaderImage from "./components/HeaderImage";
import { TouchableItem } from "../components/TouchableItem";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../config";
const { apiUrl } = getConfVars();

const initialState = {
  appId: null,
  notificationsEnabled: false,
  notification: null,
  creditLayer: false,
  loaderIsVisible: false
};

export default class ProfileScreen extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: `Profil`,
      headerTitleStyle: { textAlign: "center", fontSize: 16, fontWeight: "bold", justifyContent: "center", alignSelf: "center", flex: 1 },
      headerTintColor: "white",
      headerStyle: {
        backgroundColor: "#fec600"
      },
      headerLeft: (
        <TouchableItem onPress={() => params.openSettings()}>
          <Icon size={26} style={{ marginBottom: 0, marginLeft: 15 }} color="white" name="cog" />
        </TouchableItem>
      ),
      headerRight: (
        <TouchableItem onPress={() => params.signOut()}>
          <Text style={{ marginBottom: 0, marginRight: 15, color: "white", fontSize: 16 }}>Ausloggen</Text>
        </TouchableItem>
      )
    };
  };

  constructor(props) {
    super(props);
    this.state = initialState;
    this.togglePushNotifications = this.togglePushNotifications.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {}
  UNSAFE_componentWillUpdate() {}
  async componentDidMount() {
    //
    let token = await AsyncStorage.getItem("pushToken");
    if (token == null) {
      this.enablePushNotifications();
    } else {
      this.checkPushstatus();
    }
    this.props.navigation.setParams({
      openSettings: this._openSettings
    });
    this.props.navigation.setParams({
      signOut: this._signOutAsync
    });
  }
  async UNSAFE_componentWillMount() {
    // this._notificationSubscription = Notifications.addListener(this._handleNotification);//aktivieren für lokalen push
  }
  async componentWillUnmount() {
    // this._notificationSubscription && Notifications.removeListener(this._handleNotification);//aktivieren für lokalen push
  }
  _signOutAsync = async () => {
    await AsyncStorage.clear();
    this.props.navigation.navigate("Auth");
  };
  _openSettings = async () => {
    this.props.navigation.navigate("Settings");
  };
  _addCredit = () => {
    this.setState({ creditLayer: true });
  };
  _closeCredit = () => {
    this.setState({ creditLayer: false });
  };
  _konto = () => {
    this.props.navigation.navigate("Debit");
  };
  _verify = () => {
    this.props.navigation.navigate("Verify");
  };

  _paycheck = () => {
    this.props.navigation.navigate("Payout", { refresh: true });
  };
  _handleNotification = notification => {
    //this.setState({ notification: notification });//aktivieren für lokalen push
    console.log("notification: " + notification);
  };
  async checkPushstatus() {
    const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      this.setState({ notificationsEnabled: false });
      this.setState({ loaderIsVisible: false });
    } else {
      let localPermission = await AsyncStorage.getItem("notifyEnabled");
      if (localPermission == "1") {
        this.setState({ notificationsEnabled: true });
      } else {
        this.setState({ notificationsEnabled: false });
      }
    }
  }

  async enablePushNotifications() {
    this.setState({ loaderIsVisible: true });
    const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
    let finalStatus = existingStatus;

    console.log("existingStatus: " + existingStatus);
    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.

    if (existingStatus !== "granted") {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
      console.log("finalStatus: " + finalStatus);
    }

    // Stop here if the user did not grant permissions
    if (finalStatus !== "granted") {
      console.log("finalStatus !== granted");

      Alert.alert(
        "Hinweis",
        "Aktiviere die Benachrichtigung für Mister Postman in den Einstellungen",
        [
          {
            text: "OK",
            onPress: () => {
              this.setState({ loaderIsVisible: false });
              Linking.openURL("app-settings://notification/misterpostman");
            }
          }
        ],
        { cancelable: false }
      );
      return;
    } else {
      // Get the token that uniquely identifies this device

      let tokenobj = await Notifications.getExpoPushTokenAsync();
      let token="";
      if(tokenobj.data != undefined){
        token= tokenobj.data;
      }else{
        token= tokenobj;
      }
      
      console.log("token: " + token);
      this.setState(state => {
        return {
          ...state
        };
      });

      const resp = await fetch(apiUrl + "user/setpushtoken.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          pushToken: token,
          todo: "enable",
          userID: global.userID,
          loginKey: global.loginKey,
          userPlatform: Platform.OS
        })
      })
        .then(response => response.json())
        .then(async responseJson => {
          if (typeof responseJson.exit !== "undefined") {
            await AsyncStorage.clear();
            this.props.navigation.navigate("Auth");
          } else if (typeof responseJson.success !== "undefined") {
            //alert(responseJson.realname);

            await AsyncStorage.setItem("pushToken", token);
            await AsyncStorage.setItem("notifyEnabled", "1");

            this.setState({ notificationsEnabled: true });
            this.setState({ loaderIsVisible: false });
          } else {
            Alert.alert("Ups...", "Da ist etwas schief gelaufen...", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
          }
        })
        .catch(error => {
          console.error(error);
          Alert.alert("Ups...", "Da ist etwas schief gelaufen...", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
        });
    }
  }
  async disablePushNotifications(token) {
    this.setState({ loaderIsVisible: true });
    var token = await AsyncStorage.getItem("pushToken");
    const resp = await fetch(apiUrl + "user/setpushtoken.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        pushToken: token,
        todo: "disable",
        userID: global.userID,
        loginKey: global.loginKey,
        userPlatform: Platform.OS
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.exit !== "undefined") {
          await AsyncStorage.clear();
          this.props.navigation.navigate("Auth");
        } else if (typeof responseJson.success !== "undefined") {
          await AsyncStorage.setItem("notifyEnabled", "0");
          this.setState({ loaderIsVisible: false });
          this.setState({ notificationsEnabled: false });
        } else {
          Alert.alert("Ups...", "Da ist etwas schief gelaufen...", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
        }
      })
      .catch(error => {
        console.error(error);
        Alert.alert("Ups...", "Da ist etwas schief gelaufen...", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
      });
  }
  togglePushNotifications() {
    console.log("togglePushNotifications");
    if (!this.state.notificationsEnabled) {
      console.log("enablePushNotifications");
      return this.enablePushNotifications();
    } else {
      console.log("disablePushNotifications");
      return this.disablePushNotifications();
    }
  }

  render() {
    const buttonData = this.state.notificationsEnabled
      ? {
          text: "Mitteilungen deaktivieren"
        }
      : {
          text: "Mitteilungen aktivieren"
        };

    return (
      <View style={styles.container}>
        <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
          </View>
        </Overlay>

        <Overlay isVisible={this.state.creditLayer}>
          <AddCreditLayer closeFunc={this._closeCredit} />
        </Overlay>

        <View style={{ padding: 0, justifyContent: "flex-start" }}>
          <HeaderImage image="profile" />
        </View>
        <View style={{ alignItems: "center", marginBottom: 20, flex: 1, justifyContent: "flex-start" }}>
          <Text style={{ color: "#666666", fontSize: 16 }} h4Style={{ color: "#666666", fontSize: 20 }} h4>
            Hallo {global.userFirstname} {global.userLastname}
          </Text>
        </View>

        <View style={{ padding: 5, justifyContent: "flex-end", alignItems: "center", marginBottom: 20, flex: 1 }}>
          {this.state.notification && (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text>Origin: {this.state.notification.origin}</Text>
              <Text>Body: {this.state.notification.body}</Text>
              <Text>Data: {JSON.stringify(this.state.notification.data)}</Text>
            </View>
          )}
        </View>

        <View style={{ padding: 5, justifyContent: "flex-end" }}>
          <View style={{ width: "75%", marginBottom: 20, alignSelf: "center" }}>
            <Button onPress={this.togglePushNotifications} title={buttonData.text} buttonStyle={{ borderRadius: 0 }} />
          </View>

          <View style={{ width: "75%", marginBottom: 20, alignSelf: "center" }}>
            <Button onPress={this._verify} title={"Identität bestätigen"} buttonStyle={{ borderRadius: 0 }} accessibilityLabel="Klicke hier um Deine Identität zu bestätigen" />
          </View>

          <View style={{ width: "75%", marginBottom: 20, alignSelf: "center" }}>
            <Button onPress={this._konto} title={"Kontoaktivitäten"}  buttonStyle={{ borderRadius: 0 }} accessibilityLabel="Klicke hier um die Aktivitäten auf Deinem Konto zu sehen" />
          </View>
          <View style={{ width: "75%", marginBottom: 20, alignSelf: "center" }}>
            <Button onPress={this._addCredit} title={"Guthaben aufladen"}  buttonStyle={{ borderRadius: 0 }} accessibilityLabel="Klicke hier um Guthaben auf Dein Konto zu laden" />
          </View>
          <View style={{ width: "75%", marginBottom: 20, alignSelf: "center" }}>
            <Button onPress={this._paycheck} title={"Guthaben auszahlen"}  buttonStyle={{ borderRadius: 0 }} accessibilityLabel="Klicke hier um Dir Guthaben auszahlen zu lassen" />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#fff",
    paddingTop: 20
  }
});
