import React from "react";
import { ScrollView, Platform, StyleSheet, TouchableWithoutFeedback, View,Alert } from "react-native";
import { Button, Text } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import Collapsible from "react-native-collapsible";
import { formatDate } from "../helper/DateFormat";
import DateTimePicker from '@react-native-community/datetimepicker';
import DateTimePickerModal from "react-native-modal-datetime-picker";

export default class AddCourierBring_PickupTimeScreen extends React.Component {
  render() {
    return <MyContext.Consumer>{context => <TimeDataHandler context={context} navigation={this.props.navigation} />}</MyContext.Consumer>;
  }
}

class TimeDataHandler extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      date1vis: true,
      date2vis: true,
      dateisvis: "",
      modaldate1vis:false,
      modaldate2vis:false,
    };

  }
  UNSAFE_componentWillMount = async () => {
    console.log("componentWillMount");

    await this.chkPickTimeFrom();

  };
  componentDidUpdate(prevProps, prevState) {
   
  }
  chkPickTimeFrom = async (value = 0) => {

    this._hideModalCalAndroid();
    
    var pickTime = this.props.context.state["parcelDatePickupFrom"].getTime();
    var currTime = new Date();

    //nächster möglicher abholtermin
    var minhours=4;

    var nextPickupTime = currTime.getTime() + (minhours * 60 * 60 * 1000);
    var nextPickupTimeDate = new Date(nextPickupTime);


              //prüfen ob zeit zwischen 21 uhr und 6 uhr morgens liegt, dann zeit auf 6.00 uhr am nächsten Tag stellen
              var absDate1From = nextPickupTimeDate.getHours() * 60 + nextPickupTimeDate.getMinutes();
              if(absDate1From < 420 || absDate1From > 1260){
                var nextDateDay= nextPickupTimeDate.getDate();
                var nextDateMonth= nextPickupTimeDate.getMonth();
                var nextDateYear= nextPickupTimeDate.getFullYear();
            
                var nextDate=new Date(nextDateYear,nextDateMonth,nextDateDay,"07","00","00");
                var nextPickupTimeDate = new Date(nextDate.getTime() + (24 * 60 * 60 * 1000));
              }


      if (pickTime < nextPickupTime) {
              await this.props.context.setNewParcelValue("parcelDatePickupFrom", nextPickupTimeDate);
      }

    if (value != 0) {
      //if (new Date(value) < today) {
        if (value.getTime() >= nextPickupTime) {
          await this.props.context.setNewParcelValue("parcelDatePickupFrom", new Date(value));
      } else {
        await this.props.context.setNewParcelValue("parcelDatePickupFrom", nextPickupTimeDate);
      }
    }
    this.chkToTime(this.props.context.state["parcelDatePickupTo"]);

   
  };


  chkToTime = async (newvalue) => {

    this._hideModalCalAndroid();

    var fromTime = this.props.context.state["parcelDatePickupFrom"].getTime();
    var toTimeMin = new Date(fromTime + 60 * 60000);
    var toTimeMax = new Date(fromTime + 300 * 60000);
    if (newvalue < toTimeMin) {
      await this.props.context.setNewParcelValue("parcelDatePickupTo", toTimeMin);
    } else if (newvalue > toTimeMax) {
      await this.props.context.setNewParcelValue("parcelDatePickupTo", toTimeMax);
    } else {
      await this.props.context.setNewParcelValue("parcelDatePickupTo", newvalue);
    }
    
  };


  _showModalCalAndroid=(indx)=>{
    this.setState({ ["modaldate"+indx+"vis"]: true });
  }
  _hideModalCalAndroid=()=>{
    this.setState({ modaldate1vis: false });
    this.setState({ modaldate2vis: false });
  }


  _toogleCalVisIOS = trgState => {
    if (this.state.dateisvis == trgState) {
      this.setState({ dateisvis: "" });
    } else {
      this.setState({ [this.state.dateisvis]: true });
      this.setState({ dateisvis: trgState });
    }
    this.setState({ [trgState]: !this.state[trgState] });
  };


  render() {

    return (
      <View style={styles.container}>
        <ScrollView>
          <View style={{ padding: 5, justifyContent: "center", flex: 1 }}>
          <DateTimePickerModal
              isVisible={this.state.modaldate1vis}
              mode="datetime"
              onConfirm={(val) => this.chkPickTimeFrom(val)}
              onCancel={this._hideModalCalAndroid}
              date={this.props.context.state.parcelDatePickupFrom}
              minuteInterval={10}
              locale={'de_DE'}                                  
            />
            <DateTimePickerModal
              isVisible={this.state.modaldate2vis}
              mode="datetime"
              onConfirm={(val) => this.chkToTime(val)}
              onCancel={this._hideModalCalAndroid}
              date={this.props.context.state.parcelDatePickupTo}
              minuteInterval={10}
              locale={'de_DE'}                                  
            />
            <View style={styles.entryRowNoBorder}>
              <Text h4Style={styles.entryRowSubHeadline} h4>
                Abholzeit
              </Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={{ color: "#666" }}>Wann soll das Paket bei Dir abgeholt werden?</Text>
              <Text style={{ color: "#666" }}>Die Abholzeit kann frühestens in 4 Stunden beginnen.</Text>
            </View>

            <TouchableWithoutFeedback
              onPress={async () => {
               
                if (Platform.OS === "ios") {
                  this._toogleCalVisIOS("date1vis");
                } else {
                  this._showModalCalAndroid(1);
       
               }
              }}
            >
              <View style={styles.entryRow}>
                <View style={styles.entryRowTitle}>
                  <Text style={styles.entryRowTitleText}>von</Text>
                </View>

                <View style={styles.entryRowDetail}>
                  <Text style={styles.entryRowDetailText}>{formatDate(this.props.context.state.parcelDatePickupFrom)}</Text>
                </View>
              </View>
            </TouchableWithoutFeedback>



            

            <Collapsible collapsed={this.state.date1vis}>



              {Platform.OS === "ios" ? (

            
                       <DateTimePicker
                        testID="dateTimePicker"
                        value={this.props.context.state.parcelDatePickupFrom}
                        mode="datetime"
                        display="spinner"
                        is24Hour={true}
                        onChange={(event,val) => this.chkPickTimeFrom(val)}
                        locale={'de_DE'}
                        minuteInterval={10}
                        /> 

              ) : (
                <Text />
              )}
            </Collapsible>

            <TouchableWithoutFeedback
              onPress={async () => {
                if (Platform.OS === "ios") {
                  this._toogleCalVisIOS("date2vis");
                } else {
                  this._showModalCalAndroid(2);

                }
              }}
            >
              <View style={styles.entryRow}>
                <View style={styles.entryRowTitle}>
                  <Text style={styles.entryRowTitleText}>bis</Text>
                </View>

                <View style={styles.entryRowDetail}>
                  <Text style={styles.entryRowDetailText}>{formatDate(this.props.context.state.parcelDatePickupTo)}</Text>
                </View>
              </View>
            </TouchableWithoutFeedback>

            <Collapsible collapsed={this.state.date2vis}>
              {Platform.OS === "ios" ? (

                  <DateTimePicker
                  testID="dateTimePicker"
                  value={this.props.context.state.parcelDatePickupTo}
                  mode="datetime"
                  display="spinner"
                  is24Hour={true}
                  onChange={(event,val) => this.chkToTime(val)}
                  locale={'de_DE'}
                  minuteInterval={10}
                  /> 


              ) : (
                <Text />
              )}
            </Collapsible>


  
          </View>
        </ScrollView>
        <View style={{}}>
          <Button onPress={this._nextstep} title={"weiter"}  buttonStyle={{ borderRadius: 0 }} />
        </View>
      </View>
    );
  }

  _nextstep = () => {

    //check time
    var absDate1From = this.props.context.state.parcelDatePickupFrom.getHours() * 60 + this.props.context.state.parcelDatePickupFrom.getMinutes();
    var absDate1To = this.props.context.state.parcelDatePickupTo.getHours() * 60 + this.props.context.state.parcelDatePickupTo.getMinutes();

    if (absDate1From < 420 || absDate1To < 420 ) {
      Alert.alert(
        "Ups...",
        "Bitte beachte, dass der Lieferzeitraum zwischen 7:00Uhr und 22:00 Uhr muss.",
        [
          {
            text: "OK"
          }
        ],
        { cancelable: false }
      );
    } else if (absDate1From > 1320 || absDate1To > 1320) {
      Alert.alert(
        "Ups...",
        "Bitte beachte, dass der Lieferzeitraum zwischen 7.00 Uhr und 22:00 Uhr muss.",
        [
          {
            text: "OK"
          }
        ],
        { cancelable: false }
      );
    } else {


      this.props.navigation.navigate("AddCourierBring_DeliveryTime");
      this.setState({ date1vis: true });
      this.setState({ date2vis: true });

    } 

  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowNoBorder: {
    paddingBottom: 0,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
