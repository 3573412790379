import React from "react";
import { View, Text, StyleSheet, TouchableOpacity, TouchableNativeFeedback, Platform } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 7,
    flexDirection: "row",
    alignItems: "center"
  },
  innercontainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    paddingRight: 10
  },
  text: {
    marginLeft: 12,
    fontSize: 16,
    flex: 0.85,
    flexWrap: "wrap"
  }
});

export default class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { addressFields } = this.props;
    const TouchableItem = Platform.OS === "ios" ? TouchableOpacity : TouchableNativeFeedback;
    return (
      <View style={styles.container}>
        <TouchableItem onPress={this._selectrow} style={styles.innercontainer}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <View style={{ flexDirection: "row", flex: 1, paddingTop: 4 }}>
              <Text style={styles.text}>{`${addressFields.route} ${addressFields.street_number}, ${addressFields.postal_code} ${addressFields.locality}`}</Text>
            </View>
          </View>
        </TouchableItem>
      </View>
    );
  }

  _selectrow = () => {
    this.props.selectFunction._selectCacheAddress(this.props.rowData);
  };
}
