import React from "react";
import { Image, Platform, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, TextInput, Alert, Keyboard, KeyboardAvoidingView } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking'
import Constants from "expo-constants";
import { Audio } from "expo-av";
import { Input, Button, Text, Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class SignInScreen extends React.Component {
  static navigationOptions = {
    header: null
  };

  constructor(props) {
    super(props);
    this.state = {
      text: "Useless Placeholder",
      username: "",
      password: "",
      loaderIsVisible: false
    };
  }
  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  async UNSAFE_componentWillMount() {
    this._playsound();
  }

  render() {
    return (
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
          <View style={{ flex: 1 }}>
            <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" style={{ flex: 1 }} width={"100%"} height={"130%"} borderRadius={0}>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
              </View>
            </Overlay>

            <KeyboardAvoidingView style={styles.containerTop} behavior={Platform.OS === "ios" ? "padding" : null}>
              <View style={styles.welcomeContainer}>
                <Image source={require("../../assets/images/mrpostman.jpg")} style={{ width: 191, height: 100 }} />
              </View>

              <Text h4 h4Style={{ textAlign: "center", marginBottom: 20 }}>
                Login
              </Text>

              <Input
                inputContainerStyle={{ padding: 0, borderColor: "#ccc", borderWidth: 1, borderRadius: 0, margin: 10, width: 250, alignSelf: "center" }}
                onChangeText={username => this.setState({ username })}
                value={this.state.username}
                placeholder="Benutzername"
                autoComplete="off"
                autoCorrect={false}
                autoCapitalize="none"
                leftIconContainerStyle={{ paddingRight: 10 }}
                leftIcon={<Icon name="user" size={24} color="grey" />}
                returnKeyLabel="Einloggen"
                returnKeyType="next"
                onSubmitEditing={() => this._focusNextField("2")}
                ref="1"
              />

              <Input
                inputContainerStyle={{ padding: 0, borderColor: "#ccc", borderWidth: 1, borderRadius: 0, margin: 10, width: 250, alignSelf: "center" }}
                onChangeText={password => this.setState({ password })}
                value={this.state.password}
                placeholder="Passwort"
                secureTextEntry
                leftIconContainerStyle={{ paddingRight: 10 }}
                leftIcon={<Icon name="lock" size={24} color="grey" />}
                returnKeyType="go"
                onSubmitEditing={() => this._login()}
                ref="2"
                enablesReturnKeyAutomatically={true}
              />

              <Button
                onPress={this._login}
                title={"Einloggen"}
                borderRadius={25}
                containerViewStyle={{ borderRadius: 0, width: 250 }}
                buttonStyle={{ borderRadius: 0, height: 45, width: 250 }}
                containerStyle={{ borderRadius: 25, height: 45, width: 250, alignSelf: "center", marginTop: 30 }}
                disabled={this.state.password.length > 0 && this.state.username.length > 2 ? false : true}
                accessibilityLabel="Drücken Sie hier um sich einzuloggen"
              />



            

            <Button onPress={this._register} title={"Registrieren"} buttonStyle={{ borderRadius: 50, width: 250 }} containerStyle={{ width: 250, alignSelf: "center", marginTop: 10, marginBottom: 0 }} type="clear" />
            <Button onPress={this._reminder} title={"Passwort vergessen"} buttonStyle={{ borderRadius: 50, width: 250 }} containerStyle={{ width: 250, alignSelf: "center", marginTop: 0, marginBottom: 30 }} type="clear" />

            <View style={{ justifyContent: "center", flexDirection: "row", marginBottom: 5 }}>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("https://www.mister-postman.net/datenschutz");
                }}
              >
                <Text style={styles.legaltext}>Datenschutz</Text>
              </TouchableOpacity>
              <Text style={styles.separator}>|</Text>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("https://www.mister-postman.net/impressum");
                }}
              >
                <Text style={styles.legaltext}>Impressum</Text>
              </TouchableOpacity>
              <Text style={styles.separator}>|</Text>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("https://www.mister-postman.net/agb");
                }}
              >
                <Text style={styles.legaltext}>AGB</Text>
              </TouchableOpacity>
            </View>
            <Text style={[styles.legaltext, { textAlign: "center", fontSize: 9, color: "#d8d8d8", marginBottom: 10 }]}>Version: {Constants.manifest.version}</Text>
            </KeyboardAvoidingView>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
  _focusNextField(nextField) {
    this.refs[nextField].focus();
  }
  _playsound = async () => {
    /*await Audio.setIsEnabledAsync(true);
        const sound = new Audio.Sound();
        await sound.loadAsync(require('./assets/sounds/mrpostman.mp3'));
        await sound.playAsync();*/

    const soundObject = new Audio.Sound();

    try {
      await soundObject.loadAsync(require("../../assets/sounds/mrpostman.mp3"));
      await soundObject.playAsync();
      // Your sound is playing!
    } catch (error) {
      // An error occurred!
    }
  };

  _register = () => {
    this.props.navigation.navigate("Register");
  };
  _reminder = () => {
    this.props.navigation.navigate("Reminder");
  };

  _login = async () => {
    const { username, password } = this.state;
    this.setState({ loaderIsVisible: true });
    fetch(apiUrl + "user/login.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: username,
        pass: password
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.firstname !== "undefined") {
          //alert(responseJson.realname);

          await AsyncStorage.setItem("userToken", "abc");

          await AsyncStorage.setItem("userFirstname", responseJson.firstname);
          global.userFirstname = responseJson.firstname;

          await AsyncStorage.setItem("userLastname", responseJson.lastname);
          global.userLastname = responseJson.lastname;

          await AsyncStorage.setItem("userID", responseJson.id);
          global.userID = responseJson.id;

          await AsyncStorage.setItem("loginKey", responseJson.key);
          global.loginKey = responseJson.key;

          await AsyncStorage.setItem("isPostman", responseJson.verified);
          global.isPostman = responseJson.verified;

          await AsyncStorage.setItem("userName", responseJson.username);
          this.props.navigation.navigate("App");
        } else {
          // await alert("Login fehlgeschlagen.");
          Alert.alert("Ups...", "Login fehlgeschlagen.", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });
          //this.setState({ loaderIsVisible: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

const styles = StyleSheet.create({
  legaltext: {
    fontSize: 11,
    color: "grey"
  },
  separator: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: 11
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center"
  },
  containerTop: {
    flex: 1,
    justifyContent: "center"
  },
  developmentModeText: {
    marginBottom: 20,
    color: "rgba(0,0,0,0.4)",
    fontSize: 14,
    lineHeight: 19,
    textAlign: "center"
  },
  contentContainer: {
    paddingTop: 30
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  },
  welcomeImage: {
    width: 200,
    height: 100,
    resizeMode: "contain",
    marginTop: 3,
    marginLeft: -10
  },
  getStartedContainer: {
    alignItems: "center",
    marginHorizontal: 50
  },
  homeScreenFilename: {
    marginVertical: 7
  },
  codeHighlightText: {
    color: "rgba(96,100,109, 0.8)"
  },
  codeHighlightContainer: {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 3,
    paddingHorizontal: 4
  },
  getStartedText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    lineHeight: 24,
    textAlign: "center"
  },
  tabBarInfoContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: "black",
        shadowOffset: { height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3
      },
      android: {
        elevation: 20
      }
    }),
    alignItems: "center",
    backgroundColor: "#fbfbfb",
    paddingVertical: 20
  },
  tabBarInfoText: {
    fontSize: 17,
    color: "rgba(96,100,109, 1)",
    textAlign: "center"
  },
  navigationFilename: {
    marginTop: 5
  },
  helpContainer: {
    marginTop: 15,
    alignItems: "center"
  },
  helpLink: {
    paddingVertical: 15
  },
  helpLinkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
});
