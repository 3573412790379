import React from "react";
import { Image, ScrollView, StyleSheet, View, Alert, ActivityIndicator ,Modal} from "react-native";
import * as Linking from 'expo-linking'
import MapView from "react-native-maps";
import { Button, Text, Overlay, CheckBox } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import LocationType from "../helper/LocationType";
import ProviderName from "../helper/ProviderName";
import ProviderLogo from "../helper/ProviderLogo";
import { formatDate } from "../helper/DateFormat";
import { MyProvider, MyContext } from "../../rootProvider";
import CalcCosts from "./components/CalcCosts";
import HeaderImage from "../components/HeaderImage";
import SignContractLayer from "./SignContractLayer";
import getConfVars from "../../config";
import * as ScreenOrientation from 'expo-screen-orientation';
const { apiUrl } = getConfVars();

export default class AddParcelOverviewScreen extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      code: "",
      isLoading: false,
      loaderIsVisible: true,
      numberValue: "",
      md5: "",
      distanceVal: "",
      distanceText: "",
      durationVal: "",
      durationText: "",
      checkedDSGVO: false,
      checkedAGB: false,
      agbIsVisible: false,
      privacyIsVisible: false,
      contractLayerVisible: false,
      signature: null
    };
  }

  _confirmpost = context => {
    Alert.alert(
      "Paket kostenpflichtig eintragen",
      "Bist Du Dir sicher, dass Du dieses Paket jetzt eintragen möchtest?",
      [
        {
          text: "Ja",
          onPress: () => this.__post(context)
        },
        {
          text: "Nein"
        }
      ],
      { cancelable: true }
    );
  };

  __post = async context => {
    const {
      parcelNumber,
      parcelProvider,
      parcelType,
      parcelWeight,
      parcelCustomerID,
      parcelCode,
      parcelLocStreet,
      parcelLocNumber,
      parcelLocAdditional,
      parcelLocZip,
      parcelLocCity,
      parcelDestStreet,
      parcelDestNumber,
      parcelDestAdditional,
      parcelDestZip,
      parcelDestCity,
      parcelDatePickupFrom,
      parcelDatePickupTo,
      parcelDate1From,
      parcelDate1To,
      parcelDate2,
      parcelDate2From,
      parcelDate2To,
      parcelLocLocation,
      parcelDestLocation,
      parcelLocFormattedAddress,
      parcelDestFormattedAddress,
      parcelLocPlaceID,
      parcelDestPlaceID,
      parcelSess,
      parcelEditID
    } = context.state;

    this.setState({ loaderIsVisible: true });

    console.log(
      JSON.stringify({
        userID: global.userID,
        parcelNumber: parcelNumber,
        parcelProvider: parcelProvider,
        parcelType: parcelType,
        parcelWeight: parcelWeight,
        parcelCustomerID: parcelCustomerID,
        parcelCode: parcelCode,
        parcelLocStreet: parcelLocStreet,
        parcelLocNumber: parcelLocNumber,
        parcelLocAdditional: parcelLocAdditional,
        parcelLocZip: parcelLocZip,
        parcelLocCity: parcelLocCity,
        parcelLocLocationLat: parcelLocLocation["coords"]["latitude"].toString(),
        parcelLocLocationLng: parcelLocLocation["coords"]["longitude"].toString(),
        parcelLocFormattedAddress: parcelLocFormattedAddress,
        parcelLocPlaceID: parcelLocPlaceID,
        parcelDestStreet: parcelDestStreet,
        parcelDestNumber: parcelDestNumber,
        parcelDestAdditional: parcelDestAdditional,
        parcelDestZip: parcelDestZip,
        parcelDestCity: parcelDestCity,
        parcelDestLocationLat: parcelDestLocation["coords"]["latitude"].toString(),
        parcelDestLocationLng: parcelDestLocation["coords"]["longitude"].toString(),
        parcelDestFormattedAddress: parcelDestFormattedAddress,
        parcelDestPlaceID: parcelDestPlaceID,
        parcelDatePickupFrom: parcelDatePickupFrom,
        parcelDatePickupTo: parcelDatePickupTo,
        parcelDate1From: parcelDate1From,
        parcelDate1To: parcelDate1To,
        parcelDate2: parcelDate2,
        parcelDate2From: parcelDate2From,
        parcelDate2To: parcelDate2To,
        parcelSess: parcelSess,
        timeOffSet: new Date().getTimezoneOffset(),
        signature: this.state.signature,
        parcelEditID: parcelEditID
      })
    );

    fetch(apiUrl + "parcel/owner/newparcel.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userID: global.userID,
        loginKey: global.loginKey,
        parcelNumber: parcelNumber,
        parcelProvider: parcelProvider,
        parcelType: parcelType,
        parcelWeight: parcelWeight,
        parcelCustomerID: parcelCustomerID,
        parcelCode: parcelCode,
        parcelLocStreet: parcelLocStreet,
        parcelLocNumber: parcelLocNumber,
        parcelLocAdditional: parcelLocAdditional,
        parcelLocZip: parcelLocZip,
        parcelLocCity: parcelLocCity,
        parcelLocLocationLat: parcelLocLocation["coords"]["latitude"].toString(),
        parcelLocLocationLng: parcelLocLocation["coords"]["longitude"].toString(),
        parcelLocFormattedAddress: parcelLocFormattedAddress,
        parcelLocPlaceID: parcelLocPlaceID,
        parcelDestStreet: parcelDestStreet,
        parcelDestNumber: parcelDestNumber,
        parcelDestAdditional: parcelDestAdditional,
        parcelDestZip: parcelDestZip,
        parcelDestCity: parcelDestCity,
        parcelDestLocationLat: parcelDestLocation["coords"]["latitude"].toString(),
        parcelDestLocationLng: parcelDestLocation["coords"]["longitude"].toString(),
        parcelDestFormattedAddress: parcelDestFormattedAddress,
        parcelDestPlaceID: parcelDestPlaceID,
        parcelDatePickupFrom: parcelDatePickupFrom,
        parcelDatePickupTo: parcelDatePickupTo,
        parcelDate1From: parcelDate1From,
        parcelDate1From: parcelDate1From,
        parcelDate1To: parcelDate1To,
        parcelDate2: parcelDate2,
        parcelDate2From: parcelDate2From,
        parcelDate2To: parcelDate2To,
        parcelSess: parcelSess,
        timeOffSet: new Date().getTimezoneOffset(),
        signature: this.state.signature,
        parcelEditID: parcelEditID
      })
    })
      .then(response => response.json())
      .then(async responseJson => {
        console.log(responseJson);
        if (typeof responseJson.ok !== "undefined") {
          console.log(responseJson.ok);
          Alert.alert("Super!", "Das Paket wurde eingetragen.", [
            {
              text: "Zurück zur Übersicht",

              onPress: () => {
                onPress: () => this.setState({ loaderIsVisible: false });
                this.props.navigation.navigate("MyParcels", { refresh: true });
                context.handleReset();
              }
            }
          ]);
        } else if (typeof responseJson.error !== "undefined") {
          var errmess = "Da hat etwas nicht geklappt!";
          var errtit = "Ups...";
          if (responseJson.code == "parcelExists") {
            errmess = "Paket mit der verwendeten Paketnummer ist bereits im System";
          } else if (responseJson.code == "parcelJobExist") {
            errmess = "Dieses Paket kann nicht bearbeitet werden.";
          } else if (responseJson.code == "nocredit") {
            errtit = "Kein Guthaben vorhanden!";
            errmess = "Bitte laden Sie Ihr Konto auf um Pakete einzustellen.";
          } else if (responseJson.code == "lowcredit") {
            errtit = "Zu wenig Guthaben vorhanden! (" + responseJson.credit + ")";
            errmess = "Bitte laden Sie Ihr Konto auf um Pakete einzustellen.";
          } else if (responseJson.code == "11") {
            errmess = "11";
          } else if (responseJson.code == "22") {
            errmess = "22";
          } else if (responseJson.code == "33") {
            errmess = "33";
          }
          Alert.alert(
            errtit,
            errmess,
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            errtit,
            "Da hat etwas nicht geklappt!",
            [
              {
                text: "OK",
                onPress: () => this.setState({ loaderIsVisible: false })
              }
            ],
            { cancelable: false }
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
  _openContract = async () =>{
    this.setState({ contractLayerVisible: true },()=>{ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)});
    //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
   
  }
  _closeContract = async () => {
    this.setState({ contractLayerVisible: false });
    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
  };
  _setSignature = sig => {
    this.setState({ signature: sig });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1, paddingTop: 20 }}>
          <ActivityIndicator />
        </View>
      );
    }

    return (
      <MyContext.Consumer>
        {context => (
          <View style={{ flex: 1 }}>

          <Modal visible={this.state.contractLayerVisible} width={"100%"} height={"100%"} supportedOrientations={['portrait', 'landscape']} >
            <View style={{padding:40,flex:1}}>
              <SignContractLayer closeFunc={this._closeContract} func={this._setSignature} />
              </View>
            </Modal>

            <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)" width={"100%"} height={"130%"} borderRadius={0}>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
              </View>
            </Overlay>

            <ScrollView>
              <View style={([styles.container], { paddingTop: 10 })}>
                <HeaderImage image="empfangen" />
              </View>
              <View style={styles.container}>
                <View style={styles.entryRow}>
                  <Text h4Style={styles.entryRowSubHeadline} h4>
                    Kosten & Lieferweg
                  </Text>
                </View>

                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Lieferweg</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{context.state.parcelDistanceText}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Lieferzeit mit dem Auto ca.</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{context.state.parcelDurationText}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>
                    Kosten inkl. Mwst. ({context.state.parcelCostsPerKm}{context.state.parcelCurrency} pro Km, 1. Km wird als Festpreis mit {context.state.parcelCostsBase}{context.state.parcelCurrency} berechnet)
                     
                    </Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelCosts} {context.state.parcelCurrency}
                    </Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <Text h4Style={styles.entryRowSubHeadline} h4>
                    Paketdetails
                  </Text>
                </View>

                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Sendungsnummer</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{context.state.parcelNumber}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Versandunternehmen</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text>
                      <ProviderName provider={context.state.parcelProvider} />
                    </Text>
                    <ProviderLogo provider={context.state.parcelProvider} />
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Gewicht</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{context.state.parcelWeight} Kg</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Standort</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {" "}
                      <LocationType value={context.state.parcelType} />
                    </Text>
                  </View>
                </View>
                <View style={[styles.entryRow, { display: context.state.parcelType == 2 ? "none" : "flex" }]}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Postnummer/Kundennummer</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {" "}
                      <LocationType value={context.state.parcelCustomerID} />
                    </Text>
                  </View>
                </View>
                <View style={[styles.entryRow, { display: context.state.parcelType == 2 ? "none" : "flex" }]}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Code/PIN</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {" "}
                      <LocationType value={context.state.parcelCode} />
                    </Text>
                  </View>
                </View>

                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelLocAdditional}
                    </Text>
                  </View>
                </View>

                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Strasse, Nr.</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelLocStreet} {context.state.parcelLocNumber}
                    </Text>
                  </View>
                </View>



                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>PLZ, Ort</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelLocZip} {context.state.parcelLocCity}
                    </Text>
                  </View>
                </View>
                <View pointerEvents="none">
                  <MapView
                    initialRegion={{
                      latitude: context.state.parcelLocLocation.coords.latitude,
                      longitude: context.state.parcelLocLocation.coords.longitude,
                      latitudeDelta: 0.01,
                      longitudeDelta: 0.01
                    }}
                    style={{ flex: 1, height: 100 }}
                    // onPress={() => alert('hello')}
                    mapType="standard"
                    showsCompass={true}
                    showsBuildings={true}
                    showsTraffic={true}
                    showsIndoors={true}
                  >
                    <MapView.Marker coordinate={context.state.parcelLocLocation.coords} title="My Marker" description="Some description" />
                  </MapView>
                </View>
                <View style={styles.entryRow}>
                  <HeaderImage image="roller" />
                </View>
                <View style={styles.entryRow}>
                  <Text h4Style={styles.entryRowSubHeadline} h4>
                    Lieferadresse
                  </Text>
                </View>

                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Adresszusatz/-info</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelDestAdditional}
                    </Text>
                  </View>
                </View>
                
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Strasse, Nr.</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelDestStreet} {context.state.parcelDestNumber}
                    </Text>
                  </View>
                </View>

                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>PLZ, Ort</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelDestZip} {context.state.parcelDestCity}
                    </Text>
                  </View>
                </View>
                <View pointerEvents="none">
                  <MapView
                    initialRegion={{
                      latitude: context.state.parcelDestLocation.coords.latitude,
                      longitude: context.state.parcelDestLocation.coords.longitude,
                      latitudeDelta: 0.01,
                      longitudeDelta: 0.01
                    }}
                    style={{ flex: 1, height: 100 }}
                    // onPress={() => alert('hello')}
                    mapType="standard"
                    showsCompass={true}
                    showsBuildings={true}
                    showsTraffic={true}
                    showsIndoors={true}
                  >
                    <MapView.Marker coordinate={context.state.parcelDestLocation.coords} title="My Marker" description="Some description" />
                  </MapView>
                </View>
                <View style={styles.entryRow}>
                  <HeaderImage image="uhren" />
                </View>
                <View style={styles.entryRow}>
                  <Text h4Style={styles.entryRowSubHeadline} h4>
                    Abholung
                  </Text>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>von</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{formatDate(context.state.parcelDatePickupFrom)}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>bis</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{formatDate(context.state.parcelDatePickupTo)}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <Text h4Style={styles.entryRowSubHeadline} h4>
                    Wunschtermin
                  </Text>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>von</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{formatDate(context.state.parcelDate1From)}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>bis</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{formatDate(context.state.parcelDate1To)}</Text>
                  </View>
                </View>
                <View style={{ display: context.state.parcelDate2 ? "flex" : "none" }}>
                  <View style={styles.entryRow}>
                    <Text h4Style={styles.entryRowSubHeadline} h4>
                      Ausweichtermin
                    </Text>
                  </View>
                  <View style={styles.entryRow}>
                    <View style={styles.entryRowTitle}>
                      <Text style={styles.entryRowTitleText}>von</Text>
                    </View>
                    <View style={styles.entryRowDetail}>
                      <Text style={styles.entryRowDetailText}>{formatDate(context.state.parcelDate2From)}</Text>
                    </View>
                  </View>
                  <View style={styles.entryRow}>
                    <View style={styles.entryRowTitle}>
                      <Text style={styles.entryRowTitleText}>bis</Text>
                    </View>
                    <View style={styles.entryRowDetail}>
                      <Text style={styles.entryRowDetailText}>{formatDate(context.state.parcelDate2To)}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={[styles.container]}>
                <View>
                  <Text h4Style={styles.entryRowSubHeadline} h4>
                    Lieferkosten
                  </Text>
                </View>
                <View style={{ paddingTop: 10 }}>
                  <HeaderImage image="muenze" />
                </View>
              </View>
              <CalcCosts context={context} scope={this} />
              <View style={[styles.container, { backgroundColor: "#cccccc" }]}>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Lieferweg</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{context.state.parcelDistanceText}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>Lieferzeit mit dem Auto ca.</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{context.state.parcelDurationText}</Text>
                  </View>
                </View>
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>
                      Kosten inkl.Mwst. (km * {context.state.parcelCostsPerKm} {context.state.parcelCurrency})
                    </Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>
                      {context.state.parcelCosts} {context.state.parcelCurrency}
                    </Text>
                  </View>
                </View>

                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>verfügbares Guthaben</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{context.state.parcelUserCredit == "0.00" ? "Kein Guthaben vorhanden!" : context.state.parcelUserCredit}</Text>
                  </View>
                </View>
              </View>

              <View style={{ paddingBottom: 20 }}>
                <Button
                  onPress={() => {
                    Linking.openURL("https://www.mister-postman.net/datenschutz");
                  }}
                  title={"Datenschutzbestimmungen  "}
                  buttonStyle={{ borderRadius: 50 }}
                  containerStyle={{ marginLeft: 0 }}
                  type="clear"
                  iconRight
                  icon={<Icon name="external-link" size={17} color="#007AFF" />}
                />

                <Button
                  onPress={() => {
                    Linking.openURL("https://www.mister-postman.net/agb");
                  }}
                  title={"AGB  "}
                  buttonStyle={{ borderRadius: 50 }}
                  containerStyle={{ marginLeft: 0 }}
                  type="clear"
                  accessibilityLabel="AGB"
                  iconRight
                  icon={<Icon name="external-link" size={17} color="#007AFF" />}
                />

                <CheckBox title="Ich akzeptiere die Datenschutzbestimmungen" checked={this.state.checkedDSGVO} onPress={() => this.setState({ checkedDSGVO: !this.state.checkedDSGVO })} />

                <CheckBox title="Ich akzeptiere die AGB" checked={this.state.checkedAGB} onPress={() => this.setState({ checkedAGB: !this.state.checkedAGB })} />
                <View style={{ padding: 20 }}>
                    <Text style={{ paddingTop: 10, paddingBottom: 10 }}>Hiermit bevollmächtige ich die Zustellerin der den Zusteller, die für mich bestimmte Sendung abzuholen.</Text>
                    <Text style={{ paddingBottom:5 }}>Sendungsnummer: {context.state.parcelNumber}</Text>
                    <Text style={{ paddingBottom: 10 }}>
                      Versandunternehmen: <ProviderName provider={context.state.parcelProvider} />
                    </Text>
                    <Text style={{ paddingTop: 20, paddingBottom: 10, fontWeight: "bold" }}>Vollmachtgeber (Empfänger der Sendung):</Text>

                    <Text>
                      {context.state.userFirstname} {context.state.userLastname}
                    </Text>
                    <Text>{context.state.userStreet}</Text>
                    <Text>
                      {context.state.userZip} {context.state.userCity}
                    </Text>
                    <Text style={{ paddingTop: 20, paddingBottom: 10, fontWeight: "bold" }}>Vollmachtnehmer (Abholer der Sendung):</Text>

                    <Text style={{ fontStyle: "italic" }}>wird bei Zustandekommen des Zustellungsvertrags automatisch ausgefüllt.</Text>
                  </View>


                  <Image resizeMode={"contain"} style={{ width: "100%", height: 114 }} source={{ uri: this.state.signature!= null ? this.state.signature: ' ' }} />

                <Button onPress={() => this._openContract()} title={"Vollmacht unterschreiben"} buttonStyle={{ marginTop: 10, marginBottom: 20, borderRadius: 0 }} />

                <Button onPress={() => this._confirmpost(context)} title={"Paket kostenpflichtig eintragen"} disabled={this._checkAllInputs()} buttonStyle={{ backgroundColor: "green", borderRadius: 0 }} />
              </View>
            </ScrollView>
          </View>
        )}
      </MyContext.Consumer>
    );
  }
  _checkAllInputs = () => {
    const { checkedDSGVO, checkedAGB, signature } = this.state;

    if (checkedDSGVO && checkedAGB && signature != null) {
      return false;
    } else {
      return true;
    }
  };
  _nextstep = () => {
    //this.setState({ step: this.state.step + 1 });
    //this.props.navigation.navigate("AddParcelLocation");
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowHL: {
    backgroundColor: "#cccccc"
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
