import React from "react";
import { StyleSheet, View} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import HeaderImage from "../components/HeaderImage";

export default class AddCourierServiceScreen extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      type: "station"
    };
  }
  UNSAFE_componentWillMount() {
 
  }
  componentDidUpdate(prevProps, prevState) {

  }
  _addbring = async () => {

    try {
      await AsyncStorage.setItem("newCourierService", "bring");
    } catch (error) {
      // Error saving data
    }

    this.props.navigation.navigate("AddCourierWeight");
  };

  _addpickup = async () => {

    try {
      await AsyncStorage.setItem("newCourierService", "pickup");
    } catch (error) {
      // Error saving data
    }

    this.props.navigation.navigate("AddCourierNumber");
  };


  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <View style={styles.container}>
            <View style={{ padding: 5, justifyContent: "flex-start", flex: 1 }}>
            <HeaderImage image="standort" />
            </View>
            <View style={{ padding: 5, justifyContent: "center", flex: 1,   alignItems: "center", }}>

              <View style={styles.welcomeContainer,{marginBottom:20}}>
                <Button onPress={() => {context.setNewParcelValue("parcelCourierService", "bring");this._addbring(context)}} title={"Lieferung"} raised type="outline" buttonStyle={{ borderRadius: 0, width: 250 }} />
              </View>
              <View style={styles.welcomeContainer}>
                <Button onPress={() => {context.setNewParcelValue("parcelCourierService", "pickup");this._addpickup(context)}} title={"Retoure"} raised type="outline" buttonStyle={{ borderRadius: 0, width: 250 }} />
              </View>  


            </View>

            <View style={{ padding: 5, justifyContent: "flex-end", flex: 1 }}>

            </View>
          </View>
        )}
      </MyContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  }
});
