import React from "react";
import { StyleSheet, View, Text, Image } from 'react-native';
import AppIntroSlider from 'react-native-app-intro-slider';
import { LinearGradient } from 'expo';
import { Ionicons } from '@expo/vector-icons';

const slides = [
    {
      key: 'img1',
      image: require('../../assets/images/intro/001.jpg')
    },
    {
      key: 'img2',
      image: require('../../assets/images/intro/002.jpg')
    },
    {
      key: 'img3',
      image: require('../../assets/images/intro/003.jpg')
    }
    ,
    {
      key: 'img4',
      image: require('../../assets/images/intro/004.jpg')
    }
    ,
    {
      key: 'img5',
      image: require('../../assets/images/intro/005.jpg')
    }
  ];


  export default class IntroSliderScreen extends React.Component {
    static navigationOptions = {
      header: null
    };
    _renderItem = ({ item, dimensions  }) => {
      return (
        <View style={styles.slide}>
          <Image source={item.image} style = {{  width: dimensions.width, height: dimensions.height, resizeMode: 'cover'}}  />
        </View>
      );
    }

    _onDone= () =>{
      this.props.navigation.navigate("SignIn");
    }
    render() {
        return <AppIntroSlider renderItem={this._renderItem} slides={slides} onDone={this._onDone} doneLabel={"Fertig"} nextLabel={"weiter"} dotStyle={{backgroundColor:'grey'}} activeDotStyle={{backgroundColor: '#008ae2'}} buttonTextStyle={{color: '#008ae2'}}/>;


    }
}
const styles = StyleSheet.create({
  mainContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  image: {
    width: 320,
    height: 320,
  },
  text: {
    color: 'rgba(255, 255, 255, 0.8)',
    backgroundColor: 'transparent',
    textAlign: 'center',
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 22,
    color: 'white',
    backgroundColor: 'transparent',
    textAlign: 'center',
    marginBottom: 16,
  },
});