import React from "react";
export const MyContext = React.createContext();

const reducer = (state, action) => {
  if (action.type === "TOGGLE") {
    return { ...state, isADuck: !state.isADuck };
  }
};
const initialState = {
  isADuck: false,
  parcelEditID: "",
  parcelInProgress: false,
  parcelProvider: "dhl",
  parcelType: 2,
  parcelWeight: "0-3",
  parcelNumber: "",
  parcelNumbers: [],
  parcelCustomerID: "",
  parcelCode: "",
  parcelLocFirstname: "",
  parcelLocLastname: "",
  parcelLocStreet: "",
  parcelLocNumber: "",
  parcelLocAdditional: "",
  parcelLocZip: "",
  parcelLocCity: "",
  parcelLocLocation: {
    coords: {
      latitude: "",
      longitude: ""
    }
  },
  parcelDestFirstname: "",
  parcelDestLastname: "",
  parcelDestStreet: "",
  parcelDestNumber: "",
  parcelDestAdditional: "",
  parcelDestZip: "",
  parcelDestCity: "",
  parcelDestLocation: {
    coords: {
      latitude: "",
      longitude: ""
    }
  },
  parcelDatePickupFrom: new Date(),
  parcelDatePickupTo: new Date(),
  parcelDatePickup1From: new Date(),
  parcelDatePickup1To: new Date(),
  parcelDatePickup2From: new Date(),
  parcelDatePickup2To: new Date(),
  parcelDate1From: new Date(),
  parcelDate2From: new Date(),
  parcelDate1To: new Date(),
  parcelDate2To: new Date(),
  parcelDate2: true,
  //courier
  parcelCourierService:"",
  //
  parcelSess: "",
  dispatch: action => {
    this.setState(state => reducer(state, action));
  }
};
export default class MyProvider extends React.Component {
  state = { ...initialState };
  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          handleReset: () => {
            this.setState(initialState);
          },
          setNewParcelValue: async (name, value) => await this.setState({ [name]: value })
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}
