import React from "react";
import { ScrollView, Platform, StyleSheet, TouchableWithoutFeedback, View, Alert } from "react-native";
import { Button, Text } from "react-native-elements";
import { MyProvider, MyContext } from "../../rootProvider";
import Collapsible from "react-native-collapsible";
import { formatDate } from "../helper/DateFormat";
import DateTimePicker from '@react-native-community/datetimepicker';
import DateTimePickerModal from "react-native-modal-datetime-picker";

export default class AddCourierBring_DeliveryTimeScreen extends React.Component {
  render() {
    return <MyContext.Consumer>{context => <TimeDataHandler context={context} navigation={this.props.navigation} />}</MyContext.Consumer>;
  }
}

class TimeDataHandler extends React.Component {
  /*static navigationOptions = {
    title: "Neues Paket eintragen"
  };*/

  constructor(props) {
    super(props);
    this.state = {
      date1vis: true,
      date2vis: true,
      date3vis: true,
      date4vis: true,
      dateisvis: "",
      modaldate1vis:false,
      modaldate2vis:false,
      modaldate3vis:false,
      modaldate4vis:false
    };
 
  }
  UNSAFE_componentWillMount = async () => {

    console.log("UNSAFE_componentWillMount");
   
    await this.chkFromTime(1);
    await this.chkToTimebyFrom(1);

    await this.chkFromTime(2);
    await this.chkToTimebyFrom(2);
  };
  componentDidUpdate(prevProps, prevState) {

  } 
  chkFromTime = async (instance, value = 0) => {

    this._hideModalCalAndroid();

    var fromTime = this.props.context.state["parcelDate" + instance + "From"].getTime();
    var currTime = new Date();

    //nächster möglicher lieferzeitpunkt
    var minhours=6;

    var nextDeliveryTime = currTime.getTime() + (minhours*60) * 60000; //12h später
    var nextDeliveryTimeDate = new Date(nextDeliveryTime);


    //prüfen ob zeit zwischen 21 uhr und 6 uhr morgens liegt, dann zeit auf 6.00 uhr am nächsten Tag stellen
    var absDate1From = nextDeliveryTimeDate.getHours() * 60 + nextDeliveryTimeDate.getMinutes();
    if(absDate1From < 420 || absDate1From > 1260){
      var nextDateDay= nextDeliveryTimeDate.getDate();
      var nextDateMonth= nextDeliveryTimeDate.getMonth();
      var nextDateYear= nextDeliveryTimeDate.getFullYear();
  
      var nextDate=new Date(nextDateYear,nextDateMonth,nextDateDay,"07","00","00");
      var nextDeliveryTimeDate = new Date(nextDate.getTime() + (24 * 60 * 60 * 1000));
    }


    var fromvar = "parcelDate" + instance + "From";

    //startseite setzen, wenn aktuelle Zeit vor frühst möglichem termin liegt
    if (fromTime < nextDeliveryTime) {
      await this.props.context.setNewParcelValue(fromvar, nextDeliveryTimeDate);
    }

    //beim einstellen der ezit, wenn ein neuer Wert üergeben wird
    if (value != 0) {

      //nur setzen wenn neue Zeit nach nächster möglicher zeit liegt
      if (value.getTime() >= nextDeliveryTime) {
        var writeval;

        writeval = value;

        await this.props.context.setNewParcelValue(fromvar, writeval);
      } else {
        await this.props.context.setNewParcelValue(fromvar, nextDeliveryTimeDate);
      }
    }

    //bis-Zeit ausrechnen
    this.chkToTime(instance, this.props.context.state["parcelDate" + instance + "To"]);
  };
  chkToTimebyFrom = async instance => {
    var fromTime = this.props.context.state["parcelDate" + instance + "From"].getTime();
    var toTime = this.props.context.state["parcelDate" + instance + "To"].getTime();
    var toTimeMin = new Date(fromTime + 60 * 60000);

    if (toTimeMin.getTime() > toTime) {
      var tovar = "parcelDate" + instance + "To";
      await this.props.context.setNewParcelValue(tovar, toTimeMin);
      console.log("correct toTimeMin inst:" + instance);
      console.log(this.props.context.state["parcelDate" + instance + "From"]);
      console.log(this.props.context.state["parcelDate" + instance + "To"]);
    }

    this.checkOverlapping();
  };

  chkToTime = async (instance, newvalue) => {

    this._hideModalCalAndroid();

    var fromTime = this.props.context.state["parcelDate" + instance + "From"].getTime();
    var tovar = "parcelDate" + instance + "To";
    var toTimeMin = new Date(fromTime + 60 * 60000);
    var toTimeMax = new Date(fromTime + 300 * 60000);
    if (newvalue < toTimeMin) {
      await this.props.context.setNewParcelValue(tovar, toTimeMin);
    } else if (newvalue > toTimeMax) {
      await this.props.context.setNewParcelValue(tovar, toTimeMax);
    } else {
      await this.props.context.setNewParcelValue(tovar, newvalue);
    }

    var test = await this.props.context.state.parcelDate1To;
   this.checkOverlapping();
  };

  checkOverlapping = () => {

    var toTime1 = this.props.context.state.parcelDate1To.getTime();
    var fromTime2 = this.props.context.state.parcelDate2From.getTime();
    var toTimeMin = new Date(toTime1 + 360 * 60000);

    //abstand nucht vorhanden?
    if (fromTime2 < toTimeMin) {

        //prüfen ob zeit zwischen 21 uhr und 6 uhr morgens liegt, dann zeit auf 6.00 uhr am nächsten Tag stellen
        var absDate1From = toTimeMin.getHours() * 60 + toTimeMin.getMinutes();

        if(absDate1From < 420 || absDate1From > 1260){
          //nachster tag von date1To, fa toTimeMin bereits der nächste Tag sein könnte
          var nextDateDay= this.props.context.state.parcelDate1To.getDate();
          var nextDateMonth= this.props.context.state.parcelDate1To.getMonth();
          var nextDateYear= this.props.context.state.parcelDate1To.getFullYear();
      
          var nextDate=new Date(nextDateYear,nextDateMonth,nextDateDay,"07","00","00");
          var toTimeMin = new Date(nextDate.getTime() + (24 * 60 * 60 * 1000));
        }


     this.props.context.setNewParcelValue("parcelDate2From", toTimeMin);
     this.chkToTimebyFrom(2);
    }
  };


  _showModalCalAndroid=(indx)=>{
    this.setState({ ["modaldate"+indx+"vis"]: true });
  }
  _hideModalCalAndroid=()=>{
    this.setState({ modaldate1vis: false });
    this.setState({ modaldate2vis: false });
    this.setState({ modaldate3vis: false });
    this.setState({ modaldate4vis: false });
  }

  _toogleCalVisIOS = trgState => {
    if (this.state.dateisvis == trgState) {
      this.setState({ dateisvis: "" });
    } else {
      this.setState({ [this.state.dateisvis]: true });
      this.setState({ dateisvis: trgState });
    }
    this.setState({ [trgState]: !this.state[trgState] });
  };


  render() {
    //var newDate = this.state.datefrom.getDate().toString() + ". " + this.state.datefrom.getMonth().toString() + ". " + this.state.datefrom.getYear().toString();

    return (
      <View style={styles.container}>
        <ScrollView>
          <View style={{ padding: 5, justifyContent: "center", flex: 1 }}>

          <DateTimePickerModal
              isVisible={this.state.modaldate1vis}
              mode="datetime"
              onConfirm={(val) => {
                  this.chkFromTime(1, val);
                  this.chkToTimebyFrom(1);
              
              }}
              onCancel={this._hideModalCalAndroid}
              date={this.props.context.state.parcelDate1From}
              minuteInterval={10}
              locale={'de_DE'}                                  
            />
            <DateTimePickerModal
              isVisible={this.state.modaldate2vis}
              mode="datetime"
              onConfirm={(val) => this.chkToTime(1, val)}
              onCancel={this._hideModalCalAndroid}
              date={this.props.context.state.parcelDate1To}
              minuteInterval={10}
              locale={'de_DE'}                                  
            />
            <DateTimePickerModal
              isVisible={this.state.modaldate3vis}
              mode="datetime"
              onConfirm={(val) => {
                 this.chkFromTime(2, val);
                 this.chkToTimebyFrom(2);
              }}
              onCancel={this._hideModalCalAndroid}
              date={this.props.context.state.parcelDate2From}
              minuteInterval={10}
              locale={'de_DE'}                                  
            />
            <DateTimePickerModal
              isVisible={this.state.modaldate4vis}
              mode="datetime"
              onConfirm={(val) => this.chkToTime(2, val)}
              onCancel={this._hideModalCalAndroid}
              date={this.props.context.state.parcelDate2To}
              minuteInterval={10}
              locale={'de_DE'}                                  
            />



            <View style={styles.entryRowNoBorder}>
              <Text h4Style={styles.entryRowSubHeadline} h4>
                Wunschtermin
              </Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={{ color: "#666" }}>- Lieferung frühestens 6 Std. nach Einstellung des Pakets</Text>
              <Text style={{ color: "#666" }}>- Lieferzeitraum min.1 Std. max. 5 Std.</Text>
              <Text style={{ color: "#666" }}>- Lieferzeiten zwischen 7 und 22 Uhr</Text>
            </View>
            <TouchableWithoutFeedback
              onPress={async () => {
                if (Platform.OS === "ios") {
                  this._toogleCalVisIOS("date1vis");
                } else {
                  this._showModalCalAndroid(1);
                }
              }}
            >
              <View style={styles.entryRow}>
                <View style={styles.entryRowTitle}>
                  <Text style={styles.entryRowTitleText}>von</Text>
                </View>

                <View style={styles.entryRowDetail}>
                  <Text style={styles.entryRowDetailText}>{formatDate(this.props.context.state.parcelDate1From)}</Text>
                </View>
              </View>
            </TouchableWithoutFeedback>
            <Collapsible collapsed={this.state.date1vis}>
              {Platform.OS === "ios" ? (


                <DateTimePicker
                testID="dateTimePicker"
                value={this.props.context.state.parcelDate1From}
                mode="datetime"
                display="spinner"
                is24Hour={true}
                onChange={(event,val) => {
                    this.chkFromTime(1, val);
                    this.chkToTimebyFrom(1);
                }}
                locale={'de_DE'}
                minuteInterval={10}
                /> 

              ) : (
                <Text />
              )}
            </Collapsible>
            <TouchableWithoutFeedback
              onPress={async () => {
                if (Platform.OS === "ios") {
                  this._toogleCalVisIOS("date2vis");
                } else {
                  this._showModalCalAndroid(2);
                }
              }}
            >
              <View style={styles.entryRow}>
                <View style={styles.entryRowTitle}>
                  <Text style={styles.entryRowTitleText}>bis</Text>
                </View>
                <View style={styles.entryRowDetail}>
                  <Text style={styles.entryRowDetailText}>{formatDate(this.props.context.state.parcelDate1To)}</Text>
                </View>
              </View>
            </TouchableWithoutFeedback>
            <Collapsible collapsed={this.state.date2vis}>
              {Platform.OS === "ios" ? (


                  <DateTimePicker
                  testID="dateTimePicker"
                  value={this.props.context.state.parcelDate1To}
                  mode="datetime"
                  display="spinner"
                  is24Hour={true}
                  onChange={(event,val) => {
                    this.chkToTime(1, val);
                  }}
                  locale={'de_DE'}
                  minuteInterval={10}
                  /> 

              ) : (
                <Text />
              )}
            </Collapsible>
            <View style={styles.entryRowNoBorder}>
              <Text h4Style={styles.entryRowSubHeadline} h4>
                Ausweichtermin
              </Text>
            </View>
            <View style={{ display: this.props.context.state.parcelDate2 ? "flex" : "none" }}>
              <TouchableWithoutFeedback
                onPress={async () => {
                  if (Platform.OS === "ios") {
                    this._toogleCalVisIOS("date3vis");
                  } else {
                    this._showModalCalAndroid(3);

                  }
                }}
              >
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>von</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{formatDate(this.props.context.state.parcelDate2From)}</Text>
                  </View>
                </View>
              </TouchableWithoutFeedback>
              <Collapsible collapsed={this.state.date3vis}>
                {Platform.OS === "ios" ? (

                    <DateTimePicker
                    testID="dateTimePicker"
                    value={this.props.context.state.parcelDate2From}
                    mode="datetime"
                    display="spinner"
                    is24Hour={true}
                    onChange={(event,val) => {
                       this.chkFromTime(2, val);
                      this.chkToTimebyFrom(2);
                    }}
                    locale={'de_DE'}
                    minuteInterval={10}
                    /> 

                ) : (
                  <Text />
                )}
              </Collapsible>
              <TouchableWithoutFeedback
                onPress={async () => {
                  if (Platform.OS === "ios") {
                    this._toogleCalVisIOS("date4vis");
                  } else {
                    this._showModalCalAndroid(4);
                  }
                }}
              >
                <View style={styles.entryRow}>
                  <View style={styles.entryRowTitle}>
                    <Text style={styles.entryRowTitleText}>bis</Text>
                  </View>
                  <View style={styles.entryRowDetail}>
                    <Text style={styles.entryRowDetailText}>{formatDate(this.props.context.state.parcelDate2To)}</Text>
                  </View>
                </View>
              </TouchableWithoutFeedback>
              <Collapsible collapsed={this.state.date4vis}>
                {Platform.OS === "ios" ? (

                  <DateTimePicker
                  testID="dateTimePicker"
                  value={this.props.context.state.parcelDate2To}
                  mode="datetime"
                  display="spinner"
                  is24Hour={true}
                  onChange={(event,val) => {
                    this.chkToTime(2, val);
                  }}
                  locale={'de_DE'}
                  minuteInterval={10}
                  /> 

                ): (<Text />
                )}</Collapsible>
            </View>
          </View>
        </ScrollView>
        <View style={{}}>
          <Button onPress={this._nextstep} title={"zur Übersicht"}  buttonStyle={{ borderRadius: 0 }} />
        </View>
      </View>
    );
  }

  _nextstep = () => {
    //check time
    var absDate1From = this.props.context.state.parcelDate1From.getHours() * 60 + this.props.context.state.parcelDate1From.getMinutes();
    var absDate1To = this.props.context.state.parcelDate1To.getHours() * 60 + this.props.context.state.parcelDate1To.getMinutes();
    var absDate2From = this.props.context.state.parcelDate2From.getHours() * 60 + this.props.context.state.parcelDate2From.getMinutes();
    var absDate2To = this.props.context.state.parcelDate2To.getHours() * 60 + this.props.context.state.parcelDate2To.getMinutes();

    if (absDate1From < 420 || absDate1To < 420 || absDate2From < 420 || absDate2To < 420) {
      Alert.alert(
        "Ups...",
        "Bitte beachte, dass der Lieferzeitraum zwischen 7:00 Uhr und 22:00 Uhr muss.",
        [
          {
            text: "OK"
          }
        ],
        { cancelable: false }
      );
    } else if (absDate1From > 1320 || absDate1To > 1320 || absDate2From > 1320 || absDate2To > 1320) {
      Alert.alert(
        "Ups...",
        "Bitte beachte, dass der Lieferzeitraum zwischen 7:00 Uhr und 22:00 Uhr muss.",
        [
          {
            text: "OK"
          }
        ],
        { cancelable: false }
      );
    } else {
      this.props.navigation.navigate("AddCourierOverview",{title:"Kurier Übersicht"});
      this.setState({ date1vis: true });
      this.setState({ date2vis: true });
      this.setState({ date3vis: true });
      this.setState({ date4vis: true });
    }
  };

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    marginTop: 10,
    marginBottom: 20
  },
  entryRow: {
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    flexDirection: "row",
    paddingBottom: 20,
    paddingTop: 20
  },
  entryRowNoBorder: {
    paddingBottom: 0,
    paddingTop: 20
  },
  entryRowHeadline: { fontSize: 18, fontWeight: "bold" },
  entryRowSubHeadline: { fontSize: 15, fontWeight: "bold" },
  entryRowTitle: { width: "40%" },
  entryRowDetail: { alignItems: "flex-end", width: "60%" },
  entryRowTitleText: {
    color: "#444444"
  },
  entryRowDetailText: {
    color: "#000000"
  }
});
