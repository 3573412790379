import React from "react";
import { Image, Platform, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, TextInput, Alert, Keyboard, KeyboardAvoidingView } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Input, Button, Text, Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import getConfVars from "../../config";
const { apiUrl } = getConfVars();

export default class ReminderScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temppass: "",
      password: "",
      password2: "",
      loaderIsVisible: false
    };
  }
  _dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  async UNSAFE_componentWillMount() {}

  render() {
    return (
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={this._dismissKeyboard} style={{ flex: 1 }}>
          <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : null}>
            <Overlay isVisible={this.state.loaderIsVisible} overlayBackgroundColor="rgba(0, 0, 0, .0)">
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Button onPress={this._login} loading type="clear" loadingProps={{ size: "large", color: "white" }} />
              </View>
            </Overlay>

            <View style={styles.containerTop}>
              <View style={styles.welcomeContainer}>
                <Image source={require("../../assets/images/mrpostman.jpg")} style={{ width: 191, height: 100 }} />
              </View>

              <Input inputContainerStyle={{ padding: 0, paddingLeft: 10, borderColor: "#ccc", borderWidth: 1, borderRadius: 0, margin: 10, width: 250, alignSelf: "center" }} onChangeText={temppass => this.setState({ temppass })} value={this.state.temppass} placeholder="Temporäres Passwort" autoComplete="off" autoCorrect={false} autoCapitalize="none" />

              <Input
                inputContainerStyle={{ padding: 0, paddingLeft: 10, borderColor: "#ccc", borderWidth: 1, borderRadius: 0, margin: 10, width: 250, alignSelf: "center" }}
                onChangeText={password => this.setState({ password })}
                value={this.state.password}
                placeholder="Neues Passwort"
                secureTextEntry
                rightIconContainerStyle={{ paddingRight: 10 }}
                rightIcon={<Icon name={"thumbs-up"} size={24} color={this.state.password.length < 1 ? "transparent" : this.state.password.length > 5 ? "green" : "red"} />}
              />

              <Input
                inputContainerStyle={{ padding: 0, paddingLeft: 10, borderColor: "#ccc", borderWidth: 1, borderRadius: 0, margin: 10, width: 250, alignSelf: "center" }}
                onChangeText={password2 => this.setState({ password2 })}
                value={this.state.password2}
                placeholder="Passwort wiederholen"
                secureTextEntry
                rightIconContainerStyle={{ paddingRight: 10 }}
                rightIcon={<Icon name={this.state.password2 == this.state.password ? "thumbs-up" : "thumbs-down"} size={24} color={this.state.password2.length < 1 ? "transparent" : this.state.password2 == this.state.password ? "green" : "red"} />}
              />

              <Button onPress={this._login} title={"Passwort zurücksetzen"}  buttonStyle={{ borderRadius: 0, width: 250 }} containerStyle={{ width: 250, alignSelf: "center", marginTop: 30 }} disabled={this._checkAllInputs()} />
            </View>
          </KeyboardAvoidingView>
        </TouchableWithoutFeedback>
      </View>
    );
  }
  _checkAllInputs = () => {
    const { temppass, password, password2 } = this.state;

    if (temppass.length > 4 && password.length > 1 && this.state.password2 == this.state.password && this.state.password.length > 5) {
      return false;
    } else {
      return true;
    }
  };

  _login = async () => {
    const { temppass, password } = this.state;
    this.setState({ loaderIsVisible: true });

    //Alert.alert('Credentials', `${username} + ${password}`);

    /*Alert.alert(
          'Alert Title',
          this.state.text,
          [
            {text: 'Ask me later', onPress: () => console.log('Ask me later pressed')},
            {text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel'},
            {text: 'OK', onPress: () => console.log('OK Pressed')},
          ],
          { cancelable: false }
        );*/

    fetch(apiUrl + "user/resetpassword2.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        temppass: temppass,
        newpass: password
      })
    }) /*.then(function(response) {
      console.log(response);
    });*/
      .then(response => response.json())
      .then(async responseJson => {
        if (typeof responseJson.ok !== "undefined") {
          //alert(responseJson.realname);

          /*await AsyncStorage.setItem("userToken", "abc");
          await AsyncStorage.setItem("userRealname", responseJson.realname);
          global.userRealname = responseJson.realname;
          await AsyncStorage.setItem("userID", responseJson.id);
          global.userID = responseJson.id;
          await AsyncStorage.setItem("userName", responseJson.username);
          this.props.navigation.navigate("App");*/

          Alert.alert("Super!", "Passwort wurde erfolgreich geändert!", [
            {
              text: "Jetzt einloggen",
              onPress: () => this.props.navigation.navigate("SignIn")
            }
          ]);
        } else {
          // await alert("Login fehlgeschlagen.");

          Alert.alert("Ups...", "Da ist was fehlgeschlagen.", [{ text: "OK", onPress: () => this.setState({ loaderIsVisible: false }) }], { cancelable: false });

          //this.setState({ loaderIsVisible: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center"
  },
  containerTop: {
    flex: 1,
    justifyContent: "center"
  },
  contentContainer: {
    paddingTop: 30
  },
  welcomeContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20
  },
  welcomeImage: {
    width: 200,
    height: 100,
    resizeMode: "contain",
    marginTop: 3,
    marginLeft: -10
  }
});
