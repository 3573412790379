import React from "react";
import { createAppContainer, createStackNavigator, createSwitchNavigator, HeaderBackButton } from "react-navigation";

import MainTabNavigator from "./MainTabNavigator";

//import HomeScreen from '../screens/HomeScreen';
//import LinksScreen from '../screens/LinksScreen';
import IntroSliderScreen from "../screens/login/IntroSliderScreen";
import SignInScreen from "../screens/login/SignInScreen";
import RegisterScreen from "../screens/login/RegisterScreen";
import ReminderScreen from "../screens/login/ReminderScreen";
import NewPasswordScreen from "../screens/login/NewPasswordScreen";
import AuthLoadingScreen from "../screens/login/AuthLoadingScreen";

//const AppStack = createStackNavigator({ Home: HomeScreen,  Links: LinksScreen});

const AuthStack = createStackNavigator(
  {
    IntroSlider: {
      screen: IntroSliderScreen,
      navigationOptions: () => ({
        title: ``,
        headerBackTitle: "zurück",
        headerTruncatedBackTitle: `Login`
      })
    },
    SignIn: {
      screen: SignInScreen,
      navigationOptions: () => ({
        title: ``,
        headerBackTitle: "zurück",
        headerTruncatedBackTitle: `Login`
      })
    },
    Register: {
      screen: RegisterScreen,
      navigationOptions: () => ({
        title: `Registrieren`
      })
    },
    Reminder: {
      screen: ReminderScreen,
      navigationOptions: () => ({
        title: `Passwort vergessen`
      })
    },
    NewPassword: {
      screen: NewPasswordScreen,
      navigationOptions: () => ({
        title: `Neues Passwort festlegen`
      })
    }
  } /*,
  {
    headerMode: "none",
    navigationOptions: {
      gesturesEnabled: false
    }
  }*/
);

//export default createAppContainer(createSwitchNavigator({
// You could add another route here for authentication.
// Read more at https://reactnavigation.org/docs/en/auth-flow.html
// Main: MainTabNavigator,
//}));

export default createAppContainer(
  createSwitchNavigator(
    {
      AuthLoading: AuthLoadingScreen,
      App: MainTabNavigator,
      Auth: AuthStack
    },
    {
      initialRouteName: "AuthLoading"
    }
  )
);
